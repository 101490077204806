import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Checkmark from 'react-typescript-checkmark';
import { useAppSelector } from 'hooks/reduxHooks'
import { colorPortfolioCard } from '../../../../../constants';
import { porfolioColors } from 'Latest/DashboardScreen/FreshUserScreen/constant';



const PortfolioCard = ({ colorGraph, formik, recommendedColor, selectedPorfolio, setColorGraph, setRecommendedColor }: any) => {

    const [selectedCard, setSelectedCard] = useState<any>({})
    const { investPortfolioAlloc } = useAppSelector(state => state.sharedData);

    const newColor = porfolioColors();
    const color = colorPortfolioCard;

    useEffect(() => {
        setSelectedCard(selectedPorfolio)
        const index = investPortfolioAlloc && investPortfolioAlloc?.findIndex((item: any) => item?.profileName === selectedPorfolio?.profileName);
        setColorGraph(newColor[index])
        if (recommendedColor == null && index >= 0) {
            setRecommendedColor(newColor[index])
        }
    }, [selectedPorfolio])



    const handleName: any = {
        "Conservative": "Low-risk",
        "Slightly Conservative": "Cautious",
        "Moderate": "Balanced",
        "Slightly Aggressive": "Ambitious",
        "Aggressive": "Bold"
    }

    // const color = ["#43BED8", "#8274AF", "#53B1FD", "#FDA29B", "#F97066"]
    

    

    const handleSelect = (item: any, index: number) => {
        setSelectedCard(item)
        setColorGraph(newColor[index])
        formik.setFieldValue("selectedPorfolio", item)
    }

    return (
        <>
            <Grid item md={12} xs={12} sx={{ pt: { md: "24px" }, overflowX: 'scroll' }}>
                <Box sx={{ display: "flex", gap: "16px" }}>

                    {investPortfolioAlloc?.map((item: any, index: number) => {
                        return (
                            <Box key={index} sx={{ display: "flex", flexDirection: "column" }} >

                                <Box sx={{
                                    position: "relative", fontWeight: 500, lineHeight: "14px",
                                    top: "7px", width: "fit-content",
                                    // background: recommendedColor, 
                                    background: "#422E84",
                                    fontSize: "12px",
                                    color: "white", p: "2px 6px 10px 6px", borderRadius: "4px 4px 0px 0px",
                                    opacity: item?.profileName == formik?.values?.recommendedPorfolio?.profileName ? 1 : 0
                                }}>Recommended</Box>

                                <Box onClick={() => handleSelect(item, index)} sx={{
                                    padding: "12px 24px 12px 18px",
                                    boxShadow: selectedCard?.profileName == item?.profileName ? "0px 4px 12px 0px rgba(208, 213, 218, 0.50)" : "",
                                    display: "flex",
                                    gap: "12px",
                                    alignItems: 'center',
                                    background: selectedCard?.profileName == item?.profileName ? "#FFF" : "#F2F4F7",
                                    border: selectedCard?.profileName == item?.profileName ? "1px solid #98A2B3" : "",
                                    color: "#344054",
                                    borderRadius: '8px',
                                    cursor: "pointer",
                                    position: "relative"

                                }}>

                                    <Box>
                                        {selectedCard?.profileName == item?.profileName && <Box style={{
                                            position: "absolute",
                                            top: "10px",
                                            left: "15px"
                                        }}>
                                            <Checkmark size='md' backgroundColor={"transparent"} checkColor={"#FFF"} />
                                        </Box >}
                                        <Box sx={{ background: item?.colour, borderRadius: "50px", width: "18px", height: "18px" }} />
                                        {/* <Box sx={{ background: color[index], borderRadius: "50px", width: "18px", height: "18px" }} /> */}
                                    </Box >

                                    <Typography noWrap variant='h6' fontWeight={400}>
                                        {/* {handleName[item.profileName]} */}
                                        {item.profileName}
                                    </Typography>
                                </Box>

                            </Box>

                        )

                    })}


                </Box>

            </Grid>

        </>
    )
}

export default PortfolioCard