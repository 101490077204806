import { useEffect, useRef, useCallback, useState } from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import { useLocation } from 'react-router-dom';

const ZENDESK_KEY = "529af78f-c847-400a-aa58-5fa9f82affef";
const RETRY_INTERVAL = 1000; // 1 second
const MAX_RETRIES = 5;

const ZendeskChat = () => {
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const scriptLoaded = useRef(false);
    const retryCount = useRef(0);
    const location = useLocation();
    const [isInitialized, setIsInitialized] = useState(false);

    const name = userAccountDetails?.identityCard?.name;
    const email = userAccountDetails?.email;
    const phone = userAccountDetails?.contactNumber;

    const setZendeskFields = useCallback(() => {
        if (!window.zE) {
            return false;
        }

        
        try {
            window.zE('messenger:set', 'conversationFields', [
                { id: '22178646645650', value: name || '' },
                { id: '22178719982610', value: email || '' },
                { id: '22178707426962', value: phone || '' }
            ]);
            return true;
        } catch (error) {
            console.error('Error setting Zendesk fields:', error);
            return false;
        }
    }, [name, email, phone]);

    const initializeZendesk = useCallback(() => {
        
        if (!window.zE) {
            if (retryCount.current < MAX_RETRIES) {
                retryCount.current += 1;
                setTimeout(initializeZendesk, RETRY_INTERVAL);
                return;
            }
            console.error('Failed to initialize Zendesk after max retries');
            return;
        }

        window.zE('messenger:on', 'open', function() {
            setZendeskFields();
        });

        // Reset widget settings when initialized
        window.zE('messenger', 'close');
        setZendeskFields();
        setIsInitialized(true);
        retryCount.current = 0;
    }, [setZendeskFields]);

    // Initial script loading
    useEffect(() => {
        if (!scriptLoaded.current) {
            const script = document.createElement('script');
            script.id = 'ze-snippet';
            script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
            script.async = true;
            
            script.onload = () => {
                scriptLoaded.current = true;
                initializeZendesk();
            };

            document.body.appendChild(script);
        }

        return () => {
            if (scriptLoaded.current) {
                const script = document.getElementById('ze-snippet');
                if (script) {
                    document.body.removeChild(script);
                }
                scriptLoaded.current = false;
                setIsInitialized(false);
                retryCount.current = 0;
            }
        };
    }, [initializeZendesk]);

    // Handle user data updates
    useEffect(() => {
        if (scriptLoaded.current && isInitialized && (email || phone)) {
            const success = setZendeskFields();
            
            if (!success && retryCount.current < MAX_RETRIES) {
                const timeoutId = setTimeout(() => {
                    retryCount.current += 1;
                    setZendeskFields();
                }, RETRY_INTERVAL);
                
                return () => clearTimeout(timeoutId);
            }
        }
    }, [email, phone, setZendeskFields, isInitialized]);

    // Handle route changes
    useEffect(() => {
        if (window.zE && isInitialized) {
            window.zE('messenger', 'close');
            
            // Ensure fields are set after navigation
            setTimeout(() => {
                setZendeskFields();
            }, 500);
        }
    }, [location.pathname, setZendeskFields, isInitialized]);

    return null;
};

export default ZendeskChat;