import { Grid, Box, Typography, Checkbox, IconButton, useTheme, Button, useMediaQuery } from '@mui/material'
import { withdrawStyle } from '../Style/withdrawStyle'
import Checkmark from 'react-typescript-checkmark';
import { useEffect, useState } from 'react';
import NoDataScreen from "../../../components/NodataScreen"
import { onDefaultRequestUpdateAccount, onDeleteUserBankAccount, onGetUserBankAccount, postUserAcc } from 'redux/reducers/sharedStateData';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import CustomModal from "Latest/MyProfile/ModalV2/confirmationModal";
// import BankIcon from "Latest/Images/bankIcon.png"
import BankIconSelected from "Latest/Images/bankIconSelected.png"
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import SaveIcon from "Latest/Images/saveIcon.png"
import warningIcon3 from "Latest/Images/warningIcon3.png"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { tokens } from 'theme';
import { useAppLoading } from 'hooks/appLoading';
import { toast } from 'react-toastify';
import BankIcon from 'Latest/Images/svg/BankIcon';
import FormikConfirmPasswordField from 'jsx/components/MUI_components/FormikConfirmPasswordField';
import NoAccdataScreen from 'Latest/Illustration/noAccount';
import { BankAccountDefaultStatus, BankAccountDefaultStatusString, UserUpdateRequestType } from '../../../../constants/index';
import BankOTPModal from 'Latest/MyProfile/Modal/BankOTPModal';
import { onChangeAccountItem } from 'redux/actions';
import BankSuccessModal from 'Latest/MyProfile/ModalV2/BankSuccessModal';

const modalTypesObj = {
    deafultAccUpdate: "deafultAccUpdate"
}
const BankCheckBoxCard = ({ defaultBank = true, defaultViewLimit = 1, deleteBtn = false, formik, isAccTitleShow = true, list, selectedBank, userSelectedBank = false }: any) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const byDefaultViewLimit = defaultViewLimit
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [listLength, setListLength] = useState(byDefaultViewLimit)
    const [modalType, setModalType] = useState('')
    const [selectedItem, setSelectedItem] = useState<any>({})


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setSelectedItem({}) };

    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const handleOpenSuccessModal = () => setOpenSuccessModal(true);
    const handleCloseSuccessModal = () => setOpenSuccessModal(false);


    const [openDefaultConf, setOpenDefaultConf] = useState(false);
    const handleOpenDefaultConf = () => setOpenDefaultConf(true);
    const handleCloseDefaultConf = () => { setOpenDefaultConf(false); setSelectedItem({}) };
    const handleSubmitDefaultConf = () => {

        const body = {
            "fieldsValues": {
                "IsRoshanDigitalBankAccount": selectedItem?.isRDA?.toString(),
                "IBAN": selectedItem?.accountNumber,
            }
        }

        const onSuccess = () => {
            toast.success(`OTP sent to ${userAccountDetails?.email}`, { theme: "colored" });
            setOpenDefaultConf(false)
            handleOpenOtpModal()
        }

        const onFail = (message: any, response: any) => {
            console.log("🚀 ~ onFail ~ message:", message, response.status)
            return;
        }

        dispatch(onChangeAccountItem(body, userAccountDetails?.userId, onSuccess, onFail))
    }


    const [openOtpModal, setOpenOtpModal] = useState(false);
    const handleOpenOtpModal = () => { setOpenDefaultConf(false); setOpenOtpModal(true); };
    const handleCloseOtpModal = () => { setOpenOtpModal(false); setSelectedItem({}) };


    const dispatch = useAppDispatch();
    const loading = useAppLoading('auth');

    const handleDefaultFunc = (item: any, index: number) => {

        const findAnyAccInProgress = list?.some((x: any, i: number) => x.defaultStatus.toLowerCase() == BankAccountDefaultStatusString.InProgress.toLowerCase())
        const findDefaultAcc = item.isDefault

        if (findAnyAccInProgress) {
            const isAccInprogress = item?.defaultStatus.toLowerCase() == BankAccountDefaultStatusString.InProgress.toLowerCase()

            if (isAccInprogress || findDefaultAcc) {
                console.log()
            } else {
                toast.error('Only 1 bank account can be made default at a time.', { theme: "colored" });
            }
        } else if (findDefaultAcc) {
            console.log('')
        } else {
            handleOpenDefaultConf()
            // handleSetDefaultBank(item)
        }


    }

    const handleOtpVerify = () => {
        dispatch(onGetUserBankAccount())
        handleCloseOtpModal()
        handleOpenSuccessModal()
    }

    const handleClick = (item: any, index: number) => {
        setSelectedItem(item)
        // selectedBank && selectedBank(item)
        handleDefaultFunc(item, index)
    }

    const handleDelete = () => {
        const body: any = {
            accountNumber: selectedItem?.accountNumber,
            bankName: selectedItem?.bankName
        }
        const onSuccess = () => {
            dispatch(onGetUserBankAccount())
            handleClose()
            toast.success('Deleted successfully', {
                autoClose: 5000,
                closeOnClick: true,
                draggable: true,
                hideProgressBar: false,
                pauseOnHover: true,
                position: 'bottom-right',
                progress: undefined
            });

        }
        const onFail = () => {
            console.log("onFail")
        }

        dispatch(onDeleteUserBankAccount(body, onSuccess, onFail))
    }

    const confirmationContent = () => {
        return (
            <>
                <Typography sx={ModalStyle.headStyle}>Save changes?</Typography>
                <Typography sx={ModalStyle.subheadStyle}>Do you want to delete your account?</Typography>
            </>
        )
    }

    const confirmationContentUpdate = () => {
        return (
            <>
                <Typography sx={ModalStyle.headStyle}>Are you sure you wish to update your default bank account? All withdrawals are deposited into your default bank account.</Typography>
                <Typography sx={ModalStyle.subheadStyle}>Update requests may take up to 5 working days to complete.</Typography>
            </>
        )
    }

    const handleView = () => {
        if (listLength == byDefaultViewLimit) {
            setListLength(list?.length)
        } else {
            setListLength(byDefaultViewLimit)
        }
    }

    useEffect(() => {
        // defaultBank && list?.length > 0 && handleClick(list[0], 0); //default bank select
    }, [list]);

    // useEffect(() => {
    //     const userSelected = userSelectedBank && list.filter((item: any, ind: number) => item.accountNumber == formik?.values?.accountNumber) //default bank selected on modal
    //     userSelectedBank && userSelected && setSelectedItem(userSelected[0])
    // }, []);



    return (
        <>
            <CustomModal image={SaveIcon} handleOpen={handleOpen} open={open} handleClose={handleClose} handleConfirm={handleDelete} Content={confirmationContent} loadingConfirmBtn={loading} />

            <CustomModal imgStyle={{ width: '48px', height: "48px" }} image={warningIcon3} handleOpen={handleOpenDefaultConf} open={openDefaultConf} handleClose={handleCloseDefaultConf} handleConfirm={handleSubmitDefaultConf} Content={confirmationContentUpdate} loadingConfirmBtn={loading} />

            <BankSuccessModal open={openSuccessModal} handleClose={handleCloseSuccessModal} />

            <BankOTPModal open={openOtpModal} handleClose={handleCloseOtpModal} handleVerify={handleOtpVerify} loadingConfirmBtn={loading} selectedItem={selectedItem} />

            {list?.length > 0 ?
                <>
                    {list?.slice(0, listLength)?.map((item: any, index: number) => {
                        return (
                            <>

                                <Box sx={{ position: 'relative' }}>
                                    <Grid key={item.accountNumber}
                                        sx={{
                                            background: item?.isDefault ? "#F4F2FF" : "#FFFFFF", ...withdrawStyle.keyGrid
                                            , mb: list.length !== index + 1 ? "24px" : "0px",
                                        }}
                                        onClick={() => handleClick(item, index)}
                                    >


                                        <Box sx={{ position: "absolute", top: { xs: 92, sm: 12 }, right: 19 }}>
                                            {/* {selectedItem?.accountNumber == item.accountNumber ? */}
                                            {item?.isDefault ?
                                                <Checkmark backgroundColor='#7F56D9' size='sm' />
                                                :
                                                <Box sx={{
                                                    height: "15px",
                                                    width: "15px",
                                                    backgroundColor: "#FFFFFF",
                                                    borderRadius: "50%",
                                                    display: "inline-block",
                                                    border: "1px solid #D0D5DD"
                                                }}></Box>
                                            }
                                        </Box>

                                        {<Grid item xs={12} sm={2} md={1.5} sx={{ ...withdrawStyle.imgGrid }} >
                                            {/* <img src={`/Images/BankIcon/${item?.bankName?.split(" ")[0]}.png`} alt={"a"} />  //for dynamic bank icon */}
                                            <BankIcon fill={item?.isDefault ? "#432F87" : "#475467"} />
                                        </Grid>}

                                        <Grid item xs={12} sm={10} md={10.5} sx={{
                                            py: "17px", px: "22px",
                                            background: { xs: "#FFFFFF", sm: item?.isDefault ? "#F4F2FF" : "#FFFFFF" }
                                        }}>
                                            {item?.defaultStatus.toLowerCase() == BankAccountDefaultStatusString.InProgress.toLowerCase() &&
                                                <Box sx={{ ...withdrawStyle.detailBox, pb: "11.5px", flexDirection: 'row' }}>
                                                    <Box sx={{ background: '#FFD6A4', px: '8px', borderRadius: '8px' }}>
                                                        <Typography variant='body2' sx={{ color: '#5E4200', textAlign: 'center' }}>In progress</Typography>
                                                    </Box>
                                                </Box>
                                            }
                                            <Box sx={{ ...withdrawStyle.detailBox, pb: "11.5px" }}>
                                                <Box sx={withdrawStyle.detailSubBoxKey}><Typography variant='body2'>Bank Name</Typography></Box>
                                                <Box sx={withdrawStyle.detailSubBoxValue}><Typography variant='h6'>{item.bankName}</Typography></Box>
                                            </Box>
                                            {isAccTitleShow && <Box sx={{ ...withdrawStyle.detailBox, pb: "11.5px" }}>
                                                <Box sx={withdrawStyle.detailSubBoxKey}><Typography variant='body2'>Account Title</Typography></Box>
                                                <Box sx={withdrawStyle.detailSubBoxValue}><Typography variant='h6'>{item.accountTitle}</Typography></Box>
                                            </Box>}
                                            <Box sx={withdrawStyle.detailBox}>
                                                <Box sx={withdrawStyle.detailSubBoxKey}><Typography variant='body2'>Account Number/IBAN</Typography></Box>
                                                <Box sx={{ ...withdrawStyle.detailSubBoxValue, display: "flex", alignItems: 'center', gap: '8px' }}>
                                                    <Typography variant='h6'>{item.accountNumber}</Typography>
                                                    {item.isRDA && <Box sx={{ background: '#E5DDFF', px: '8px', borderRadius: '10px' }}>
                                                        <Typography variant='body2' sx={{ color: '#422E84' }}>RDA</Typography>
                                                    </Box>}
                                                </Box>

                                            </Box>
                                        </Grid>

                                    </Grid>
                                    {deleteBtn && !item?.isDefault &&
                                        item?.defaultStatus.toLowerCase() !== BankAccountDefaultStatusString.InProgress.toLowerCase() &&
                                        <Box sx={{ position: "absolute", top: { xs: 110, sm: 34 }, right: 6 }}>
                                            <IconButton aria-label="delete" onClick={() => { handleOpen(); setSelectedItem(item) }}>
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </Box>}
                                </Box >
                            </>

                        )
                    })}

                    {list?.length > defaultViewLimit && <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} >
                        <Button sx={{ background: colors.grey[100], color: colors.purple[100] }} onClick={handleView}>
                            {listLength == byDefaultViewLimit ? "view all" : "view less"}
                        </Button>
                    </Box>}

                </>
                :
                <NoAccdataScreen subHeadContent={"There is no account. Please add your account"} loading={loading} />
            }
        </>
    )
}

export default BankCheckBoxCard