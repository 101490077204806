import React, { useRef } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { onGetAccountSetup, onPostAdditionalDetailPerAddress, onPutUserAnswers } from 'redux/actions';
import { getFormattedDate } from 'utils/getDateDMYFormat';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import FormikInputField from '../../components/Account_Fields/FormikInputFieldLatest';
import FormikRadioField from '../../components/Account_Fields/FormikRadioFieldV2';
import FormikSelectFieldWithLabel from '../../components/Account_Fields/AppSelectWithLabelLatest';
import PhoneField from 'jsx/components/Account_Fields/PhoneFieldFormikLatest';
import { FadeIn } from 'jsx/components/Shared/FadeIn';
import ConfirmationModal from 'jsx/components/AccountSetupForm/ConfirmationModal';
import LoaderWithText from 'jsx/components/Shared/LoaderWithText';
import DynamicQuestionAnswers from 'Latest/2ndTierKyc/steps/Declaration/dynamicQuestion';
import * as Yup from 'yup';
import { AnswerFieldType, CASH_FUND_ID, InputCategoryEnum, mixPannelTrackerEventName } from '../../../constants';
import '../../../scss/login/_Login.scss';
import { Container, Grid } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Loader from 'jsx/components/Shared/Loader';
import InfoIcon from '@mui/icons-material/Info';
import FormikInputFieldLifeTime from 'jsx/components/Account_Fields/FormikInputFieldLifeTime';
import { accountSetupFormValidation, additionalDetailsFormValidation, additionalDetailsPermanentAddrValidation } from 'validate/Screen/AccountSetupFormValidation';
import { goalMainHeadTypoStyle } from 'Latest/Goal/style';
import ButtonsRow from 'jsx/components/WizardFormModal/ButtonsRowLatest';
import { containerMain, containerMainBox, HeadingStyle, subHeadingBlueStyle, accountsetupBtnStyles, juniorHeadingStyle, topInfoWithLabelStyles } from 'Latest/Style/Main';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import infoIcon from 'Latest/Images/svg/infoIconGray.svg';
import FormikBooleanButtonsField from 'jsx/components/Shared/V1/FormikBooleanBtnFieldBoxV1';
import { subHeadingSecStyle } from 'Latest/Style/Main';
import { UploadFile } from '@mui/icons-material';
import UploadCustomInputFieldV1 from 'jsx/components/Shared/V1/uploadCustomInputFieldV1';
import UploadField from '../../components/Shared/V2/FileUploadFieldV2';
import { useAppLoading } from 'hooks/appLoading';

const AdditionalDetailsPermanentAddr = ({ formikVP, goalId, loading, nextStep, previousStep }: any) => {
    const dispatch = useAppDispatch();
    const { userAccountDetails } = useAppSelector(state => state.auth);

    const isLoading = useAppLoading('onPostAdditionalDetailPerAddress')

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        dispatch(onGetAccountSetup());
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            isAnotherPermanentAddress: '',
            Document: ""
        },
        onSubmit: async (values: any) => {
            onSubmit()

        },
        validationSchema: additionalDetailsPermanentAddrValidation
    });

    const onSubmit = () => {

        const differentAdd = formik?.values?.isAnotherPermanentAddress
        const formData = new FormData();
        formData.append('differentAddress',differentAdd);
        formData.append('Document', formik?.values?.Document);

        const onSuccess = () => {
            nextStep();
        }


        dispatch(onPostAdditionalDetailPerAddress(formData, onSuccess));
    };

    const goBack = (e: any) => {
        previousStep()
    }

    const goNext = (e: any) => {
        e.preventDefault();
        formik.submitForm();
    }

    const buttonHtml = () => {
        return (
            <ButtonRowV1 handleNext={goNext} handleBack={goBack} btnTitleNext={"Continue"} loading={isLoading} />
        )
    }

    const answerOptions = [
        {
            "id": 1,
            "score": 0,
            "label": "Yes",
            "value": "true",
            "answer": null,
            "idRelatedQuestions": null
        },
        {
            "id": 2,
            "score": 0,
            "label": "No",
            "value": "false",
            "answer": null,
            "idRelatedQuestions": null
        }
    ]


    return (
        <>
            <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ pt: { xs: "24px", md: "40px" } }}>

                <Grid container>

                    <Grid item md={12} xs={12} sx={mainContainerV2}>

                        <Grid item md={12} xs={12}>
                            <Typography variant='h4' color={"#1D2939"} lineHeight={"normal"} textAlign={"center"}>Additional details</Typography>
                        </Grid>

                        <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"40px"}>
                            <Grid item md={12} xs={12}>

                                <Grid item md={10} xs={12} mb={2}>
                                    <Box
                                        sx={{
                                            '& p.MuiTypography-root': { fontSize: { ms: "14px", xs: "14px" }, color: "#1D2939", mb: "8px", fontWeight: 400 },
                                            '& label': { fontSize: "14px", fontWeight: 500, lineHeight: "24px", letterSpacing: "0.4px" },
                                            '& .form-check': { width: "auto !important", color: "#fff" }
                                        }}
                                    >
                                        <FormikBooleanButtonsField
                                            required={false}
                                            singleLine={true}
                                            FieldTouchInput={true}
                                            col={12}
                                            fieldName={"isAnotherPermanentAddress"}
                                            fields={answerOptions}
                                            formik={formik}

                                            label={"Do you have a different permanent address other than the one stated on your CNIC?"}
                                        />
                                    </Box>
                                </Grid>

                                {formik?.values?.isAnotherPermanentAddress == "true" &&
                                    <Grid item md={6} xs={12}>
                                        <Typography sx={subHeadingSecStyle}>Proof of Address</Typography>
                                        <Typography color={"rgba(29, 41, 57, 0.70)"} fontSize={{ md: "14px", xs: "14px" }}>
                                            You can upload current or last month utility bills or your bank statement on which address is clearly mentioned
                                        </Typography>
                                        <UploadField required
                                            acceptedType='image/png, image/jpg, image/jpeg'
                                            buttonTitle="Upload file"
                                            col={6}
                                            fieldName="Document"
                                            formik={formik}
                                            imageError={false}
                                            inputFiles={[]}
                                            maxFiles={1}
                                            setImageError={() => { console.log("set image error") }}
                                            setImages={() => { console.log("set image error") }}
                                            title={""}
                                            permissionGranted={true}
                                            subTitle={"Please sign & upload the attached document"}
                                        />
                                    </Grid>
                                }

                            </Grid>

                            {!smScreen && buttonHtml()}
                        </Box>
                    </Grid>


                    {smScreen && <Box sx={smallScreenBtnWrap}>
                        {buttonHtml()}
                    </Box>}


                </Grid>

            </Container>

        </>
    );
}

export default AdditionalDetailsPermanentAddr;