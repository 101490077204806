import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import React, { useEffect } from 'react'
import { onGetAllUserQuestionAnswers, onGetUserQuestionAnswers } from 'redux/actions';
import UpdateProfile from "./index"
import { QuestionAnswerCategoryEnum, RETIREMENT_ID } from '../../../../constants';
import { onGetPersonalisedScore } from 'redux/reducers/sharedStateData';
import { useAppLoading } from 'hooks/appLoading';

const UpdateRiskProfile = ({ formik, formikRiskProfile, goalId, goalValue, nextStep, previousStep, setShowUpdateRiskProfile }: any) => {



    const dispatch = useAppDispatch();
    const { allUserSelectedAnswer, riskProfileUserQuestions } = useAppSelector(state => state.questionAnswer);
    const { investPortfolioAlloc, personalizeScore } = useAppSelector(state => state.sharedData);
    const loading = useAppLoading('personalizedPortfolio');
    const IS_Retirement_ACC = goalId == RETIREMENT_ID

    useEffect(() => {
        handleAPIOnUpdate()
    }, [])

    const handleAPIOnUpdate = () => {
        dispatch(onGetAllUserQuestionAnswers(QuestionAnswerCategoryEnum.RiskProfile));
    }
    const handlePrevStep = (formikCallback: any) => {
        const vpValues = formikCallback?.values;
        Object.keys(vpValues).forEach((key) => {
            formikRiskProfile.setFieldValue(key, vpValues[key]);
        });

        previousStep()
    }

    const handleNextStep = (formikCallback: any) => {
        nextStep(undefined, 2)
        getScore()
        const vpValues = formikCallback;
        Object.keys(vpValues).forEach((key) => {
            formikRiskProfile.setFieldValue(key, vpValues[key]);
        });
    }

    const getScore = () => {
        const numbersOfPayment = formik.values.number_of_payments
        dispatch(onGetPersonalisedScore(IS_Retirement_ACC));
        handleAPIOnUpdate()
    }

    useEffect(() => {
        checkCategory()
    }, [personalizeScore])

    const checkCategory = () => {
        const findCategory = investPortfolioAlloc.find((item: any) => personalizeScore >= item.minimumRiskScore && personalizeScore <= item.maximumRiskScore)
        formik.setFieldValue("recommendedPorfolio", findCategory)
    }

    return (
        <>
            <UpdateProfile
                setShowUpdateRiskProfile={setShowUpdateRiskProfile}
                previousStep={previousStep}
                selectedAnswer={allUserSelectedAnswer}
                profileQuestions={riskProfileUserQuestions}
                handleAPIOnUpdate={handleAPIOnUpdate}
                handleNextStep={handleNextStep}
                handlePrevStep={handlePrevStep}
            />
        </>
    )
}

export default UpdateRiskProfile