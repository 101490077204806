import { Avatar, Box, Chip, Collapse, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';
import moment from 'moment';
import ArrowUp from 'Latest/ComponentV1/ArrowUp';
import { CurrencyFormatter, CurrencyFormatterWithDecimal } from 'utils/calculation';
import TableStatusLabel from 'Latest/ComponentV1/TableStatusLabel';
import { labelTableStatus, transactionTypeArrow, transactionTypeArrowSigns } from '../../../constants';
// import saveImg from "Latest/Images/save+.png"
import saveImg from "Latest/Images/svg/savePlusChip.svg";
import investchip from "Latest/Images/svg/investchip.svg";
import { tokens } from 'theme';
import Pagination from '@mui/material/Pagination';
import NoData from 'Latest/Illustration/noData';
import { mobTransactionTable } from './style';
import { capitalizeFirstLetter } from 'utils/textTransform';




const TransactionHistoryTableMobile = ({ goalDetailView = false, lists, loading, row = 5 }: any) => {

    const screenSizeCheck = useMediaQuery('(min-width:374px)');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(row);

    const [openListItem, setOpenListItem] = React.useState<any>({});
    const handleClick = (item: any) => {
        setOpenListItem((prevState: any) => ({
            // ...prevState,
            [item]: !prevState[item],
        }));
    };



    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setOpenListItem({})
    };
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, lists.length - (page - 1) * rowsPerPage);

    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const totalPage = Math.ceil(lists?.length / rowsPerPage)
    const typeName = (item: any) => {
        // if (item?.matchingDateObj?.hasOnlyBonus) {
        //     return capitalizeFirstLetter("Dividendreinvest")
        // }
        if (item?.transactionType.toLowerCase() == "Dividendreinvest".toLowerCase()) {
            return "Dividend-Reinvested"
        } else if (item?.transactionType.toLowerCase() == "Reversalwithdrawal".toLowerCase()) {
            return "Reversal Withdrawal"
        } else if (item?.transactionType.toLowerCase() == "NadraFee".toLowerCase()) {
            return "Nadra Fee"
        } else if (item?.transactionType.toLowerCase() == "MobileVerificationFee".toLowerCase()) {
            return "Mobile Verification Fee"
        } else if (item?.transactionType.toLowerCase() == "ProcessingFee".toLowerCase()) {
            return "Processing Fee"
        }
        return capitalizeFirstLetter(item?.transactionType)
    }

    const arrowTransaction = (item: any, index: number) => {
        // if (item?.matchingDateObj?.hasOnlyBonus) {
        //     return <ArrowUp type={''} />
        // } else {
        //     return <ArrowUp type={transactionTypeArrow[item?.transactionType]} versionLight={!openListItem[index]} />
        // }
        return <ArrowUp type={transactionTypeArrow[item?.transactionType]} versionLight={!openListItem[index]} />
    }


    return (
        <> {loading ?
            <LoadingSkeleton />
            :
            lists?.length > 0 ?
                <>
                    {lists?.slice(startIndex, endIndex).map((item: any, index: number) => {
                        const isLastItem = startIndex + index === endIndex - emptyRows - 1;
                        return (
                            <Box key={index}
                                sx={{
                                    border: "1px solid #EAECF0",
                                    borderRadius: (theme) =>
                                        index === 0
                                            ? `6px 6px 0 0` // Apply rounded top corners to the first child
                                            : isLastItem
                                                ? `0 0 6px 6px` // Apply rounded bottom corners to the last child
                                                : 0, // No border radius for other children
                                }}
                            >
                                <List disablePadding component="div" sx={{ background: openListItem[index] ? colors.grey[100] : "" }}>
                                    <ListItemButton sx={{ p: "0px" }} onClick={() => handleClick(index)}>
                                        <ListItem>
                                            <ListItemAvatar sx={{ width: "32px", minWidth: "32px", mr: "12px" }}>
                                                {arrowTransaction(item, index)}
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Box sx={mobTransactionTable.primaryMainBox}>
                                                        <Box sx={mobTransactionTable.primarySubBox} >
                                                            {`${transactionTypeArrowSigns[item?.transactionType]} ${CurrencyFormatter(item?.amount, true)}`}
                                                            <TableStatusLabel label={labelTableStatus?.[item?.status]?.label} status={labelTableStatus?.[item?.status]?.status} />
                                                        </Box>
                                                        <Box sx={{ ml: "12px" }}>
                                                            {openListItem[index] ? <ExpandLess sx={{ color: colors.grey[600] }} /> : <ExpandMore />}
                                                        </Box>
                                                    </Box>
                                                }
                                                secondary={moment(item?.transactionDate).format('MMM DD, YYYY')}

                                                sx={{
                                                    ".MuiListItemText-primary": {
                                                        ...mobTransactionTable.primaryText,
                                                        color: colors.grey[600]
                                                    },
                                                    ".MuiListItemText-secondary": {
                                                        ...mobTransactionTable.secondaryText,
                                                        color: colors.grey[500]
                                                    }
                                                }}
                                            />
                                        </ListItem>
                                    </ListItemButton>
                                </List>

                                <Collapse unmountOnExit in={openListItem[index]} timeout="auto" >

                                    <Grid container sx={{ px: "8px", pt: "12px", pb: "16px" }} spacing={2}>

                                        <Grid item xs={screenSizeCheck ? 4 : 6} >
                                            <Typography sx={{ ...mobTransactionTable.tableCellStyleHead }}>Type</Typography>
                                            <Typography sx={{ ...mobTransactionTable.tableCellStyleBody, wordWrap: 'break-word' }}>{typeName(item)}</Typography>
                                        </Grid>

                                        <Grid item xs={screenSizeCheck ? 4 : 6} >
                                            <Typography sx={{ ...mobTransactionTable.tableCellStyleHead }}>Payment</Typography>
                                            <Typography sx={{ ...mobTransactionTable.tableCellStyleBody }}>{item?.paymentGateway}</Typography>
                                        </Grid>

                                        {!goalDetailView && <Grid item xs={screenSizeCheck ? 4 : 6}>
                                            <Typography sx={{ ...mobTransactionTable.tableCellStyleHead }}>Product</Typography>
                                            <Typography sx={{ ...mobTransactionTable.tableCellStyleBody }}>{item?.productName == "Retirement" ?
                                                // <TableStatusLabel label={'Retirement'} status={'success'} customStyle={{background:'#e4faff'}} /> 
                                                <Chip label={'Retirement'} sx={{
                                                    fontSize: "12px",
                                                    width: "130px",
                                                    whiteSpace: "nowrap",
                                                    background: "#D7E4FF",
                                                    color: "#113997",
                                                    fontWeight: "bold",
                                                    cursor: "default",
                                                }} />
                                                : item?.productName == "Invest" ? <img src={investchip} /> : <img src={saveImg} />}</Typography>
                                        </Grid>}

                                        {!goalDetailView && item?.productName == "Invest" && <Grid item xs={screenSizeCheck ? 9 : 12}>
                                            <Typography sx={{ ...mobTransactionTable.tableCellStyleHead }}>Goal name</Typography>
                                            <Typography sx={{ ...mobTransactionTable.tableCellStyleBody }}>{item?.goalName}</Typography>
                                        </Grid>}
                                        {/* <Grid item xs={screenSizeCheck ? 4 : 6} >
                                            <Typography sx={{ ...mobTransactionTable.tableCellStyleHead }}>Net Invest. (PKR)</Typography>
                                            <Typography sx={{ ...mobTransactionTable.tableCellStyleBody }}>{CurrencyFormatterWithDecimal(item.netValue, false, "never", false)}</Typography>
                                        </Grid> */}

                                    </Grid>

                                </Collapse >

                            </Box>


                        )
                    })}
                    {totalPage > 1 && emptyRows > 0 && (
                        <TableRow
                            style={{
                                height: (66) * emptyRows,
                            }}
                        >
                            <TableCell colSpan={7} />
                        </TableRow>
                    )}
                    <Box sx={{ pt: "15px", display: "flex", justifyContent: "center" }}>
                        <Pagination
                            count={totalPage}
                            page={page}
                            onChange={handleChangePage}
                            sx={{ "& .Mui-selected": { background: "#0000001F" }, color: "rgba(0, 0, 0, 0.87)" }}
                        />
                    </Box>
                </>
                :
                <NoData />
        }
        </>
    )
}

export default TransactionHistoryTableMobile


const LoadingSkeleton = () => {
    return (
        <>
            <Box sx={{ display: 'flex', gap: 1, alignItems: "center" }}>
                <Skeleton variant="circular" width={45} height={40} />
                <Skeleton variant="rectangular" width={"100%"} height={20} />
            </Box>
        </>
    )
}