import Big from 'big.js';
import { postGoalSuccess } from 'redux/actions';

const NumberOfMonths = 12;
const ProfitDefaultExpectedPercentage = 0.1;
const defaultCurrency = "PKR";
const defaultLocale = "en-PK";
const defaultDecimalDigits = 2;
// currencyDisplay symbol, narrowSymbol, code, name
const currencyFormatOptions: Intl.NumberFormatOptions = { style: 'currency', currency: defaultCurrency, signDisplay: "always", currencyDisplay: 'code' };

export const CurrencyFormatter = (amount: number | undefined, showPrefix = false, showSign: "auto" | "never" | "always" | "exceptZero" | undefined = 'auto', hideDecimal = false) => {
  if (!amount) {
    currencyFormatOptions.style = showPrefix ? 'currency' : 'decimal';
    currencyFormatOptions.maximumFractionDigits = hideDecimal ? 0 : defaultDecimalDigits;
    currencyFormatOptions.signDisplay = showSign;
    return Number(amount)?.toLocaleString(defaultLocale, currencyFormatOptions);
  }
  currencyFormatOptions.style = showPrefix ? 'currency' : 'decimal';
  currencyFormatOptions.maximumFractionDigits = hideDecimal ? 0 : defaultDecimalDigits;
  currencyFormatOptions.signDisplay = showSign;
  return Number(amount)?.toLocaleString(defaultLocale, currencyFormatOptions);
};

// this will not round off any value
export const CurrencyFormatterWithDecimal = (amount: number | undefined, showPrefix = false, showSign: "auto" | "never" | "always" | "exceptZero" | undefined = 'auto', hideDecimal = true , roundDown=false) => {

  if (typeof amount === 'undefined') {
    currencyFormatOptions.style = showPrefix ? 'currency' : 'decimal';
    currencyFormatOptions.maximumFractionDigits = hideDecimal ? 0 : defaultDecimalDigits;
    currencyFormatOptions.signDisplay = showSign;
    return Number(amount).toLocaleString(defaultLocale, currencyFormatOptions);
  }

  let formattedAmount;
  if (roundDown) {
    formattedAmount =  new Big(amount).round(0,Big.roundDown);
   // Remove decimals without rounding
  } else if (hideDecimal) {
    formattedAmount = new Big(amount).mul(100).div(100).toFixed(2, 0); // Round to the nearest integer if hideDecimal is true
  } else {
    formattedAmount = new Big(amount).toFixed(2, 0); // Show two decimal places if hideDecimal is false
  }

  currencyFormatOptions.style = showPrefix ? 'currency' : 'decimal';
  currencyFormatOptions.maximumFractionDigits = hideDecimal ? 0 : defaultDecimalDigits;
  currencyFormatOptions.minimumFractionDigits = hideDecimal ? 0 : defaultDecimalDigits;
  currencyFormatOptions.signDisplay = showSign;

  return Number(formattedAmount).toLocaleString(defaultLocale, currencyFormatOptions);
};

export const roundToNearestThousand =(number:number) => {
  return Math.round(number / 1000) * 1000;
}

export const FormulaForMonthlysavings = (props: IFormulaeValues) => {
  const ratePerMonth = new Big(ProfitDefaultExpectedPercentage).div(
    NumberOfMonths
  );
  let initialInvestmentFutureValue = new Big(0);
  if (props.initialAmount > 0) {
    initialInvestmentFutureValue = new Big(props.initialAmount).mul(
      ratePerMonth.add(1).pow(props.numberOfPayments)
    );
  }

  const sum = ratePerMonth
    .add(1)
    .pow(props.numberOfPayments)
    .sub(1)
    .div(ratePerMonth)
    .mul(Number(props.monthlySavings))
    .add(initialInvestmentFutureValue);
  return Number(sum);
};

// export const FormulaeForRetirementSavings = (props: IFormulaeValues) => {
//   const ratePerMonth = new Big(ProfitDefaultExpectedPercentage).div(NumberOfMonths);

//   let initialInvestmentFutureValue = new Big(0);
//   initialInvestmentFutureValue = new Big(props.initialAmount).mul(ratePerMonth.add(1).pow(props.numberOfPayments));

//   const futureValueForRetirment = new Big(Number(props.retirementAmount))
//     .sub(initialInvestmentFutureValue)
//     .mul(ratePerMonth)
//     .div(ratePerMonth.add(1).pow(props.numberOfPayments).sub(1));

//   return futureValueForRetirment.round().toNumber();
// };

export const FormulaeForRetirementSavings = (props: IFormulaeValues) => {
  const ratePerMonth = new Big(ProfitDefaultExpectedPercentage).div(NumberOfMonths);
  const initialAmount = props.initialAmount
  const numberOfPayments = props.numberOfPayments
  const divisbleRate = numberOfPayments ? ratePerMonth.add(1).pow(numberOfPayments) : ratePerMonth.add(1)

  let initialInvestmentFutureValue = new Big(0);
  initialInvestmentFutureValue = new Big(initialAmount).mul(divisbleRate)

  const futureValueForRetirment = new Big(Number(props.retirementAmount))
    .sub(initialInvestmentFutureValue)
    .mul(ratePerMonth)
    .div(divisbleRate.sub(1));

  return futureValueForRetirment.round().toNumber();
};


export const formatYAxis = (value: any, index?: number): string => {
  let formattedNumber;
  let num = value
  num = Math.abs(num)
  if (num >= 1000000000) {
    formattedNumber = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  } else if (num >= 1000000) {
    formattedNumber = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (num >= 100000) {
    formattedNumber = num.toLocaleString();
  } else if (num >= 1000) {
    formattedNumber = num.toLocaleString();
  } else {
    formattedNumber = num;
  }
  return formattedNumber;
};


export const kFormatter = (nums: any) => {
  let formattedNumber;
  const num = Math.abs(nums)
  if (num >= 10000000000) {
    formattedNumber = (num / 1000000000).toFixed(0) + 'B';
  } else if (num >= 10000000) {
    formattedNumber = (num / 1000000).toFixed(0)+ 'M';
  }
  else if (num >= 10000) {
    formattedNumber = (num / 1000).toFixed(0)+ 'K';
  } else {
    formattedNumber = CurrencyFormatterWithDecimal(num, false);
  }
  return formattedNumber;
}

export const kFormatterV1 = (nums: any) => {
  let formattedNumber;
  const num = Math.abs(nums);

  if (num >= 1000000000) { // Billions
    formattedNumber = (num / 1000000000).toFixed(num >= 10000000000 ? 0 : 1).replace(/\.0$/, '') + 'B';
  } else if (num >= 1000000) { // Millions
    formattedNumber = (num / 1000000).toFixed(num >= 10000000 ? 0 : 1).replace(/\.0$/, '') + 'M';
  } else if (num >= 1000) { // Thousands
    formattedNumber = (num / 1000).toFixed(num >= 10000 ? 0 : 1).replace(/\.0$/, '') + 'K';
  } else { // Less than 1,000
    formattedNumber = num.toFixed(0);  // No decimal points for small numbers
  }

  return formattedNumber;
}


// export const kFormatter = (nums: any) => {
//   let formattedNumber;
//   const num = Math.abs(nums)
//   if (num >= 1000000000) {
//     // formattedNumber = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
//     formattedNumber = (num / 1000000000).toFixed(0) + 'B';
//   } else if (num >= 1000000) {
//     // formattedNumber = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
//     formattedNumber = (num / 1000000).toFixed(0)+ 'M';
//   }
//   // else if (num >= 100000) {
//   //     formattedNumber = (num / 100000).toFixed(1).replace(/\.0$/, '') + 'K';
//   //     // formattedNumber = CurrencyFormatter(num, false);
//   // } 
//   else if (num >= 10000) {
//     // formattedNumber = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
//     formattedNumber = (num / 1000).toFixed(0)+ 'K';
//     // formattedNumber = CurrencyFormatter(num, false);
//   } else {
//     formattedNumber = CurrencyFormatterWithDecimal(num, false);
//   }
//   return formattedNumber;
// }

export function generateTickValues(start: any, end: any, count: any) {
  const interval = (end - start) / (count - 1);
  return Array.from({ length: count }, (_, i) => Math.round(start + interval * i));
}

export function isSizeGreaterThan25KB(file1: any, file2: any) {
  const maxSize = 25000; // 25 KB in bytes
  return file1.size > maxSize && file2.size > maxSize;
}


interface IFormulaeValues {
  numberOfPayments: number;
  initialAmount: number;
  monthlySavings?: number;
  expextedRetirementAge?: number;
  retirementAmount?: number;
  retirement_age?: number;
}
