import React from 'react'
import StaticContent from '../staticContent'
import { Box, Typography } from '@mui/material'
import { CurrencyFormatterWithDecimal } from 'utils/calculation'
import { intialInvestmentMaxLimit } from '../../../../constants'

const Pending = () => {
  const maxLimit = CurrencyFormatterWithDecimal(intialInvestmentMaxLimit, true, "never")
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: "flex-start", flexDirection: { xs: "column", md: "row" }, gap: { xs: "20px", md: "32px" }, justifyContent: "space-between" }}>
        <StaticContent text={`You can now invest above ${maxLimit}`} />

        <Typography sx={{
          borderRadius: "64px", p: "6px 12px", background: '#12B76A', color: "white", fontSize: "15px!important",
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.14px",
          whiteSpace: "nowrap",
        }}>
          Verified
        </Typography>

      </Box>

    </>
  )
}

export default Pending