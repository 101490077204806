import { Box, Button, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomBreadcrumb from './CustomBreadcrumb'
import mahaanaFullLogo from "Latest/Images/svg/mahaanaFullLogo.svg"
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'hooks/reduxHooks';
import { onLogout } from 'redux/actions';
import SideBarSmallScreen from "../SideBar/sideBarSmallscreen"
import HelpIcon from "Latest/Images/svg/HelpIcon";
import { checkHelpCrunch, helpCrunchHideWidget, helpCrunchShowWidget, helpCrunchTrigger } from 'Latest/HelpCrunch';
import LogoutIcon from 'Latest/Images/svg/LogoutIcon';
import HelpIconV2 from 'Latest/Images/svg/HelpIconV2';
import { links } from 'static/links';
import { HelpModal } from 'Latest/ModalV2/helpModal';


const TopBarLogo = ({ addExtraRoutes = [], removeRouteCustom = [], showNavbarItems = true }: props) => {

    const [openModal, setOpenModal] = useState(false);

    const dispatch = useAppDispatch();
    const logoutHandle = () => dispatch(onLogout());

    const theme = useTheme();

    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
        logoutHandle();
    }

    const modalOpenHandler = () => {
        setOpenModal(true)
    }

    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const checkHC = checkHelpCrunch();

    useEffect(() => {
        widget()
    }, [xsScreen, checkHC]);

    const widget = () => {
        if (xsScreen && checkHC) {
            setTimeout(() => {
                helpCrunchHideWidget();
            }, 500);
        } else {
            helpCrunchShowWidget();
        }
    }

    const newUserRoutes = [
        { title: "Need help?", route: "/support", icon: HelpIconV2, isNavlink: true, navDropDown: [], target: false },
        //{ title: "Need help?", route: null, icon: HelpIconV2, isNavlink: false, navDropDown: [], target: false , onClick: modalOpenHandler, functionTrigger: true },
        // { title: "Need help?", route: null, icon: HelpIcon, isNavlink: false, navDropDown: [], target: false, onClick: () => helpCrunchTrigger(), functionTrigger: true },
        { title: "Log out", route: null, icon: LogoutIcon, isNavlink: false, navDropDown: [], target: false, onClick: handleLogout, functionTrigger: true },
    ]

    // const removeRoute = smScreen ? [] : ["Need help?"];
    const removeRoute = removeRouteCustom;
    const mergeAllRoute = newUserRoutes?.concat(addExtraRoutes)

    const removeRouteItems = (array: any, removeRoute: any) => {
        return array?.filter((item: any) => !removeRoute?.includes(item?.title));
    };

    const newUserCustomRoute = removeRouteItems(mergeAllRoute, removeRoute);

    return (
        <>
            <Box sx={{
                width: "100%", background: "white",
                //  p: { xs: "16px", md: "12px 64px" },
                borderBottom: "1px solid rgba(208, 213, 221, 0.25)",
                py: { xs: "16px", md: "12px" },
            }}>
                <Container maxWidth="xl">
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Link to="/auth/login" style={{
                            width: "156.357px",
                            height: "25.143px"
                        }}>
                            <img src={mahaanaFullLogo} width={"100%"} />
                        </Link>

                        {(() => {
                            if (showNavbarItems) {
                                return (
                                    <>
                                        {smScreen ?
                                            <SideBarSmallScreen newUserCustomRoute={newUserCustomRoute} />
                                            :
                                            <>
                                                <Stack direction="row" spacing={2}>
                                                    {newUserCustomRoute.map((item: any, index: number) => {

                                                        {
                                                            return (
                                                                item?.route == null ? (
                                                                    <Button
                                                                        key={index}
                                                                        variant="text"
                                                                        color="inherit"
                                                                        sx={{ color: "#475467", fontSize: "14px", textTransform: "none", fontWeight: 400 }}
                                                                        onClick={item.onClick}
                                                                    >
                                                                        {item?.title}
                                                                    </Button>
                                                                ) : (
                                                                    <Link to={item.route} style={{ color: "#475467", fontSize: "14px", textTransform: "none", fontWeight: 400, paddingTop: "6px" }}>{item?.title}</Link>
                                                                )
                                                            )
                                                        }
                                                    })}
                                                </Stack>
                                            </>
                                        }
                                    </>
                                );
                            }
                        })()}



                    </Box>
                </Container>

            </Box>

            <HelpModal open={openModal} handleCLose={() => setOpenModal(false)} />
        </>
    )
}

export default TopBarLogo

interface props {
    showNavbarItems?: boolean;
    addExtraRoutes?: any;
    removeRouteCustom?: any
}