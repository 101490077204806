/* eslint-disable sort-keys */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
    openModal: false,
    modalSpec: null,
    openModalInitPhEm: false,
};

const name = 'userPhoneEmailVerifyModal';
const userPhoneEmailVerifyModalSlice = createSlice({
    initialState,
    name,
    reducers: {
        handleUserPhEmVerifyModal: (state, action: PayloadAction<boolean>) => {
            state.openModal = action.payload;
        },
        handleUserPhEmVerifyModalSpec: (state, action: PayloadAction<string | null>) => {
            state.modalSpec = action.payload;
        },
        handleChangePhEmModal: (state, action: PayloadAction<boolean>) => {
            state.openModalInitPhEm = action.payload;
        },
    }
});
export const { handleChangePhEmModal, handleUserPhEmVerifyModal, handleUserPhEmVerifyModalSpec } = userPhoneEmailVerifyModalSlice.actions;
export default userPhoneEmailVerifyModalSlice.reducer;

//? APIS ACTIONS


//? Types
interface InitialState {
    openModal: boolean;
    modalSpec: string | null;
    openModalInitPhEm: boolean;
}
