import FormikBooleanButtonsField from 'jsx/components/Shared/V1/FormikBooleanBtnFieldBoxV1';
import FormikCheckboxField from 'jsx/components/Shared/FormikCheckboxField';
import FormikInputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import { parsePhoneNumber } from 'libphonenumber-js';
import UploadFileCard from '../UploadProfOfIncome/uploadFileCard';
import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { Box, Grid, Typography, Button } from '@mui/material';
import alertIcon from 'Latest/Images/svg/WarningDiamond.svg';
import CustomSingleCheckBox from 'jsx/components/Shared/CustomSingleCheckBox';
import { userUpdateRequestStatus, UserUpdateRequestType, VerificationMethod } from "../../../../constants/index"
import { handleChangePhEmModal, handleUserPhEmVerifyModalSpec } from 'redux/reducers/userPhoneEmailVerification';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { onGetUserUpdateRequest } from 'redux/reducers/sharedStateData';


const PhoneQuestions = ({ formik, setIsRequestNumOpenModal }: PhoneQuestion) => {

  const { push } = useHistory();

  const loading = useAppLoading('onPostSecondTier');
  const { userAccountDetails } = useAppSelector((state: any) => state.auth);
  const uploadedIntlPhoneBill = userAccountDetails?.kyc?.kycDocument?.internationalPhoneBill ? [userAccountDetails?.kyc?.kycDocument?.internationalPhoneBill] : []
  const uploadedUmployerPhoneBill = userAccountDetails?.kyc?.kycDocument?.employerPhoneBill ? [userAccountDetails?.kyc?.kycDocument?.employerPhoneBill] : []
  const uploadedEmployerAuthorizationLetter = userAccountDetails?.kyc?.kycDocument?.employerAuthorizationLetter ? [userAccountDetails?.kyc?.kycDocument?.employerAuthorizationLetter] : []
  const dispatch = useAppDispatch();
  const { userUpdateRequest } = useAppSelector(state => state.sharedData);

  useEffect(() => {
    dispatch(onGetUserUpdateRequest())
  }, [])


  const Options = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];
  const Label = [{ label: 'I’m authorized to use this phone number by my family member', value: 'check' },]

  const familyMemberOptions = [
    { label: 'FAMILY MEMBER', value: 'FAMILY MEMBER' },
    { label: 'EMPLOYER', value: 'EMPLOYER' },
  ];


  const Labels = [
    { fieldName: 'FamilyMemberName', label: 'Family Member Name' },
    { fieldName: 'CNICNumber', label: 'CNIC Number' },
    { fieldName: 'Relationship', label: 'Relationship' },
  ];

  const PhoneNumber = formik.values.contactNumber

  const isPakistaniNumber = (phoneNumber: string) => {
    try {
      const parsedNumber = parsePhoneNumber(phoneNumber, 'PK');
      return parsedNumber?.country === 'PK';
    } catch (error) {
      return false;
    }
  };


  const handleChangeNum = () => {
    const checkPhoneUpdateStatus = userUpdateRequest?.some((x) => x?.requestStatus == userUpdateRequestStatus.InProgressAdmin || x?.requestStatus == userUpdateRequestStatus.InProgressThirdParty)
    push("myprofile")
    if (!checkPhoneUpdateStatus) {
      dispatch(handleUserPhEmVerifyModalSpec(VerificationMethod.phoneNumber))
      dispatch(handleChangePhEmModal(true))
    }
  }

  return (
    <>
      <Grid item md={12} sx={{ pt: { xs: "30px", md: '12px' } }}>
        <FormikBooleanButtonsField
          required={false}
          singleLine={true}
          col={12}
          fieldName={'numberRegisterBelonging'}
          fields={Options}
          formik={formik}
          label={`You’ve mentioned ${PhoneNumber} as your phone number. Is this number registered in your name?`}
        />
      </Grid>




      {formik.values.numberRegisterBelonging === "Yes" && (
        <Box sx={{ minHeight: '48px', gap: "4px", display: 'flex', alignItems: { xs: "flex-start", md: 'center' }, mb: '24px', pt: { xs: "16px", md: '8px' } }}>
          <img src={alertIcon} />
          <Typography variant='alertTitle2' fontWeight={{ xs: 400, md: 500 }} color={'#821B13'}>Number verification is run to verify ownership. Any incorrect information will result in delay of upgrading your account.</Typography>
        </Box>
      )}

      {/* Displaying specific content if it's an international number */}
      {!isPakistaniNumber(PhoneNumber) && (
        <Box sx={{ mt: 2, mb: 5 }}>
          <Typography variant='body1' color={'#344054'} mb={1}>
            You’re using an international number, please upload your phone bill.
          </Typography>
          <UploadFileCard formik={formik} fieldName={"uploadIntPhoneBillImg"} loading={loading} uploadedFile={uploadedIntlPhoneBill} fileCountLimit={1} multipleFile={false} />
        </Box>
      )}









      {formik.values.numberRegisterBelonging === 'No' && (
        <>
          <Grid item md={12} mb={1} mt={'24px'}>
            <FormikBooleanButtonsField
              fieldName={'numberBelonging'}
              fields={familyMemberOptions}
              formik={formik}
              label={'Does this phone number belong to your family member or employer?'}
              formCheckStyle={{ width: '100%' }}
            />
          </Grid>

          {formik.values.numberBelonging === 'FAMILY MEMBER' && (
            <>
              <Grid sx={{ display: 'flex', gap: '24px', marginBottom: '16px', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, mt: '16px' }}>
                {Labels.map(({ fieldName, label }) => (
                  <FormikInputField
                    key={fieldName}
                    required={false}
                    formik={formik}
                    label={label}
                    name={fieldName}
                    maxLength={50}
                    placeholder={`Enter ${label}`}
                  />
                ))}
              </Grid>

              <Grid item xs={12} md={12} >
                <CustomSingleCheckBox
                  formik={formik}
                  fieldName={`authToUseThisNum`}
                  label={`I’m authorized to use this phone number by my family member`}
                  type={"checkbox"}
                  styleForm={{ paddingLeft: "0px" }}
                  styleErr={{ marginLeft: "0px" }}
                />
              </Grid>


              <Button
                onClick={() => handleChangeNum()}
                variant="text"
                sx={{ mb: '6px', mt: '24px', p: 0, color: '#432F87', textTransform: 'none', fontSize: 'h5.fontSize', fontWeight: 'medium', textDecoration: 'underline', '&:hover': { background: 'transparent' } }}>
                Change your phone number
              </Button>

            </>
          )}

          {formik?.values?.numberBelonging === 'EMPLOYER' && (
            <>
              <Grid container spacing={2} >
                <Grid item xs={12} md={isPakistaniNumber(PhoneNumber) ? 6 : 12}>
                  <Typography variant="h6" sx={{ marginBottom: '5px', marginTop: '16px' }}>
                    Upload authorization letter
                  </Typography>
                  <UploadFileCard formik={formik} fieldName={"uploadAuthImg"} loading={loading} uploadedFile={uploadedEmployerAuthorizationLetter} fileCountLimit={1} multipleFile={false} />
                </Grid>
                {isPakistaniNumber(PhoneNumber) && <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ marginBottom: '5px', marginTop: '16px' }}>
                    Upload phone bill
                  </Typography>
                  <UploadFileCard formik={formik} fieldName={"uploadPhoneBillImg"} loading={loading} uploadedFile={uploadedUmployerPhoneBill} fileCountLimit={1} multipleFile={false} />
                </Grid>}

                <Grid item xs={12} md={12} >
                  <CustomSingleCheckBox
                    formik={formik}
                    fieldName={`authToUseThisNum`}
                    label={`I’m authorized to use this phone number by my employer`}
                    type={"checkbox"}
                    styleForm={{ paddingLeft: "0px" }}
                    styleErr={{ marginLeft: "0px" }}
                  />
                </Grid>
              </Grid>


              <Button
                onClick={() => handleChangeNum()}
                variant="text"
                sx={{ mb: '6px', mt: '24px', p: 0, color: '#432F87', textTransform: 'none', fontSize: 'h5.fontSize', fontWeight: 'medium', textDecoration: 'underline', '&:hover': { background: 'transparent' } }}>
                Change your phone number
              </Button>
            </>
          )}
        </>
      )}




    </>

  )
}


export default PhoneQuestions;

interface PhoneQuestion {
  formik?: any,
  setIsRequestNumOpenModal?: any,
}
