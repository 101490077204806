import { Box, Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { CASH_FUND_ID, mixPannelTrackerEventName, RETIREMENT_ID } from '../../../../constants';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { onGetAccountSetup, onGetDashboard } from 'redux/actions';
import { CurrencyFormatterWithDecimal } from 'utils/calculation';
import ErrModal from 'Latest/MyProfile/Modal/errModal';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';
import { mainContainerV2, secBg, smallScreenBtnWrap } from 'Latest/Style/Style';

const ReviewInfo = ({ checkAddress, checkIban, checkMotherName, checkNic, formik, goalId, goalName, goalValue, loading, nextStep, previousStep, submitGoal }: any) => {

    const { userAccountDetails } = useAppSelector(state => state.auth);
    const { addGoalBody, personalisedCategory, personalizeScore } = useAppSelector(state => state.sharedData)
    const dispatch = useAppDispatch();
    const [category, setCategory] = useState<any>({})

    const IS_CASH_ACC = goalId == CASH_FUND_ID
    const IS_GI_ACC = goalId < CASH_FUND_ID
    const IS_Retirement_ACC = goalId == RETIREMENT_ID


    const [openErrModal, setOpenErrModal] = useState(false);
    const { user: { email } } = useAppSelector((state: any) => state.auth);
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        submitGoal()
        dispatch(onGetAccountSetup());
        console.log(formik);
    }, [])

    useEffect(() => {
        checkCategory()
        dispatch(onGetDashboard())
    }, [])

    useEffect(() => {
        formik.setFieldValue('specificGoalScore', personalizeScore);
    }, [personalizeScore])

    const checkCategory = () => {
        const findCategory = personalisedCategory.find((item: any) => personalizeScore >= item.scoreFrom && personalizeScore <= item.scoreTo && item.name)
        setCategory(findCategory)
    }

    const ContentFailedModal = (customMess: any) => {
        return (
            <Grid item xs={12} sx={{ pt: "8px" }}>
                <Typography sx={ModalStyle.headStyle}>Oops!</Typography>
                <Typography sx={ModalStyle.subheadStyle}>
                    Please provide both your permanent and mailing address for record-keeping purposes.
                </Typography>
            </Grid>
        )
    }

    const handleCheckAddress = () => {
        if (userAccountDetails?.permanentAddress === null || userAccountDetails?.mailingAddress === null) {
            setOpenErrModal(true)
        } else {
            nextStep();
            MixPannelTracker({ eventName: mixPannelTrackerEventName.reviewed_submission, email })

        }
    }

    const handleModalBtn = () => {
        previousStep();
    }

    const handleBackBtn = () => {

        if (IS_GI_ACC) {                                                           //For GI
            if (userAccountDetails?.motherName && !checkMotherName) {
                previousStep(undefined, 2);
                return;
            } else if (userAccountDetails?.permanentAddress && !checkAddress) {
                previousStep(undefined, 3);
                return;
            } else if (userAccountDetails?.isAccountSetupComplete && !checkNic) {
                previousStep(undefined, 5);
                return;
            } else {
                previousStep(undefined, 6);
                return;
            }
        } else if (IS_Retirement_ACC) {
            if (userAccountDetails?.permanentAddress && !checkAddress) {
                previousStep();
                return;
            } else if (userAccountDetails?.isAccountSetupComplete && checkNic) {
                previousStep(undefined, 5);
                return;
            } else {
                previousStep();
                return;
            }
        } else if (IS_CASH_ACC) {                                                     //For CF
            if (userAccountDetails?.permanentAddress && !checkAddress) {
                previousStep();
                return;
            } else if (userAccountDetails?.isAccountSetupComplete && checkNic) {
                if (IS_CASH_ACC && dashboardDetail?.status != "New") {
                    previousStep(undefined, 6)
                    return;
                } else {
                    previousStep(undefined, 6);
                    return;
                }
            } else {
                previousStep();
                return;
            }

        }





    }

    const bannerItems = [
        { title: "Product", value: IS_CASH_ACC ? "Mahaana Save+" : (goalName == "" ? goalValue : goalName) },
        { title: "Initial investment", value: (CurrencyFormatterWithDecimal(Number(formik.values.initial_amount), false, "never", true, true)) }
    ]

    const infoItems = [
        { title: "Name", value: userAccountDetails?.identityCard?.name },
        { title: userAccountDetails?.identityCard?.gender == "Female" ? "Father's / Husband's name" : "Father's name", value: userAccountDetails?.identityCard?.fatherName },
        { title: "Date of birth", value: userAccountDetails?.identityCard?.dateOfBirth },
        { title: "Email address", value: userAccountDetails?.email || "-" },
        { title: "Mobile number", value: userAccountDetails?.contactNumber || "-" },
        { title: "CNIC number", value: userAccountDetails?.identityCard?.identityCardNumber },
        { title: "CNIC issue date", value: userAccountDetails?.identityCard?.dateOfIssue },
        { title: "CNIC expiry date", value: userAccountDetails?.identityCard?.lifeTimeExpiry ? "Life time" : userAccountDetails?.identityCard?.dateOfExpiry },
        { title: "Gender", value: userAccountDetails?.identityCard?.gender },
        { title: "Country of stay", value: userAccountDetails?.identityCard?.countryOfStay || '-' },
        {
            title: "Permanent address",
            value: userAccountDetails?.permanentAddress?.lines.map((i: any) => <>{i}&nbsp;</>),
            subValue: userAccountDetails?.permanentAddress?.city + ", " + userAccountDetails?.permanentAddress?.province
        },
        {
            title: "Mailing address",
            value: userAccountDetails?.mailingAddress?.lines.map((i: any) => <>{i}&nbsp;</>),
            subValue: userAccountDetails?.mailingAddress?.city + ", " + userAccountDetails?.mailingAddress?.province
        },
        { title: "IBAN", value: userAccountDetails?.iban || "-" },
        (!IS_CASH_ACC && userAccountDetails?.ukn != "") && { title: "Ukn", value: userAccountDetails?.ukn || "-" },
    ]

    const buttonHtml = () => {
        return (
            <ButtonRowV1 btnTitleNext={"Next"} handleNext={handleCheckAddress} handleBack={handleBackBtn} loadingNextBtn={loading} />
        )
    }

    return (
        <>
            <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ py: { xs: "24px", md: "40px" } }}>
                <ErrModal
                    handleOpen={() => setOpenErrModal(true)} open={openErrModal} handleClose={() => setOpenErrModal(false)}
                    Content={ContentFailedModal} btnTitle={"Go back to address screen"} customOnClose={handleModalBtn}
                />

                <Grid container>

                    <Grid item md={12} xs={12} sx={mainContainerV2}>

                        <Box width={"100%"}>
                            <Typography variant='h4' color={"#1D2939"} lineHeight={"normal"} textAlign={"center"}>Review your information</Typography>

                            <Grid item md={12} xs={12} p={2} mt={2} sx={secBg}>
                                {/* <Grid item md={12} xs={12} pb={1}>
                                    <Typography color={"#fff"} fontSize={{ md: "16px", xs: "16px" }}>Investment Details</Typography>
                                </Grid> */}

                                <Grid container spacing={{ md: 3, xs: "12px" }}>
                                    {bannerItems?.map((item: any, index: any) => {
                                        return (
                                            item && <Grid key={index} item xs={12} sm={6} md={6}>
                                                <Typography variant='body1' color={'#fff'} pb={"4px"}>{item.title}</Typography>
                                                <Typography variant='body1' color={'#fff'} fontWeight={500}>{item.value}</Typography>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>

                        </Box>

                        <Box>
                            <Grid container spacing={{ md: "18px", xs: 2 }} sx={{ mb: "40px" }}>

                                {infoItems?.map((item: any, index: any) => {
                                    return (
                                        <Grid key={index} item md={4} sm={6} xs={12}>
                                            <Typography variant='body2' fontSize={{ md: "14px", xs: "14px" }} color={"rgba(29, 41, 57, 0.70)"}>{item.title}</Typography>
                                            <Typography variant='body2' sx={{ wordBreak: "break-word" }} fontSize={{ md: "14px", xs: "14px" }} color={"#1D2939"} fontWeight={500}>
                                                {item.value}
                                                {item.subValue}
                                            </Typography>
                                        </Grid>
                                    )
                                })}

                            </Grid>

                            {!smScreen && buttonHtml()}
                        </Box>
                    </Grid>

                    {smScreen && <Box sx={smallScreenBtnWrap}>
                        {buttonHtml()}
                    </Box>}
                </Grid>
            </Container>
        </>
    )
}

export default ReviewInfo;
