import React, { useEffect, useState } from 'react'
import CustomCard from 'Latest/ComponentV1/CustomCard'
import CustomCard1 from 'Latest/ComponentV1/Customcard1'
import { useHistory } from 'react-router-dom';
import RetirementLogo from "Latest/Images/retirementLogo.png"
import { Box, ClickAwayListener, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { tokens } from 'theme';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import { useAppSelector } from 'hooks/reduxHooks';
import infoIconSvg from "Latest/Images/svg/Info.svg"
import { ApprovedCard, DepositPendingCard, VerificationPendingCard } from './Cards';
import { goalCategory } from '../../../constants/index';


const tootltipContent = "Return is extended to a one year period with the assumption that performance remains the same as before. This is also called an annualized value."

const RetirementCard = () => {
    
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
    const { isVPSAllowed } = useAppSelector(state => state.auth);

    const Data = dashboardDetail
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const { push } = useHistory();
    const [retirementGoal, setRetirementGoal] = useState<any>([]);

    const handleInvest = () => {
        push("/retirement", { RedirectLink: "dashboard" })
    }

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setIsTooltipOpen(true);
    };

    const filterSavePlus = Data?.goals?.filter((item: any, index: number) => item.category.toLowerCase() == goalCategory.Retirement.toLowerCase())
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if (Data?.goals?.length > 0) {
            const goal = Data?.goals?.filter((item: any, ind: number) => item?.category.toLowerCase() == goalCategory.Retirement.toLowerCase())
            setRetirementGoal(goal)
        }
    }, [Data])

    const depositPendingData = { title: "Cash management account", ...retirementGoal[0] }

    const verificationPendingData = {
        title: "Cash management account",
        ...retirementGoal[0]
    }

    const customTooltip = () => {
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={isTooltipOpen}
                    title={tootltipContent}
                >
                    <img src={infoIconSvg} alt='info' onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        handleTooltipOpen();
                    }} style={{ cursor: "pointer" }} />
                </Tooltip>
            </ClickAwayListener>
        )
    }


    const renderGrids = (num: number) => {
        if (!retirementGoal[0]?.isApprovedPayment && retirementGoal[0]?.potDetails?.initialInvestmentAmount == 0) {
            return <DepositPendingCard data={depositPendingData} margin={num} />;
        }
        else if (!retirementGoal[0]?.isApprovedPayment && retirementGoal[0]?.potDetails?.initialInvestmentAmount > 500 && retirementGoal[0]?.lastTransactionStatus?.toLowerCase()?.includes("fail")) {
            return <DepositPendingCard data={depositPendingData} margin={num} />;
        }
        else if (!retirementGoal[0]?.isApprovedPayment && retirementGoal[0]?.potDetails?.initialInvestmentAmount >= 500 && !retirementGoal[0]?.lastTransactionStatus?.toLowerCase()?.includes("fail")) {
            return <VerificationPendingCard data={verificationPendingData} margin={num} />
        }
        else if (retirementGoal[0]?.isApprovedPayment && (retirementGoal[0]?.potDetails?.initialInvestmentAmount >= 500 || retirementGoal[0]?.potDetails?.portfolioAmount > 0)) {
            return <ApprovedCard data={retirementGoal[0]} component={customTooltip()} margin={num} onClick={() => push("/viewDetail", { goalInfo: filterSavePlus[0] })} />
        }
        else {
            return null; // Return null or another default component if none of the conditions are met
        }
    };

    return (
        <>
            {/* <CustomCard heading='Mahaana Retirement'
                content={<CustomCard1 title={"Open your retirement account"} imgIcon={RetirementLogo} btnTitle={"Coming soon"} type={"Retirement"} />
                } /> */}

            {/* <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant='h4' color={colors.purple[100]} lineHeight={"30px"}>Mahaana Retirement</Typography>
                <LoadingBtnCustom disabled={true} btnTitle={"Coming soon"} style={{ py: 0, height: "37px", fontWeight: 400, minWidth: "fit-content" }} />
            </Box> */}

            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant='h4' color={colors.purple[100]} lineHeight={"30px"}>Mahaana Retirement</Typography>
                {!retirementGoal.length && <LoadingBtnCustom disabled={!isVPSAllowed} btnTitle={isVPSAllowed ? "Start Now" : "Coming soon"} style={{ py: 0, height: "37px", fontWeight: 400, minWidth: "fit-content" }} handleOnClick={handleInvest} />}
            </Box>


            {retirementGoal?.length > 0 ? <Grid container mt={2}>
                {renderGrids(2)}
            </Grid> : null}
        </>
    )
}

export default RetirementCard