import { ThemeContext } from 'context/ThemeContext';
import { AnimatePresence, motion } from 'framer-motion';
import CompanyProfile from 'jsx/components/Thematic/CompanyProfile';
import Auth from 'jsx/containers/Auth';
import AccountSetupForm from 'jsx/containers/AccountSetupForm';
import DocumentsUpload from 'jsx/containers/DocumentsUpload';
import Dashboard from 'jsx/containers/DashboardLatest';
import FundDetails from 'jsx/containers/FundDetails';
import FundsOverview from 'jsx/containers/FundsOverview';
import GoalsOverview from 'jsx/containers/GoalsOverview';
import Learn from 'jsx/containers/Learn';
import PaymentOptions from 'jsx/containers/PaymentOptions';
import Pension from 'jsx/containers/Pension';
import Portfolio from 'jsx/containers/Portfolio';
import Thematic from 'jsx/containers/Thematic';
import Basket from 'jsx/containers/Basket';
import TransactionHistory from 'jsx/containers/TransactionHistory';
import Footer from 'jsx/layouts/Footer';
import Header from 'jsx/layouts/Header';
import LinkAccounts from 'jsx/pages/LinkAccounts';
import { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { links } from 'static/links';
import EmployerScreen from '../pages/EmployerScreen';
import ErrorBoundary from './ErrorBoundary';
import PrivateRoute from './PrivateRoute';
import Scroller from './Scroller';
import AccountConfirmation from 'jsx/pages/AccountConfirmation/AccountConfirmation';
import { useAppSelector } from 'hooks/reduxHooks';
import MyAccounts from 'jsx/containers/MyAccounts';
import EmployerDashboard from 'jsx/containers/EmployerDashboard';
import EmployerContributions from 'jsx/containers/EmployerDashboard';
import EmployerEmployees from 'jsx/containers/EmployerDashboard';
import EmployerInvestments from 'jsx/pages/EmployerDashboard/EmployerInvestments';
import AdminForm from 'jsx/pages/AdminForm';
import EquityAllocation from 'jsx/pages/EquityAllocation';
import EquityForm from 'jsx/pages/Equity-form';
import TradeExecution from 'jsx/pages/Trade/TradeExecution';
import TradeFiles from 'jsx/pages/Trade/TradeFiles';
import FileDetail from 'jsx/pages/Trade/FileDetail';
import AdminView from 'jsx/pages/adminViewRisk';
import Error404 from 'jsx/pages/Error404';
import RiskProfileView from 'jsx/components/RiskProfile/RiskProfileView';
import StockDetail from 'jsx/containers/StockDetailScreen';
import AdminDashboard from 'jsx/containers/AdminDashboard';
import './stylesicon.scss';
import EtfBasket from 'jsx/containers/EtfBasket';
import GeneralInvestment from 'jsx/components/GeneralInvestments/index';
import Goal from '../../Latest/Goal/index'
import Invest from '../../Latest/Goal/InvestCard'
import PayNow from 'Latest/PayNow';
import PayReceipt from 'Latest/PayReceipt';
import PayMobReceipt from 'Latest/PayReceipt/payMobReceipt';
import CashFund from 'Latest/CashFund';
import FreshUserScreen from 'Latest/DashboardScreen/FreshUserScreen/FreshUserScreen';
import MyProfile from 'Latest/MyProfileV1'
import Settings from 'Latest/Settings'
import Help from 'Latest/Help'
import tierKYC from 'Latest/2ndTierKyc'
import viewDetail from 'Latest/GoalDetail/indexV2'
import TermCondition2 from 'jsx/components/WizardFormModal/Term&Condition/TermCondition2';
import TransactionHistoryV2 from 'Latest/DashboardScreenV1/Tab/TransactionHistoryV2';
import PortfolioV2 from 'Latest/PortfolioV2';
import FinancialAdvice from 'Latest/FinancialAdvice';
import Retirement from 'Latest/Goal/Retirement';


export const ScopedDashboardRoutes = [
  {
    component: Dashboard,
    exact: true,
    url: links.home,
    scope: 'View:Dashboard'
  },
  {
    component: EmployerDashboard,
    exact: true,
    url: links.employerDashboard,
    scope: 'View:EmployerDashboard'
  },
  {
    component: AdminDashboard,
    exact: true,
    url: links.adminDashboard,
    scope: 'View:AdminDashboard'
  }
];

export const ScopedAppRoutes = [
  {
    component: Dashboard,
    exact: true,
    url: links.home,
    scope: 'View:Dashboard',
    sideBarActive: false
  },
  {
    component: PortfolioV2,
    exact: true,
    url: links.portfolio,
    scope: 'View:Portfolio',
    sideBarActive: false
  },
  {
    component: RiskProfileView,
    exact: true,
    url: links.riskProfile,
    scope: 'View:RiskProfile',
    icon: 'fa-solid fa fa-question',
    sideBarActive: false
  },
  {
    component: AdminForm,
    exact: true,
    url: links.adminForm,
    scope: 'View:AdminForm',
    sideBarActive: false
  },
  {
    component: AdminView,
    exact: true,
    url: links.adminView,
    scope: 'View:AdminView',
    sideBarActive: false
  },
  {
    component: EquityAllocation,
    exact: true,
    url: links.equityAllocation,
    scope: 'View:EquityAllocation',
    sideBarActive: false
  },
  {
    component: EquityForm,
    exact: true,
    url: links.EquityForm,
    scope: 'View:EquityForm',
    sideBarActive: false
  },
  {
    component: TradeExecution,
    exact: true,
    url: links.FileDetail,
    scope: 'View:FileDetail',
    sideBarActive: false
  },
  {
    component: TradeExecution,
    exact: true,
    url: links.TradeExecution,
    scope: 'View:TradeExecution',
    sideBarActive: false
  },
  {
    component: TradeFiles,
    exact: true,
    url: links.TradeFiles,
    scope: 'View:TradeFiles',
    sideBarActive: false
  },
  {
    component: Thematic,
    exact: true,
    url: links.thematic,
    scope: 'View:Thematic',
    sideBarActive: false
  },
  { component: Basket, exact: true, url: links.basket, scope: 'View:Basket', sideBarActive: false },
  {
    component: EtfBasket,
    exact: true,
    url: links.etfBasket,
    scope: 'View:Thematic',
    sideBarActive: false
  },
  {
    component: GoalsOverview,
    exact: true,
    url: links.overview,
    scope: 'View:GoalsOverview',
    sideBarActive: false
  },
  {
    component: CompanyProfile,
    exact: true,
    url: links.companyProfile,
    scope: 'View:CompanyProfile',
    sideBarActive: false
  },
  {
    component: FundsOverview,
    exact: true,
    url: links.fundsOverview,
    scope: 'View:FundsOverview',
    sideBarActive: false
  },
  {
    component: AccountSetupForm,
    exact: true,
    url: links.accountSetupForm,
    scope: 'View:AccountSetupForm',
    sideBarActive: false
  },
  {
    component: DocumentsUpload,
    exact: true,
    url: links.documentsUpload,
    scope: 'View:DocumentsUpload',
    sideBarActive: false
  },
  {
    component: EmployerDashboard,
    exact: true,
    url: links.employerDashboard,
    scope: 'View:EmployerDashboard',
    sideBarActive: false
  },
  {
    component: EmployerContributions,
    exact: true,
    url: links.employerContributions,
    scope: 'View:EmployerContributions',
    sideBarActive: false
  },
  {
    component: EmployerEmployees,
    exact: true,
    url: links.employerEmployees,
    scope: 'View:EmployerEmployees',
    sideBarActive: false
  },
  {
    component: EmployerInvestments,
    exact: true,
    url: links.investments,
    scope: 'View:EmployerInvestments',
    sideBarActive: false
  },
  {
    component: PaymentOptions,
    exact: true,
    url: links.paymentOptions,
    scope: 'View:PaymentOptions',
    sideBarActive: false
  },
  {
    component: LinkAccounts,
    exact: true,
    url: links.linkAccounts,
    scope: 'View:LinkAccounts',
    sideBarActive: false
  },
  {
    component: StockDetail,
    exact: true,
    url: links.StockDetail,
    scope: 'View:StockDetail',
    sideBarActive: false
  },
  {
    component: AdminDashboard,
    exact: true,
    url: links.adminDashboard,
    scope: 'View:AdminDashboard',
    sideBarActive: false
  },
  {
    component: GeneralInvestment,
    exact: true,
    url: links.GeneralInvestment,
    scope: 'View:StockDetail',
    sideBarActive: false
  },
  {
    component: Goal,
    exact: true,
    url: links.Goal,
    scope: 'View:StockDetail',
    sideBarActive: false
  },
  {
    component: Invest,
    exact: true,
    url: links.Invest,
    scope: 'View:StockDetail',
    sideBarActive: false
  },
  {
    component: PayNow,
    exact: true,
    url: links.PayNow,
    scope: 'View:StockDetail',
    sideBarActive: false
  },
  {
    component: TermCondition2,
    exact: true,
    url: links.TermsCondition,
    scope: 'View:StockDetail',
    sideBarActive: false
  },
  {
    component: PayReceipt,
    exact: true,
    url: links.PayConfirmation,
    scope: 'View:StockDetail',
    sideBarActive: false
  },
  {
    component: PayReceipt,
    exact: true,
    url: links.PayReceipt,
    scope: 'View:StockDetail',
    sideBarActive: false
  },
  {
    component: CashFund,
    exact: true,
    url: links.Cashfund,
    scope: 'View:StockDetail',
    sideBarActive: false
  },
  {
    component: FreshUserScreen,
    exact: true,
    url: links.newUser,
    scope: 'View:StockDetail',
    sideBarActive: false
  },
  {
    component: MyProfile,
    exact: true,
    url: links.myprofile,
    scope: 'View:StockDetail',
    sideBarActive: true
  },
  {
    component: Settings,
    exact: true,
    url: links.settings,
    scope: 'View:StockDetail',
    sideBarActive: true
  },
  {
    component: Help,
    exact: true,
    url: links.help,
    scope: 'View:StockDetail',
    sideBarActive: true
  },
  {
    component: Help,
    exact: true,
    url: links.support,
    scope: 'View:StockDetail',
    sideBarActive: true
  },
  {
    component: PayMobReceipt,
    exact: true,
    url: links.PayMobReceipt,
    scope: 'View:StockDetail',
    sideBarActive: true
  },
{
    component: tierKYC,
    exact: true,
    url: links.tierKyc,
    scope: 'View:StockDetail',
    sideBarActive:true
  },
  {
    component: viewDetail,
    exact: true,
    url: links.viewDetail,
    scope: 'View:StockDetail',
    sideBarActive:true
  },
  {
    component: TransactionHistoryV2,
    exact: true,
    url: links.transactions,
    scope: 'View:StockDetail',
    sideBarActive:true
  },
  {
    component: FinancialAdvice,
    exact: true,
    url: links.finance,
    scope: 'View:StockDetail',
    sideBarActive:true,
    isShow:true
  },
  {
    component: Retirement,
    exact: true,
    url: links.Retirement,
    scope: 'View:StockDetail',
    sideBarActive: false
  },

  
];

export const ScopeHeaderRoutes = [
  // { name: '', url: links.home, scope: 'View:Dashboard' },
  // { name: 'Portfolio', url: links.portfolio, scope: 'View:Portfolio' },
  {
    name: 'Dashboard',
    url: links.home,
    scope: 'View:Dashboard',
    icon: 'fa fa-th-large'
  },
  {
    name: 'Dashboard',
    url: links.adminDashboard,
    scope: 'View:AdminDashboard'
  },
  { name: 'Admin Form', url: links.adminForm, scope: 'View:AdminForm' },
  { name: 'Admin View', url: links.adminView, scope: 'View:AdminView' },
  {
    name: 'Equity Allocation',
    url: links.equityAllocation,
    scope: 'View:EquityAllocation'
  },
  { name: 'Equity Form', url: links.EquityForm, scope: 'View:EquityForm' },
  { name: 'File Upload', url: links.FileDetail, scope: 'View:FileDetail' },
  {
    name: 'Trade Execution',
    url: links.TradeExecution,
    scope: 'View:TradeExecution'
  },
  // { name: 'Trade Files', url: links.TradeFiles, scope: 'View:TradeFiles' },
  {
    name: 'Goals',
    url: links.overview,
    scope: 'View:GoalsOverview',
    icon: 'fa-solid fa fa-usd '
  },
  {
    name: 'Products',
    url: '',
    icon: 'fa fa-file-text',
    navigationData: [
      {
        name: 'Basket',
        url: links.thematic,
        icon: 'fa fa-bitbucket'
      },
      {
        name: 'ETF',
        url: links.etfBasket,
        icon: 'fa fa-pie-chart'
      }
    ]
  },
  {
    name: 'Risk Profile',
    url: links.riskProfile,
    scope: 'View:RiskProfile',
    icon: 'fa-solid fa fa-question-circle'
  },
  {
    name: 'Dashboard',
    url: links.employerDashboard,
    scope: 'View:EmployerDashboard'
  },
  {
    name: 'Employees',
    url: links.employerEmployees,
    scope: 'View:EmployerEmployees'
  },
  {
    name: 'Contribution',
    url: links.employerContributions,
    scope: 'View:EmployerContributions'
  },
  {
    name: 'Investments',
    url: links.investments,
    scope: 'View:EmployerInvestments'
  }
  // { name: 'Dashboard', url: links.adminDashboard, scope: 'View:AdminDashboard' }
];

export const ScopeDropdownRoutes = [
  { name: 'Risk Profile', url: links.riskProfile, scope: 'View:RiskProfile' }
];
