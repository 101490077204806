import { Box, Grid, Typography } from '@mui/material'
import { mainContainerV2 } from 'Latest/Style/Style'
import React from 'react'
import secFilledCheck from 'Latest/Images/svg/secondaryFillCheck.svg'
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import alertIcon from 'Latest/Images/svg/WarningDiamond.svg';
import BottomBtn from 'Latest/2ndTierKyc/bottomBtn';

const infoData = [
    { name: "Proof of identity", description: "Submit live photos of yourself and your CNIC" },
    { name: "Proof of income & tax residency ", description: "Submit income documents and declare your foreign tax residency" },
    { name: "Phone number ownership", description: "Let us know who owns your current phone number and their details" },
]

const Index = ({ colors, handleNext ,handlePrevious, mdScreenDown }: any) => {

    const handleSaveExit = () => {
        console.log("handleSaveExit")
    }

    const alertCardJsx = () => {
        return (
            <Box sx={{ minHeight: '48px', p: '12px', bgcolor: "#FEE9E8", gap: "4px", display: 'flex', alignItems: 'flex-start', mb: { xs: '20px', md: '24px' } }}>
                <img src={alertIcon} />
                <Typography variant='alertTitle' color={'#821B13'}>Incorrect information will result in approval delays. Please ensure the correctness of data.</Typography>
            </Box>
        )
    }
    return (
        <>
          {mdScreenDown && <Grid item xs={12} sx={{ mb: '16px' }} >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', pb: '16px', }}>
                    <Typography variant='h6' color={colors.grey[800]}>Upgrade account</Typography>
                </Box>
                <Box sx={{ borderBottom: "1px solid #EAECF0", mx: { xs: '-16px', sm: "-30px" } }} />
            </Grid>}
            
            <Grid item md={12} xs={12}>

                {alertCardJsx()}
                <Box sx={{ ...mainContainerV2, border: 'none', boxShadow: 'none', p: { md: 0, xs: 0 } }}>
                    <Grid item md={12} xs={12}>
                        <Typography sx={{typography: { xs: 'h5', md: 'h1' }, textAlign: { xs: 'left', md: "center" }, pb: "4px", color: "#1D2939!important" }}>Upgrade your account</Typography>
                        <Typography maxWidth={"636px"} variant='body1' color={"rgba(29, 41, 57, 0.70)"} lineHeight={"normal"} letterSpacing={"unset"} sx={{ textAlign: { xs: 'left', md: "center" } }}>
                            To upgrade your account we'll need some additional documents from you as per SECP regulations. Here's what we'll need:
                        </Typography>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <Box gap={"16px"} display={"flex"} flexDirection={"column"} maxWidth={"395px"}>
                            {infoData?.map((item, index) => {
                                return (
                                    item &&
                                    <Box key={index} display={"flex"} gap={"8px"}>
                                        <Box display="flex" alignItems="flex-start" sx={{ pt: "2px" }}>
                                            <img src={secFilledCheck} style={{ width: '20px', height: '20px' }} />
                                        </Box>
                                        <Box display={"flex"} flexDirection={"column"} flex={1}>
                                            <Typography variant='h5' color={"#422E84"} sx={{ lineHeight: "normal" }}>
                                                {item.name}
                                            </Typography>
                                            <Typography variant='body1' color={"rgba(29, 41, 57, 0.70)"} lineHeight={"normal"}>
                                                {item.description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Grid>

                    <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: "space-between" }} width={"100%"}>
                        <BottomBtn handlePrevious={handlePrevious} handleNext={handleNext} showBackBtn={false} />
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}

export default Index