import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { Box, Modal, Typography } from '@mui/material';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import { useAppLoading } from 'hooks/appLoading';
import MessageIcon from 'Latest/Images/messageIcon.png';
import OTPInput from 'jsx/pages/OtpInput';
import '../../../scss/login/_Login.scss';
import { authStyles, authStylesOTP } from 'Latest/Style/Style';
import { OtpModalStyle } from 'Latest/MyProfileV1/Style/style';
import { IdentityVerification, onChangeAccountItem } from 'redux/actions';
import { toast } from 'react-toastify';

const defaultTime = 30;

const BankOTPModal = ({ handleClose, handleVerify, loadingConfirmBtn, open, selectedItem }: any) => {
  const { userAccountDetails } = useAppSelector(state => state.auth);

  const [otp, setOtp] = useState('');

  // Countdown timer for resend otp
  const [timer, setTimer] = useState(defaultTime);

  useEffect(() => {
    if (timer === 0 || !open) return;
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [open, timer]);


  const dispatch = useAppDispatch();

  const handleCloseFunction = () => {
    handleClose();
    setOtp('')
  };

  const handleConfirm = () => {


    const data = {
      userId: userAccountDetails?.userId,
      spec: '4',
      otp,
    }

    const onSuccess = () => {
      handleVerify();
      return;
    }

    const onFail = (message: any, response: any) => {
      console.log(message, response.status, "OPOOP")
      toast.error('Invalid OTP', { theme: "colored" });
      return;
    }

    otp.length === 6 && dispatch(IdentityVerification(data, onSuccess, onFail, 'identityVerificationApiName'))

  };

  const ResendJsx = () => {
    const handleResend = () => {
      if (loadingConfirmBtn) return
      const body = {
        "fieldsValues": {
          "IsRoshanDigitalBankAccount": selectedItem?.isRDA?.toString(),
          "IBAN": selectedItem?.accountNumber,
        }
      }

      const onSuccess = () => {
        toast.success(`OTP sent to ${userAccountDetails?.email}`, { theme: "colored" });
        setTimer(defaultTime)
      }

      const onFail = (message: any, response: any) => {
        console.log("🚀 ~ onFail ~ message:", message, response.status)
        return;
      }

      dispatch(onChangeAccountItem(body, userAccountDetails?.userId, onSuccess, onFail))
    }

    return timer ? (
      <Typography
        variant="body2"
        color="#422E84 !important"
        sx={{
          display: 'inline',
          fontWeight: 700,
        }}
      >
        {"00:" + (timer < 10 ? `0${timer}` : timer.toString())}
      </Typography>
    ) : (
      <Typography
        variant="body2"
        color="#422E84 !important"
        sx={{
          display: 'inline',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={handleResend}
      >
        Resend
      </Typography>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseFunction}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{ ...OtpModalStyle.modalStyle, ...OtpModalStyle.customStyles }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src={MessageIcon} />
          </Box>

          <Box>
            <Typography
              variant='h5'
              color={'#101828'}
              textAlign={'center'}
              pb={'8px'}
            >
              Update default bank account - email verification
            </Typography>
            <Typography variant='body2' color={'#667085'} textAlign={'center'}>
              Enter the OTP we sent to <span style={{ fontWeight: 500 }}>{userAccountDetails?.email}</span>
            </Typography>
          </Box>

          <Box
            sx={{ ...authStylesOTP, ...authStyles.container, ...OtpModalStyle.otpStyle }}
          >
            <Box className={'otpForm'} >
              <Box className='Form_Secondary' id='otp-field' sx={{ display: 'flex', justifyContent: 'center' }}>
                <OTPInput
                  autoFocus
                  isNumberInput
                  length={6}
                  className='otpContainer'
                  inputClassName='otpInput'
                  onChangeOTP={setOtp}
                  disabled={false}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ marginTop: -2 }}>
            <Typography variant='body2' color={'#667085'} textAlign={'center'}>
              Didn’t get an OTP? <ResendJsx />
            </Typography>
          </Box>

          <ButtonRowV1
            handleBack={handleCloseFunction}
            handleNext={handleConfirm}
            loadingNextBtn={loadingConfirmBtn}
            btnTitleNext={'Verify'}
            btnTitleBack='Close'
            containerStyles={{ justifyContent: 'space-between' }}
            backBtnStyle={{ width: '100%' }}
            nextBtnStyle={{ width: '100%' }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default BankOTPModal;
