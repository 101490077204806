import { Box, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/reduxHooks';
import { verifyOldPhoneNumber, verifySmsOldNumberParams, verifyNewPhoneNumber, verifySmsNewNumberParams } from 'redux/actions';
import ValidOTPV2 from './ValidOTPV2';
import { toast } from 'react-toastify';
import ValidateOTP from 'jsx/pages/ValidateOTP';

const OTPPhoneNumber = ({ currentStep, handleCloseFunc, setCurrentStep }: any) => {
  const { userAccountDetails } = useAppSelector(state => state.auth);
  const dispatch = useDispatch();

  const [currentStepOTP, setCurrentStepOTP] = React.useState(0);
  const [otpError, setOtpError] = React.useState('');

  const oldPhoneNo = userAccountDetails?.contactNumber;
  const newPhoneNo = userAccountDetails?.kyc?.newPhoneNumber;

  const userId = userAccountDetails?.userId

  const verifyOTPOldNum = (data: verifySmsOldNumberParams) => {
    const body = { "token": data.token, "userId": data.userId }
    const onSuccess = (message: any, response: any) => {
      setCurrentStepOTP(1);
    }
    const onFail = (message: any, response: any) => {
      console.error('OTP verification failed:', message, response);
      toast.error('Please Enter Correct OTP', { theme: "colored" });
    }
    dispatch(verifyOldPhoneNumber(body, onSuccess, onFail))
  }

  const verifyOTPNewNum = (data: verifySmsOldNumberParams) => {
    const body = { "token": data.token, "userId": data.userId }
    const onSuccess = (message: any, response: any) => {
      setCurrentStep(currentStep + 1)
    }
    const onFail = (message: any, response: any) => {
      console.error('OTP verification failed:', message, response);
      toast.error('Please Enter Correct OTP', { theme: "colored" });
    }
    dispatch(verifyNewPhoneNumber(body, onSuccess, onFail))
  }

  const resendOTP = () => {
    console.log()
  }


  const steps = [
    <ValidateOTP
      key="old-number"
      loading={false}
      otpUserId={'otpUserId'}
      externalPhoneNo={oldPhoneNo}
      resendSmsCode={() => { resendOTP() }}
      userId={userId}
      verifySmsCode={verifyOTPOldNum}
      useContainerStyle={false}
      customContainerStyle={{ mt: 0, minWidth: { md: 'auto', xs: 'auto' } }}
      heading="Confirm Your Previous Number"
      customSubHead={
        <Typography variant='body2' color={"rgba(29, 41, 57, 0.70)"} >
          Enter OTP sent to {oldPhoneNo}
        </Typography>
      }
      resendContent2ndTier={true}
      handleCloseCustomFunc={handleCloseFunc}
      type="2ndTierKyc"
    />,
    <ValidateOTP
      key="new-number"
      loading={false}
      otpUserId={'otpUserId'}
      externalPhoneNo={newPhoneNo}
      resendSmsCode={() => { resendOTP() }}
      userId={userId}
      verifySmsCode={verifyOTPNewNum}
      useContainerStyle={false}
      customContainerStyle={{ mt: 0, minWidth: { md: 'auto', xs: 'auto' } }}
      heading="Confirm Your New Number"
      customSubHead={
        <Typography variant='body2' color={"rgba(29, 41, 57, 0.70)"} >
          Enter OTP sent to {newPhoneNo}
        </Typography>
      }
      resendContent2ndTier={true}
      handleCloseCustomFunc={handleCloseFunc}
      type="2ndTierKyc"
    />,
  ];

  return (
    <Box>
      {steps[currentStepOTP]}
      {otpError && <Typography color="error">{otpError}</Typography>}
    </Box>
  );
};

export default OTPPhoneNumber;
