import { RDABankCode } from '../constants';


export const IbanBankValidator = (iban: any,) => {
    const input: any = iban  // or undefined
    let extractedBank: any = '';

    if (typeof input === 'string') {
        extractedBank = input.substring(4, 8);
    }

    return extractedBank
};

export const IbanFormater = (iban: any) => {
    const spacedIban = iban?.replace(/(.{4})/g, '$1 ');
    return spacedIban;
};

export const checkIBANForRDA = (ibanNumber:any) => {
      const checkRda = !ibanNumber?.includes(RDABankCode)
      return checkRda;
  };