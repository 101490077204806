import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style'
import React from 'react'
import greenTick from 'Latest/Images/greenTick.png'
import warningIcon from 'Latest/Images/warningIcon.png'
import CustomSingleCheckBox from 'jsx/components/Shared/CustomSingleCheckBox';
import { useFormik } from 'formik'
import * as Yup from 'yup';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'hooks/reduxHooks'
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack'



const Index = ({ RedirectLink, goalId, nextStep, previousStep }: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { isGIAllowed, user } = useAppSelector(state => state.auth);

    const mainHead = smScreen ? "Why choose VPS" : 'Who Voluntary Pension Scheme is for?'
    const { location, push } = useHistory();
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);

    const handleNext = () => {
        if (RedirectLink == "dashboard") {
            nextStep(undefined, 1)
        } else {
            nextStep(undefined, 2)
        }
    }

    const formikTerm = useFormik({
        enableReinitialize: true,
        initialValues: { term2: false },
        onSubmit: (values: any) => {
            handleNext()
        },
        validationSchema: Yup.object({ term2: Yup.bool().oneOf([true], 'Required') })
    });

    const buttonHtml = () => {

        return (
            <ButtonRowV1 handleBack={previousStep} handleNext={() => formikTerm.submitForm()} btnTitleNext={"Continue"} />
        )
    }

    const redirectBtnHtml = () => {
        const isNewUser = dashboardDetail?.status == "New"
        const handleClick = () => {
            push(isNewUser ? 'goal' : 'invest')
        }
        return (
            <>
                {smScreen ?
                    <Box sx={{ pt: '12px', }}>
                        <LoadingBtnCustomBack handleOnClick={handleClick} btnTitle={'Invest in Mahaana Invest instead'} loading={false} />
                    </Box>
                    :
                    <Typography sx={{ color: '#432F87', textDecoration: 'underline', pt: '24px', fontWeight: 500, cursor: "pointer" }} onClick={handleClick}>Want to save for General Investment?</Typography>
                }
            </>

        )

    }

    return (
        <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ pt: { xs: "24px", md: "40px" } }}>
            <Grid container>
                <Grid item md={12} xs={12}>
                    <Box sx={{ ...mainContainerV2, alignItems: 'self-start', gap: 0 }}>


                        <Grid container sx={{ background: "#FFF", py: { xs: "16px", md: "16px" } }}>

                            <Grid item md={12} xs={12}   >
                                <Typography variant='h4' sx={{ pb: "24px", color: "#1D2939", textAlign: 'center' }}>{mainHead}</Typography>
                            </Grid>


                            <Box sx={{ display: 'flex', gap: '16px', flexDirection: { xs: "column", md: 'row' } }}>
                                <Grid item md={6} xs={12} sx={{ background: "#F8FAFB", p: '16px', borderRadius: '16px' }}  >
                                    <img src={greenTick} style={{ width: '48px', height: '48px', marginBottom: '20px' }} />
                                    <Typography variant='h6' pb="8px" color="#101828" fontWeight={{ md: 500 }} >A Smart Choice for Salaried Class & Tax Filers</Typography>
                                    <Typography variant='body2' color="#475467" >Due to it’s unique 20%+ income tax credit, VPS is highly beneficial and recommended for the <strong>salaried class</strong> & <strong>tax filers</strong>.</Typography>
                                </Grid>

                                <Grid item md={6} xs={12} sx={{ background: "#F8FAFB", p: '16px', borderRadius: '16px' }} >
                                    <img src={warningIcon} style={{ width: '48px', height: '48px', marginBottom: '20px' }} />
                                    <Typography variant='h6' pb="8px" color="#101828" fontWeight={{ md: 500 }} >Important Consideration for Non-Filers</Typography>
                                    <Typography variant='body2' color="#475467" >As average income tax rate is deducted on the withdrawal of VPS, <strong>non-filers</strong> can be at a disadvantage as they did not benefit from the income tax credit.</Typography>

                                    {isGIAllowed && redirectBtnHtml()}
                                </Grid>

                            </Box>

                        </Grid>

                        <Grid item md={12} xs={12} sx={{ px: "16px", py: "20px", background: '#F8FAFB', borderRadius: '16px' }} width={"100%"}>
                            <CustomSingleCheckBox
                                formik={formikTerm}
                                fieldName={`term2`}
                                label={`I have read the above information and understand how VPS impacts filers and non-filers`}
                                type={"checkbox"}
                                styleForm={{ paddingLeft: "0px" }}
                                styleErr={{ marginLeft: "0px" }}
                            />
                        </Grid>

                        <Grid item md={12} xs={12} sx={{ justifyContent: "flex-end", pt: "24px" }} width={"100%"}>
                            {!smScreen && buttonHtml()}
                        </Grid>

                    </Box>

                    {smScreen && <Box sx={smallScreenBtnWrap}>
                        {buttonHtml()}
                    </Box>}
                </Grid>
            </Grid>
        </Container>
    )
}

export default Index