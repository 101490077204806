import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import Loader from 'jsx/components/Shared/Loader';
import { containerMainBox } from 'Latest/Style/Main';
import React from 'react'
import ButtonsRow from '../ButtonsRowLatest';
import NationalIdentityImageLatest from './NationalIdentityImageLatest';
import PermanentField from "./permanentField"
import { CASH_FUND_ID } from '../../../../constants';
import { ToastContainer } from 'react-toastify';
import { isSizeGreaterThan25KB } from 'utils/calculation';
import { mainContainerV2 } from 'Latest/Style/Style';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';

const Index = ({ accountSetupActiveStep, backNICFile, completeAccount,
    formik,
    frontNICFile,
    goalId,
    imageError,
    isLoadingCNIC,
    loading,
    previousStep,
    setImageError,
    setUserNICBack,
    setUserNICFront,
}: any) => {
    const IS_CASH_ACC = goalId == CASH_FUND_ID
    const IS_GI_ACC = goalId < CASH_FUND_ID
    const userNICFront = formik.values.UserNICFront; // Replace with the actual file data for UserNICFront
    const userNICBack = formik.values.UserNICBack;
    const shouldUpdateUser = isSizeGreaterThan25KB(userNICFront, userNICBack);
    const loaderText = shouldUpdateUser ? ["We’re extracting your CNIC information from the images.", "This may take up to 30 seconds."] : ["Please be patient with us."]

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePressGoBack = (e: any) => {
        e.preventDefault();
        previousStep()
    }

    const handleNextBtn = (e: any) => {
        e.preventDefault();
        formik.submitForm();
    }

    const buttonHtml = () => {
        return (
            <ButtonRowV1
                handleBack={handlePressGoBack} handleNext={handleNextBtn}
                btnTitleNext="Continue" loadingNextBtn={loading}
                disableNext={
                    (formik.errors.UserNICFront && true) ||
                    (formik.errors.UserNICBack && true) ||
                    formik.values.UserNICFront == '' ||
                    formik.values.UserNICBack == ''
                }
            />
        )
    }

    return (
        // <Container maxWidth={accountSetupActiveStep == completeAccount.Cnic ? 'lg' : "md"} sx={{ my: 2 }} >
        //     <ToastContainer />
        //     <Box sx={containerMainBox}>
        //         <Grid container spacing={2} >
        //             <Grid item md={12} xs={12} sx={{ p: "1rem", pb: "2rem" }} >

        //                 {(() => {
        //                     if (accountSetupActiveStep == completeAccount.Cnic) {
        //                         return (
        //                             <>
        //                                 {isLoadingCNIC && <Loader
        //                                     multilineTitle={loaderText}
        //                                 />}
        //                                 <NationalIdentityImageLatest
        //                                     backNICFile={backNICFile}
        //                                     formik={formik}
        //                                     frontNICFile={frontNICFile}
        //                                     imageError={imageError}
        //                                     setImageError={setImageError}
        //                                     setUserNICBack={setUserNICBack}
        //                                     setUserNICFront={setUserNICFront}
        //                                 />
        //                             </>
        //                         );
        //                     }
        //                 })()}

        //                 <ButtonsRow
        //                     backButtonOnClick={(e: any) => {
        //                         e.preventDefault();
        //                         if (accountSetupActiveStep === completeAccount.Cnic) {
        //                             IS_CASH_ACC ? previousStep(undefined, 1) : previousStep();
        //                         }
        //                     }}
        //                     loading={loading}
        //                     nextButtonDisabled={
        //                         (formik.errors.UserNICFront && true) ||
        //                         (formik.errors.UserNICBack && true) ||
        //                         formik.values.UserNICFront == '' ||
        //                         formik.values.UserNICBack == ''
        //                     }
        //                     nextButtonOnClick={(e: any) => {
        //                         e.preventDefault();
        //                         formik.submitForm();
        //                     }}
        //                 />
        //             </Grid>
        //         </Grid>
        //     </Box>
        // </Container>

        <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ pt: { xs: "24px", md: "40px" }, pb: { xs: "24px", md: "100px", xl: "40px" } }}>
            <ToastContainer />

            <Grid container>
                <Grid item md={12} xs={12}>
                    <Box sx={{ ...mainContainerV2, gap: { xs: "16px", md: "40px" } }}>

                        {(() => {
                            if (accountSetupActiveStep == completeAccount.Cnic) {
                                return (
                                    <>
                                        {isLoadingCNIC && <Loader
                                            multilineTitle={loaderText}
                                        />}
                                        <NationalIdentityImageLatest
                                            backNICFile={backNICFile}
                                            formik={formik}
                                            frontNICFile={frontNICFile}
                                            imageError={imageError}
                                            setImageError={setImageError}
                                            setUserNICBack={setUserNICBack}
                                            setUserNICFront={setUserNICFront}
                                        />
                                    </>
                                );
                            }
                        })()}

                        {!smScreen && buttonHtml()}

                    </Box>

                    {smScreen && <Box sx={{ p: "8px 16px", mt: "16px", borderRadius: "8px", boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)", backgroundColor: "#fff" }}>
                        {buttonHtml()}
                    </Box>}
                </Grid>
            </Grid>

        </Container>
    )
}

export default Index