import { useAppLoading } from "hooks/appLoading";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Box, Collapse, Skeleton } from '@mui/material'
import { useState } from "react";
import ReuploadCnicWarningAlert from "../Alerts/reuploadCnicWarningAlert";
import { onPatchNotifications } from "redux/reducers/sharedStateData";
import UserPhoneEmailVerificationAlert from "../Alerts/userPhoneEmailVerificationAlert";
import { VerificationMethod } from "../../../constants";

const User_Phone_Email_Notification = () => {

    const loading = useAppLoading('auth');
    const dispatch = useAppDispatch();
    const [isCloseLocal, setIsCloseLocal] = useState(true);
    const { notficationHub } = useAppSelector(state => state.sharedData);
    const { isAuth, isUserVerified } = useAppSelector(state => state.auth);

    const checkNotificationPhoneNo = notficationHub?.PhoneNo?.toLowerCase() == "true"
    const checkNotificationEmail = notficationHub?.Email?.toLowerCase() == "true"

    return (
        <>
            {loading ?
                <SkeletonLoading />
                :
                <>
                    <Collapse in={isCloseLocal} timeout={{ enter: 500, exit: 500 }} >
                        {checkNotificationPhoneNo && <UserPhoneEmailVerificationAlert btnTitle={'Verify Phone Number'} spec={VerificationMethod.phoneNumber} />}
                    </Collapse>

                    <Collapse in={isCloseLocal} timeout={{ enter: 500, exit: 500 }} >
                        {checkNotificationEmail && <UserPhoneEmailVerificationAlert btnTitle={'Verify Email'} spec={VerificationMethod.email} />}
                    </Collapse>
                </>
            }
        </>
    )
}

export default User_Phone_Email_Notification;


const SkeletonLoading = () => {
    return (
        <Box sx={{ px: { xs: "16px", sm: "32px" }, pb: { xs: "16px", sm: "24px" } }}>
            <Skeleton variant="rounded" width={"100%"} height={20} />
        </Box>
    )
}