import { Box, Grid, Typography } from '@mui/material'
import BottomBtn from 'Latest/2ndTierKyc/bottomBtn'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import SelfieWebcamTierKyc from 'Latest/WebCam/SelfieWebcamTierKyc';
import { useAppLoading } from 'hooks/appLoading';
import { onGetAccountSetup, onGetKYCImg } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { convertStreamToBase64 } from 'utils/convertStreamToBase64';
import { useHistory } from 'react-router';

const Index = ({ colors, formik, handleAPI, handleNext, handlePrevious, mdScreenDown }: any) => {
    const { push } = useHistory();
    const loadingonPostSecondTier = useAppLoading('onPostSecondTier');
    const loadingonGetKYCImg = useAppLoading('onGetKYCImg');

    const loading = loadingonPostSecondTier || loadingonGetKYCImg

    const dispatch = useAppDispatch();

    const { user, userAccountDetails } = useAppSelector(state => state.auth);

    useEffect(() => {
        if (userAccountDetails?.kyc?.kycDocument?.selfie?.name) {
            handleDownloadNicImg()
        }
    }, [userAccountDetails?.kyc])

    const handleDownloadNicImg = () => {

        const body = {
            userId: user?.id,
            documentName: userAccountDetails?.kyc?.kycDocument?.selfie?.name
        }

        const onSuccess = async (message: string, response: any) => {
            const stream: any = message
            const blob = new Blob([stream]);

            if (blob) {
                convertStreamToBase64(blob).then((base64String: any) => {
                    formik?.setFieldValue("selfie", base64String);
                    formik?.setFieldValue("hash", userAccountDetails?.kyc?.kycDocument?.selfie?.hash)
                })
                    .catch((error) => {
                        console.error('Error converting blob to base64:', error);
                        formik?.setFieldValue("selfie", "");
                        formik?.setFieldValue("hash", "");
                    });
            } else {
                console.error('No valid blob or file provided.');
                formik?.setFieldValue("selfie", "");
                formik?.setFieldValue("hash", "");
            }

        }

        dispatch(onGetKYCImg(body, onSuccess))
    }

    const handleSaveExit = async () => {
        const errors = await formik.validateForm();
        if (Object.keys(errors).length === 0) {
            // No validation errors, proceed with your save/exit logic
            handleAPI(true)
        } else {
            // There are validation errors, you can handle them or show a message
            push("myprofile?scroll=true")
        }

    }

    const handlNextBtn = () => {
        if (userAccountDetails?.kyc?.kycDocument?.selfie?.hash.toUpperCase() === formik?.values?.hash?.toUpperCase()) {
            handleNext()
        } else {
            formik.handleSubmit()
        }
    }

    return (
        <>
            {mdScreenDown && <Grid item xs={12} sx={{ mb: '16px' }} >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', pb: '16px', }}>
                    <Typography variant='h6' color={colors.grey[800]}>Upgrade account</Typography>
                    <Typography variant='h6' sx={{ color: "#443087", cursor: 'pointer' }} onClick={handleSaveExit}>Save & Exit</Typography>
                </Box>
                <Box sx={{ borderBottom: "1px solid #EAECF0", mx: { xs: '-16px', sm: "-30px" } }} />
            </Grid>}

            <Grid item md={12} sx={{ mb: "32px" }}>
                <Typography sx={{ typography: { xs: 'h5', md: 'h1' }, color: "#202020!important", }}>Take a picture of yourself </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
                <SelfieWebcamTierKyc formik={formik} fieldName={"selfie"} loading={loading} />
            </Grid>

            <BottomBtn handlePrevious={handlePrevious} handleSaveExit={handleSaveExit} handleNext={handlNextBtn} loading={loading} showBackBtn={true}
                showBtnSaveExist={!mdScreenDown}
                styleBoxBtn={{ width: '100%', justifyContent: mdScreenDown ? "space-between" : "flex-end", }} />
        </>
    )

}

export default Index