import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import maintanence from 'images/svg/maintanence.svg';

const Maintenance = () => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '100vh', backgroundColor: '#f5f5ff' }}>
      <Grid item>
        <Box component="img" src={maintanence} alt="Maintenance" sx={{ width: '100%', mb: 4 }} />
      </Grid>
      <Grid item>
        <Typography
          gutterBottom
          variant="h4"
          sx={{
            color: '#4a4a8a',
            fontSize: { xs: '1rem', sm: '2rem' },        
          }}
        >
          Hang on! Scheduled maintenance is on-going
        </Typography>

      </Grid>
      <Grid item>
        <Typography gutterBottom variant="h6" sx={{ color: '#667085', mb: 3 ,fontSize: { xs: '0.8rem', sm: '1.2rem' }}}>
          We're improving our services. We'll be back soon.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          href="https://www.mahaana.com"
          sx={{
            backgroundColor: '#432F87',
            color: 'white',
            '&:hover': {
              backgroundColor: '#432F87',
              color: 'white'
            },
            borderRadius: '25px',
            height: {lg:'50px',md:'50px',xs:'30px'}
          }}
        >
          Back to website
        </Button>
      </Grid>
    </Grid>
  );
};

export default Maintenance;