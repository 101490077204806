import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import WizardFormBody from 'jsx/components/WizardFormModal/WizardFormBodyLatest'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { onGetInvestGoalConfig, onGetInvestPortfolioAllocation } from 'redux/reducers/sharedStateData';
import { links } from 'static/links';

const Index = () => {

  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const { isVPSAllowed } = useAppSelector(state => state.auth);

  const handleGoalBack = () => {
    push(links.home)
  }

  useEffect(() => {
    dispatch(onGetInvestGoalConfig(true))
    dispatch(onGetInvestPortfolioAllocation(true))
  }, [])

  useEffect(() => {
    if (!isVPSAllowed) {
      push("/");
      toast.error(`Your are not allowed to access Mahaana Retirement. Contact your admin.`);
      return;
    }
  }, [isVPSAllowed])


  return (
    <WizardFormBody
      goalId={300}
      goalValue={"Retirement"}
      goalName={"Mahaana Retirement"}
      isThisGoalCreated={true}
      loading={false}
      proceedButtonHandler={() => push(links.accountSetupForm)}
      handleGoalBack={handleGoalBack}
    />
  )
}

export default Index