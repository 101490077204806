import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Loader from 'jsx/components/Shared/Loader';
import { containerMainBox } from 'Latest/Style/Main';
import React, { useEffect, useState } from 'react'
import ButtonsRow from '../ButtonsRowLatest';
import NationalIdentityImageLatest from './NationalIdentityImageLatest';
import PermanentField from "./permanentFieldV2"
import { CASH_FUND_ID, RETIREMENT_ID } from '../../../../constants';
import { onGetAccountSetup } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style';
import { useAppLoading } from 'hooks/appLoading';

const Index = ({
    checkAddress,
    checkMotherName,
    checkNic,
    formik,
    goalId,
    loading,
    nextStep,
    previousStep,
}: any) => {
    const IS_CASH_ACC = goalId == CASH_FUND_ID
    const IS_GI_ACC = goalId < CASH_FUND_ID
    const IS_Retirement_ACC = goalId == RETIREMENT_ID

    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(false)
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const resLoading = useAppLoading('questionAnswer');

    useEffect(() => {
        if (userAccountDetails?.mailingAddress == null) {
            formik.setFieldValue("sameAsParmanent", true)
        }
    }, [])

    const goBack = () => {

        if (IS_GI_ACC) {
            if (userAccountDetails?.isAccountSetupComplete && !checkNic) {
                previousStep(undefined, 2);
                return;
            } else {
                previousStep(undefined, 3);
                return;
            }
        } else if (IS_Retirement_ACC) {
            if (userAccountDetails?.isAccountSetupComplete && !checkNic) {
                previousStep(undefined, 2);
                return;
            } else {
                previousStep(undefined, 3);
                return;
            }
        } else if (IS_CASH_ACC) {
            if ((userAccountDetails?.isAccountSetupComplete && !userAccountDetails?.permanentAddress) || (userAccountDetails?.permanentAddress && !checkAddress)) {
                IS_CASH_ACC ? previousStep(undefined, 3) : previousStep(undefined, 3);
                return;
            } else if (userAccountDetails?.isAccountSetupComplete && checkNic) {
                previousStep(undefined, 3);
                return;
            } else {
                previousStep();
                return;
            }
        }


    }

    const goNext = (e: any) => {
        e.preventDefault();
        formik.submitForm();
    }

    const buttonHtml = () => {
        return (
            <ButtonRowV1
                handleNext={goNext} handleBack={goBack}
                btnTitleNext={"Continue"} loadingNextBtn={loading || resLoading}
                disableNext={
                    formik.values.permanentAddress1 == '' ||
                    formik.values.cityPermanent.value == '' ||
                    formik.values.provincePermanent.value == '' ||
                    formik.values.mailingAddress1 == '' ||
                    formik.values.cityMailing == null ||
                    formik.values.provinceMailing == null
                }
            />
        )
    }

    return (

        <>
            <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ pt: { xs: "24px", md: "40px" } }}>
                <Grid container>

                    <Grid item md={12} xs={12} sx={mainContainerV2}>

                        <Grid item md={12} xs={12}>
                            <Typography variant='h4' color={"#1D2939"} lineHeight={"normal"} textAlign={"center"}>Address details</Typography>
                        </Grid>

                        <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"40px"}>

                            <Grid item md={12} xs={12}>
                                {isLoading && <Loader />}
                                <PermanentField formik={formik} />
                            </Grid>

                            {!smScreen && buttonHtml()}
                        </Box>

                    </Grid>


                    {smScreen && <Box sx={{ ...smallScreenBtnWrap, marginBottom: '36px'}}>
                        {buttonHtml()}
                    </Box>}


                </Grid>
            </Container>
            {/* <Container maxWidth={'md'} disableGutters={smScreen}>

                <Typography variant={"h5"} sx={{ p: { xs: '16px', md: "40px 0px 16px 0px" }, background: { xs: "#f9fafb", md: "transparent" } }} >Address Details
                </Typography>
                <Grid container sx={{ borderRadius: "12px", border: { xs: "none", md: "1px solid #EAECF0" }, background: "#FFF", p: { xs: "16px", md: "24px" } }}>
                    <Grid item md={12} xs={12} >
                        {isLoading && <Loader />}
                        <PermanentField formik={formik} />
                    </Grid>

                    <Grid item md={12} xs={12} sx={{ pt: "24px" }}>
                        <ButtonRowV1 btnTitleNext={"Next"} handleNext={() => formik.submitForm()}
                            loadingNextBtn={loading} handleBack={goBack}
                            disableNext={
                                formik.values.permanentAddress1 == '' ||
                                formik.values.cityPermanent.value == '' ||
                                formik.values.provincePermanent.value == '' ||
                                formik.values.mailingAddress1 == '' ||
                                formik.values.cityMailing == null ||
                                formik.values.provinceMailing == null
                            } />
                    </Grid>

                </Grid>

            </Container> */}

        </>
    )
}

export default Index