import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import warningIcon from "Latest/Images/warningIcon.png"
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';
import CustomSingleCheckBox from 'jsx/components/Shared/CustomSingleCheckBox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UpdatePhoneNumber from 'Latest/2ndTierKyc/steps/Declaration/modalComp/updatePhoneNumber';
import OTPPhoneNumber from 'Latest/2ndTierKyc/steps/Declaration/modalComp/OTPPhoneNumber';
import UpdateInfo from 'Latest/2ndTierKyc/steps/Declaration/modalComp/UpdateInfo';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: "24px",
  borderRadius: "12px",
  width: { xs: "95%", sm: "auto", md: "500px" },
};

export default function RequestPhoneNumberModal({ handleClose, open }: any) {

  const [currentStep, setCurrentStep] = React.useState(0);

  const handleCloseFunc = () => {
    handleClose()
    setCurrentStep(0)
  }
  const steps = [
    <UpdatePhoneNumber key={0} setCurrentStep={setCurrentStep} currentStep={currentStep} handleCloseFunc={handleCloseFunc} />,
    <OTPPhoneNumber key={1} setCurrentStep={setCurrentStep} currentStep={currentStep} handleCloseFunc={handleCloseFunc} />,
    <UpdateInfo key={2} setCurrentStep={setCurrentStep} currentStep={currentStep} handleCloseFunc={handleCloseFunc} />,
  ];

  
  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseFunc}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {steps[currentStep]}
        </Box>
      </Modal>
    </div>
  );
}