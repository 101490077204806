import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import warningIcon from "Latest/Images/svg/warningIcon1.svg"
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';
import { CurrencyFormatterWithDecimal } from 'utils/calculation';
import { intialInvestmentMaxLimit } from '../../constants';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: { xs: "16px", md: "24px" },
    borderRadius: "12px",
    width: { xs: "95%", sm: "auto", md: "500px" },
};

export default function flowAmtLimitModal({ content, handleClose, handleModalSubmitBtn, open }: any) {


    const handleBtn = () => {
        handleModalSubmitBtn()
    }
    const maxLimit = CurrencyFormatterWithDecimal(intialInvestmentMaxLimit, true, "never")

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", alignItems: "flex-start", gap: "16px" }}>
                        <img src={warningIcon} />

                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                                <Typography variant="h6" >Account limit reached</Typography>
                                <Typography variant="body2" >You can only invest up to {maxLimit}. Please decrease your initial investment amount to proceed.</Typography>
                            </Box>
                            <Box>
                                <IconButton aria-label="close" component="label" onClick={handleClose} sx={{ p: "0px" }} >
                                    <CloseIcon sx={{ fontSize: 18, cursor: "pointer" }} onClick={handleClose} />
                                </IconButton>
                            </Box>

                        </Box>
                    </Box>


                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", width: "100%", pt: "30px" }}>
                        <Button variant='contained' color={"secondary"} onClick={handleBtn}>ok</Button>
                    </Box>

                </Box>
            </Modal>
        </>
    );
}