import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import uploadIconImg from "Latest/Images/svg/uploadIconImg.svg"
import { ToastContainer, toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import pdfIcon from "Latest/Images/svg/pdfIcon.svg"
import { proofOfIncome } from 'Latest/2ndTierKyc/style';
import { Formik } from 'formik';
import { cryptoGraphy } from 'utils/cryptoGraphyFile';
import { onDeleteKYCDoc } from 'redux/actions';
import { useAppDispatch } from 'hooks/reduxHooks';

const UploadFileCard = ({ fieldName, fileCountLimit = 5, formik, loading = false, multipleFile = true, uploadBtnTitle = 'Upload Files', uploadedFile = [] }: any) => {

    // const fileCountLimit = 5;

    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef: any = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState<any>([])
    const dispatch = useAppDispatch();

    useEffect(() => {
        setSelectedFiles(uploadedFile)
        formik.setFieldValue(fieldName, uploadedFile);
    }, [])

    const handleDragEnter = (e: any) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e: any) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        handleFiles([...files]);
    };

    const handleFiles = async (files: any) => {
        // Handle the files here, limit to a maximum of 5 files
        if (files.length > fileCountLimit || selectedFiles.length + files.length > fileCountLimit) {
            return toast.error(`You can only upload ${fileCountLimit} files`, { theme: 'colored' });
        }

        // Create an array to store promises for hashing
        const hashingPromises = files.map((i: any) => cryptoGraphy(i));

        // Wait for all promises to resolve
        const hashedFiles = await Promise.all(hashingPromises);

        const checkDuplicateFile = hashedFiles.some(valueHashedFiles => selectedFiles.some((objSelectedFiles: any) => objSelectedFiles.hash.toUpperCase() === valueHashedFiles.toUpperCase()));

        if (checkDuplicateFile) {
            return toast.error('File is already exists', { theme: 'colored' });
        }

        // Merge the new files with the previous ones
        setSelectedFiles((prevSelectedFiles: any) => {
            const newSelectedFiles: any = files.map((i: any, index: number) => ({
                file: i, documentName: i?.name, hash: hashedFiles[index]
            }));
            const file = [...prevSelectedFiles, ...newSelectedFiles];

            if (formik) {
                formik.setFieldValue(fieldName, file);
            }

            return file;
        });
    };

    const handleFileInput = (e: any) => {
        const files = e.target.files;
        const filesArray = [...files];
        handleFiles(filesArray);
    };

    const handleBoxClick = () => {
        fileInputRef.current.click();
    };

    const handleRemove = (index: number, name: string) => {
        setSelectedFiles((prevSelectedFiles: any) => {
            const newSelectedFiles = [...prevSelectedFiles];
            newSelectedFiles.splice(index, 1);
            formik?.setFieldValue(fieldName, newSelectedFiles);
            return newSelectedFiles;
        });

        const formData = new FormData();
        formData.append('DocumentsNames', name);

        dispatch(onDeleteKYCDoc(formData))

    }

    return (
        <>
            <ToastContainer />
            <input
                ref={fileInputRef}
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileInput}
                accept=".png, .jpg, .jpeg, .pdf"
            />

            {formik.values[fieldName]?.length > 0 ? (
                <>
                    <Box sx={proofOfIncome.selectedFileMainBox}>
                        {formik.values[fieldName].map((item: any, index: number) => (
                            <React.Fragment key={index}>
                                <Box sx={proofOfIncome.selectedFileMapMainBox}>
                                    <Box sx={{ display: "flex", gap: "16px" }}>
                                        <img src={pdfIcon} alt="File Icon" />
                                        <Typography sx={{ wordBreak: "break-all" }}>{item.documentName}</Typography>
                                    </Box>
                                    <LoadingButton
                                        loading={loading}
                                        color='secondary'
                                        variant='outlined'
                                        sx={{ ...proofOfIncome.uploadBtn, minWidth: 90 }}
                                        onClick={() => handleRemove(index, item.name)}
                                    >
                                        Remove
                                    </LoadingButton>
                                </Box>

                                {formik.values[fieldName].length !== index + 1 && (
                                    <Divider sx={{ borderColor: "#EAECF0", mb: "12px" }} />
                                )}
                            </React.Fragment>
                        ))}

                        {multipleFile && <LoadingButton fullWidth size="large" color='secondary' onClick={handleBoxClick} variant='contained' loading={loading}>
                            {uploadBtnTitle}
                        </LoadingButton>}
                    </Box>
                </>
            ) : (
                <Box
                    sx={{ ...proofOfIncome.uploadBox, border: isDragging ? '2px solid #6941C6' : '2px dashed #E2E6EA', p: "8px 0" }}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={handleBoxClick}
                >
                    <img src={uploadIconImg} alt="Upload Icon" height={40} />
                    <Box>
                        <Typography textAlign="center">
                            <strong style={{ color: '#6941C6' }}>Click to upload </strong> or drag and drop
                        </Typography>
                        <Typography sx={{ fontSize: '12px!important' }} textAlign="center">
                            {'PNG, JPG, PDF (max file size 10 MB)'}
                        </Typography>
                    </Box>
                </Box>
            )}

            {formik?.touched[fieldName] && <span className='text-danger'>{formik?.errors[fieldName]}</span>}
        </>

    );
};

export default UploadFileCard;