import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Grid, Typography } from '@mui/material';
import successIcon from "../../../Latest/Images/success.png"
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import { ModalStyle } from '../Style/modalStyle';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    boxShadow: "0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)",
    p: "24px",
    borderRadius: "8px",
    maxWidth: '100%',
    width: { xs: "95%", sm: "auto", md: "400px" },
    '& img': { height: 'auto', width: 'auto', maxWidth: '100%' }
};



export default function BankSuccessModal({ handleClose, open }: any) {

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", alignItems: 'center' }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <img src={successIcon} />
                        </Box>

                        <Box width={"80%"} sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                            <Typography sx={{ ...ModalStyle.headStyle, textAlign: 'center' }}>Default bank account update request submitted</Typography>
                            <Typography sx={{ ...ModalStyle.subheadStyle, textAlign: 'center' }}>Update requests will take 5 business days to verify.</Typography>
                        </Box>
                    </Box>


                    <Grid item xs={12} sx={{ display: "flex", pt: 3 }}>
                        <ButtonRowV1 handleBack={handleClose} btnTitleBack="Close" checkFreezAcc={true} isShowNext={false} backBtnStyle={{ width: '100%', backgroundColor: "#403081 !important", color: '#fff' }} />
                    </Grid>

                </Box>
            </Modal>
        </div>
    );
}