import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import NoData from 'Latest/Illustration/noData'
import React, { useEffect, useState } from 'react'
import CustomPieChart from 'Latest/ChartV2/customPieChart';
import { customCard } from '../style'
import { goalCategory, pieColors, savePieColorsHsl } from '../../../constants';
import CustomScrollerCard from 'Latest/ComponentV1/customScrollerCard';
import { onGetCFPortfolioSummmary, onGetGIPortfolioSummmary } from 'redux/reducers/sharedStateData';
import { useAppDispatch } from 'hooks/reduxHooks';
import Big from 'big.js';

const PortfolioCard = ({ goalInfo }: any) => {

    const [porfolioApiData, setPorfolioApiData] = useState([])
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [activeIndex, setActiveIndex] = useState(-1)

    
    const isCfAcc = goalInfo.category.toLowerCase() == goalCategory.CashFund.toLowerCase()
    const isRetirementAcc = goalInfo.category.toLowerCase() == goalCategory.Retirement.toLowerCase()
    const isGIAcc = goalInfo.category.toLowerCase() == goalCategory.GeneralInvest.toLowerCase()

    const handlePorfolioCFSummaryApi = () => {

        const goalInfoData = {
            id: goalInfo.idGoal,
            amt: goalInfo.potDetails.portfolioAmount
        }

        const onSuccess = (message: any, response: any) => {
            console.log('>>>>>message', message)
            handlePortfolioData(message)

        }
        const onFail = (message: any, response: any) => {
            console.log(message, response.status, "OPOOP")
            setPorfolioApiData([])
        }
        if (isCfAcc) {
            dispatch(onGetCFPortfolioSummmary(goalInfoData, onSuccess, onFail))
        } else if (isRetirementAcc) {
            dispatch(onGetGIPortfolioSummmary(goalInfoData, onSuccess, onFail))
        } else {
            dispatch(onGetGIPortfolioSummmary(goalInfoData, onSuccess, onFail))
        }
    }

    useEffect(() => {
        handlePorfolioCFSummaryApi()
    }, [])

    const chartValuesCalculation = (dataValues: any, eachVal: number) => {
        if (dataValues) {
            let sum = 0;

            for (let i = 0; i < dataValues.length; i++) {
                sum += dataValues[i]?.percentage;
            }
            if (sum > 0) {
                const countVal = 100 - sum;
                const ratio = eachVal / sum;
                const additionalUnits = ratio * countVal;
                const finalVal = eachVal + additionalUnits;
                return Number(new Big(finalVal).round(2));
            }

        } else {
            return
        }
    }

    const handlePortfolioData = (data: any) => {

        const goalcategoryParam = goalInfo?.category

        if (data?.length > 0) {

            const isGICategory = goalcategoryParam == goalCategory.GeneralInvest

            const allItemSummaries = data?.flatMap((obj: any) => obj?.itemSummaries) || [];

            const allItemSummariesMergedData = isGICategory && Object.values(allItemSummaries.reduce((acc: any, obj: any) => {
                acc[obj?.name] = acc[obj?.name] || { ...obj, percentage: 0, amount: 0 };
                acc[obj?.name].amount = acc[obj?.name] ? obj.amount : acc[obj?.name].amount + obj.amount;
                acc[obj?.name].percentage = acc[obj?.name] ? obj.percentage : acc[obj?.name].percentage + obj.percentage;
                return acc;
            }, {}));

            const modifyData = isGICategory ? allItemSummariesMergedData : data

            const updatePorfolio = modifyData.map((item: any, index: any) => (
                {
                    'name': item?.assetName || item?.name,
                    // 'value': Number(new Big(item?.percentage).toFixed(2, 0)),
                    'value': chartValuesCalculation(modifyData, item?.percentage),
                    // 'actualVal': Number(new Big(item?.percentage).toFixed(2, 0)),
                    // "amount": new Big(goalInfo.potDetails.portfolioAmount || 0).mul(item?.percentage || 0).div(100).toFixed(2, 0)
                    "amount": null
                }
            )
            ).filter((item: any) => item.value > 0);

            let Pie_sorted_Arr;
            if (updatePorfolio) {
                Pie_sorted_Arr = updatePorfolio.slice().sort((a: any, b: any) => b.value - a.value);
            }

            setPorfolioApiData(Pie_sorted_Arr)
        }
    }


    return (
        <>
            <Box sx={customCard.mainBoxV2}>
                <Box sx={{ ...customCard.headBoxV2, borderBottom: "1px solid #D0D5DD40" }}>
                    <Typography variant='h1' color={"secondary"} lineHeight={"36px"}>Portfolio</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, height: { lg: "456px" }, alignItems: "center" }}>
                    {porfolioApiData.length > 0 ?
                        <>
                            <Box sx={{ width: { xs: "100%", sm: "60%" }, px: "20px", py: "20px" }}>
                                <CustomPieChart
                                    colors={savePieColorsHsl} data={porfolioApiData}
                                    showCustomActiveShape={false}
                                    customTooltipTrue={false}
                                    hoverToolTip={false} customHeight={smScreen ? 280 : xsScreen ? 210 : 380}
                                    outerRadiusPie={"100%"} innerRadiusPie={"83%"}
                                    hoverDotVal={(hoverDotVal: any) => { setActiveIndex(hoverDotVal) }}
                                />
                            </Box>

                            <CustomScrollerCard data={porfolioApiData} colors={savePieColorsHsl} styles={{ height: { xs: "auto", sm: "456px" } }} />
                        </>
                        :
                        <NoData />
                    }

                </Box>


            </Box>

        </>
    )
}

export default PortfolioCard