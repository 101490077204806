import Big from "big.js";

export const HandleFormulaForCalcTaxSaving = (retirementAgeValue: number, monthlySavingValue: number, annualTaxableIncome: number ,initValue:any) => {

    let taxSaving = new Big(0);
    const initAmt =  new Big(Number(initValue) || 0 )
    const userAnnualTaxableIncome = new Big(annualTaxableIncome || 0);
    const monthlyContribution = new Big(monthlySavingValue || 0);
    const retirementAgeInYear = retirementAgeValue * 12
    const numberOfMonth = new Big(retirementAgeInYear || 0);
    const sumWholeContribution = monthlyContribution.times(numberOfMonth).plus(initAmt);
    const slabs = [
        { minThreshold: 600000, maxThreshold: 1200000, baseTaxAmount: 0, taxRate: 2.5 },
        { minThreshold: 1200000, maxThreshold: 2400000, baseTaxAmount: 15000, taxRate: 12.5 },
        { minThreshold: 2400000, maxThreshold: 3600000, baseTaxAmount: 165000, taxRate: 20 },
        { minThreshold: 3600000, maxThreshold: 6000000, baseTaxAmount: 405000, taxRate: 25 },
        { minThreshold: 6000000, maxThreshold: 12000000, baseTaxAmount: 1005000, taxRate: 32.5 },
        { minThreshold: 12000000, maxThreshold: Number.MAX_SAFE_INTEGER, baseTaxAmount: 2995000, taxRate: 35 },
    ];

    const matchingSlab = slabs?.find(slab => Number(userAnnualTaxableIncome) >= Number(slab.minThreshold) && Number(userAnnualTaxableIncome) < Number(slab.maxThreshold));

    if (matchingSlab) {
        // eslint-disable-next-line sort-destructure-keys/sort-destructure-keys
        const { minThreshold, baseTaxAmount, taxRate } = matchingSlab;

        // Calculate the tax liability
        const taxLiability = new Big(baseTaxAmount).plus(
            userAnnualTaxableIncome.minus(minThreshold).times(taxRate).div(100)
        );

        // Calculate the percentage of income contributed
        let percentageIncomeContributed = sumWholeContribution.div(userAnnualTaxableIncome);

        // Cap the percentage at 20% if it exceeds that value
        if (Number(percentageIncomeContributed) > 0.2) {
            percentageIncomeContributed = new Big(0.2);
        }

        taxSaving = percentageIncomeContributed.times(taxLiability).times(retirementAgeValue)

    } else {
        taxSaving = new Big(0)
        console.error("No matching tax slab found.");
    }

    return taxSaving

};