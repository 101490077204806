//To use stagging environment use this
// import constants from "./release.json";

// //To use dev environment use this
// import constants from "./dev.json";

export const USER_MANAGER_BASE_URL = window.env.USER_MANAGER_BASE_URL;
export const ACCOUNT_MANAGEMENT_BASE_URL = window.env.ACCOUNT_MANAGEMENT_BASE_URL;
export const RISK_PROFILE_BASE_URL = window.env.RISK_PROFILE_BASE_URL;
export const PORTFOLIO_BASE_URL = window.env.PORTFOLIO_BASE_URL;
export const STOCK_DETAIL_BASE_URL = window.env.STOCK_DETAIL_BASE_URL;
export const EXCEL_UPLOAD_BASE_URL = window.env.EXCEL_UPLOAD;
export const WEALTH_API_BASE_URL = window.env.WEALTH_API_BASE_URL;
export const CASH_FUND_WEALTH_BASE_URL = window.env.CASH_FUND_WEALTH_BASE_URL;
export const INVITE_MAHANIERS_BASER_URL = window.env.INVITE_MAHANIERS_BASER_URL;
export const ENVIRONMENT_NAME = window.env.ENVIRONMENT_NAME;
export const PAYMENT_GATEWAY_BASER_URL = window.env.PAYMENT_GATEWAY_BASER_URL;
export const WEALTH_STORAGE_BASER_URL = window.env.WEALTH_STORAGE_BASER_URL;
export const COMMON_SYNC_CONNECTIONS = window.env.COMMON_SYNC_CONNECTIONS;
export const WEALTH_RAAST_PAYMENT_BASER_URL = window.env.WEALTH_RAAST_PAYMENT_BASER_URL;

export const MAHAANA_CONTACT_US = window.env.MAHAANA_CONTACT_US;
export const MAHAANA_ABOUT_US = window.env.MAHAANA_ABOUT_US;
export const MAHAANA_FAQS = window.env.MAHAANA_FAQS;
export const MAHAANA_TC = window.env.MAHAANA_TC;
export const MAHAANA_SAVE = window.env.MAHAANA_SAVE;
export const MAHAANA_MICF = window.env.MAHAANA_MICF;
export const MAHAANA_MAIN_LANDING_PAGE = window.env.MAHAANA_MAIN_LANDING_PAGE;
export const MAHAANA_STG_LANDING_PAGE = window.env.MAHAANA_STG_LANDING_PAGE;

export const MIXPANEL_API_TOKEN_DEV = window.env.MIXPANEL_API_TOKEN_DEV;
export const MIXPANEL_API_SECRET_DEV = window.env.MIXPANEL_API_SECRET_DEV;

export const MIXPANEL_API_TOKEN_PROD = window.env.MIXPANEL_API_TOKEN_PROD;
export const MIXPANEL_API_SECRET_PROD = window.env.MIXPANEL_API_SECRET_PROD;

export const Maintenance_Status = window.env.Maintenance_Status;

