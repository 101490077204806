import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import FormikReactSelect from "jsx/components/Shared/V1/FormikReactSelectV1"
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral'
import * as Yup from 'yup';
import DetailCardComp from '../Component/detailCardComp'
import { useHistory } from 'react-router-dom'
import { depositDetailCFArr, depositDetailGIArr, goalCategory, mixPannelTrackerEventName } from '../../../constants'
import { OnlineBankFormValue } from "redux/reducers/payment";
import { AxiosResponse } from 'axios'
import { links } from "static/links";
import { CurrencyFormatterWithDecimal } from 'utils/calculation'
import { StylesConfig } from 'react-select'
import BankDetailCard from '../Component/bankDetailCard'
import { useAppLoading } from 'hooks/appLoading'
import { CSSObject } from 'styled-components'
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1'
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker'
import { onGetTransactionDetail } from 'redux/reducers/sharedStateData'

import BankDetailGrid from 'Latest/MyProfile/BankDetails/bankDetailGrid'

const upperPDFErr = "Please upload receipt."

type Field = { label: string; value: string };
export const customStyleSelectPayment: StylesConfig<Field, false> = {
    valueContainer: (provided: CSSObject, state) => {
        return {
            ...provided,
            minHeight: state.hasValue ? '80px' : "auto", // Adjust the height as needed,
        };
    },
    menuList: (provided: CSSObject, state) => {
        return {
            ...provided,
            minHeight: "80px", // Adjust the height as needed,
        };
    },
};

const BankDeposit = ({ banksList, categoryAcc, formik, goalId, onSuccessTransaction, originalAmt, paymentAccList, previousStep, receiptCategory, submitBankTransfer, ...props }: any) => {

    const loading = useAppLoading('payment');
    const dispatch = useAppDispatch();
    const [openAccModal, setOpenAccModal] = useState(false);
    const investment = localStorage.getItem('InvestmentAmount');
    const initial = localStorage.getItem('InitialAmount');
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const { goalIdPost } = useAppSelector(state => state?.portfolio);
    const { location, push } = useHistory();
    const loc: any = location.state
    const [openErrModal, setOpenErrModal] = useState(false);
    const { addGoalBody } = useAppSelector(state => state.sharedData);
    const [filterBankList, setFilterBankList] = useState([])

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // const amt = goalId ? (goalId === CASH_FUND_ID ? (addGoalBody?.initialAmount) : formik?.values?.initial_amount) : loc?.amount?.investAmount
    const { user: { email } } = useAppSelector((state: any) => state.auth);

    const UserAccIban = userAccountDetails?.iban || "";

    const isRetirementAcc = categoryAcc.toLowerCase() == goalCategory.Retirement.toLowerCase()
    // const flowAccCategory = goalId == CASH_FUND_ID ? goalCategory.CashFund : goalCategory.GeneralInvest

    // const categoryAcc = loc?.key == "Sell" ? loc?.category : flowAccCategory

    const classes = useStyles();

    const { userBankAcc } = useAppSelector(state => state.sharedData)

    const SellCFBody = {
        "idCashFundInvestment": loc?.idCashFundInvestment,
        "investAmount": loc?.amount?.investAmount
    }

    const formikIbft = useFormik({
        enableReinitialize: true,
        initialValues: { proofOfPayment: '', selectedAccNo: UserAccIban, term1: false, transactionID: "" },
        onSubmit: (values: any) => {
            const { proofOfPayment, selectedAccNo, transactionID } = values;
            const FilterAccDetail = userBankAcc.filter((item: any, index: number) => item.accountNumber == selectedAccNo)

            const goalId = isRetirementAcc ? '' : loc?.key == "Sell" ? loc?.idCashFundInvestment : goalIdPost
            const goalIdisRetirementAcc = loc?.key == "Sell" ? loc?.idCashFundInvestment : goalIdPost


            const formData = new FormData();
            formData.append('accountNumber', selectedAccNo);
            formData.append('accountTitle', FilterAccDetail[0]?.accountTitle);
            formData.append('bankName', FilterAccDetail[0]?.bankName);
            formData.append('paymentReceipts[0].key', receiptCategory);
            formData.append('paymentReceipts[0].value', proofOfPayment);
            formData.append('goalId', goalId);
            formData.append('paymentGateway', "IBFT");
            formData.append('accountCategory', categoryAcc);
            formData.append('transactionReferenceNumber', transactionID || "-");
            formData.append('originalAmount', originalAmt);
            if (isRetirementAcc) {
                formData.append('VoluntaryPensionId',goalIdisRetirementAcc);
            }

            dispatch(OnlineBankFormValue(formData, onSuccessResponse));
            return;
            // eslint-disable-next-line no-console
        },
        validationSchema: Yup.object().shape({
            // term1: Yup.bool().oneOf([true], 'Please read and agree to our terms & conditions.'),
            selectedAccNo: Yup.string().required('Please select a bank account for deposit.'),
            proofOfPayment: Yup.mixed().required(upperPDFErr)
                .test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed.', (value: any) => {
                    if (value instanceof File) {
                        const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
                        const fileType = value.type;
                        return allowedFileTypes.includes(fileType);
                    }
                    return true;
                })
        })
    });


    // const onSuccess = (message: string, response: any) => {
    //     push(links.PayReceipt, { ...loc, "transactionReferenceNumber": formikIbft.values.transactionID });
    // }
    // const onFail = (message: string, response: AxiosResponse) => {
    //     console.log(response.status)
    //     setOpenErrModal(true)
    // }

    const getTransactionHistory = () => { //to check out transaction history for mixpanel 
        const onSuccess = (message: any, response: any) => {
            const filterTransactionDetail = message.filter((item: any, index: number) => item.goalId == goalIdPost)
            if (categoryAcc.toLowerCase() == goalCategory.CashFund.toLowerCase()) {
                filterTransactionDetail.length == 1 && MixPannelTracker({ eventName: mixPannelTrackerEventName.initial_transaction_submitted, email })
                filterTransactionDetail.length == 1 && Number(originalAmt) >= 10000 && MixPannelTracker({ eventName: mixPannelTrackerEventName.tenK_crossed, email })
            }
        }
        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
        }
        const param = {
            id: ""
        }
        dispatch(onGetTransactionDetail(param, onSuccess, onFail))
    }

    const onSuccessResponse = (message: string, response: AxiosResponse) => {
        // if (loc?.key == "Sell") {
        //     dispatch(SellCf(SellCFBody, onSuccess, onFail))
        // } else {
        //     push(links.PayReceipt, { ...loc, "transactionReferenceNumber": formikIbft.values.transactionID });
        // }
        onSuccessTransaction({ ...loc, "transactionReferenceNumber": formikIbft.values.transactionID })
        // push(links.PayReceipt, { ...loc, "transactionReferenceNumber": formikIbft.values.transactionID });
        getTransactionHistory()
        return;
    };

    React.useEffect(() => {
        if (userBankAcc?.length > 0) {
            const filterKey = userBankAcc?.map((item: any, ind: number) => (
                {
                    "label": `${item.bankName}<br/>${item.accountTitle}<br/> ${item.accountNumber}`,
                    "value": item.accountNumber
                }))
            setFilterBankList(filterKey)
        }
    }, [userBankAcc])

    const handleGoBack = (e: any) => {
        if (loc?.key == "Sell") {
            push(links.home)
        } else {
            e.preventDefault();
            previousStep();
        }
    }

    const depositArr = receiptCategory == goalCategory?.GeneralInvest ? depositDetailGIArr : depositDetailCFArr

    const buttonHtml = () => {
        return (
            <ButtonRowV1 handleBack={handleGoBack} handleNext={() => formikIbft.handleSubmit()} loadingNextBtn={loading} btnTitleNext={"Submit"} checkFreezAcc={true} />
        )
    }

    return (
        <>
            <Typography variant='h1' color="#422E84" sx={{
                p: categoryAcc != "GeneralInvestment" ? { xs: "16px 0px", md: "12px 20px" } : { xs: "0px 0px 16px 0px", md: "0px 20px 12px 20px" }, borderRadius: "8px", borderBottom: "1px solid rgba(208, 213, 221, 0.25)"
            }}>Deposit to Mahaana’s bank account</Typography>

            <Box sx={{ p: { xs: "16px 0px", md: "16px" } }}>

                <DetailCardComp
                    formik={formikIbft}
                    upperPDFErr={upperPDFErr}
                    depositDetailArr={depositArr}
                    fieldName={"proofOfPayment"}
                    singleCard={true}
                    depositAmt={CurrencyFormatterWithDecimal(originalAmt, true, "never", false)}
                />

            </Box>

            <Box sx={{ pt: userBankAcc?.length ? "40px" : "10px" }}>

                <Grid container md={12} xs={12} sx={{ px: { xs: "0px", md: "20px" }, }}>

                    <Grid item md={12} xs={12}>
                        <Grid container alignItems={"center"} pb={{ xs: "16px", md: "12px" }} gap={"16px"} justifyContent={"space-between"}>
                            {userBankAcc?.length ? <Typography variant='h1' color="#422E84" sx={{ fontSize: { md: "1.5rem", xs: "1rem" } }}>Your bank account</Typography> : null}
                            <BankDetailGrid screen="Paynow" />
                        </Grid>

                        {userBankAcc?.length ?
                            <Box>
                                {userBankAcc?.length > 1 ?
                                    <Box sx={{ maxWidth: "470px" }} >
                                        <FormikReactSelect
                                            required
                                            disabled={false}
                                            fieldName='selectedAccNo'
                                            fields={filterBankList}
                                            formik={formikIbft}
                                            // label='Select your deposit bank account'
                                            customStyle={customStyleSelectPayment}
                                            onChange={(val, option) => {
                                                formikIbft.setFieldValue('selectedAccNo', option.value);
                                            }}
                                            placeholder='Select your bank account'
                                            value={filterBankList.find((el: any) => el?.value == formikIbft?.values?.selectedAccNo)}
                                        />
                                    </Box>
                                    :
                                    <BankDetailCard iban={userAccountDetails?.iban} userBankList={userBankAcc} />
                                }

                                <Typography pt="16px" variant='body1' sx={{ textAlign: { xs: "center", md: "left" } }}><span style={{ fontWeight: 500 }} >Important:</span> Deposit must be made from a bank account that's in your name</Typography>
                            </Box>
                            : null}
                    </Grid>

                </Grid>

                {!smScreen && <Box sx={{ mt: { xs: "24px", md: "40px" } }}>
                    {buttonHtml()}
                </Box>}

            </Box>

            {smScreen && <Box sx={{ p: "8px 16px", mt: "24px", background: "white" }}>
                {buttonHtml()}
            </Box>}


            {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>

                <Box sx={PayNowStylesV1.blueChip}>
                    <Typography variant='subtitle2'>Important:</Typography>

                    <Typography variant='body2'>Deposit must be made from a bank account that's in your name.</Typography>

                </Box>

                <Box sx={PayNowStylesV1.cardBox}>

                    <Typography className='mainCardHead' variant='h6' > <img src={bankDepositIcon} />Bank Deposit</Typography>

                    <Box className="innerMainBox">
                        <DetailCardComp
                            formik={formikIbft}
                            upperPDFErr={upperPDFErr}
                            depositDetailArr={depositArr}
                            fieldName={"proofOfPayment"}
                            singleCard={true}
                            depositAmt={CurrencyFormatterWithDecimal(originalAmt, true, "never", false)}
                        />
                    </Box>
                </Box>

                <Box>
                    {userBankAcc?.length > 1 ?
                        <FormikReactSelect
                            required
                            disabled={false}
                            fieldName='selectedAccNo'
                            fields={filterBankList}
                            formik={formikIbft}
                            label='Select your deposit bank account'
                            customStyle={customStyleSelectPayment}
                            onChange={(val, option) => {
                                formikIbft.setFieldValue('selectedAccNo', option.value);
                            }}
                            placeholder='Select your bank account'
                            value={filterBankList.find((el: any) => el?.value == formikIbft?.values?.selectedAccNo)}
                        />
                        :
                        <BankDetailCard iban={userAccountDetails?.iban} userBankList={userBankAcc} />}
                </Box>

                <Box>
                    <BankDetails screen="Paynow" />
                </Box>

                <Grid item xs={12} sx={{ display: "flex", gap: "26px" }}>
                    <CustomSingleCheckBoxV1
                        formik={formikIbft}
                        fieldName={`term1`}
                        label={`I have read and agree to Mahaana’s <span style=color:#43BED8 ; cursor:pointer>terms and conditions.</span>`}
                    />
                </Grid>


                <Grid item xs={12} sx={{ display: "flex", gap: "26px" }}>
                    <Button fullWidth size={"large"} variant='outlined' className={classes.neutralButton} onClick={handleGoBack}>Back</Button>
                    <LoadingButton disableElevation fullWidth loading={loading} size={"large"} variant='contained' color='secondary' onClick={() => formikIbft.handleSubmit()}>Submit</LoadingButton>
                </Grid>
            </Box> */}
        </>
    )
}

export default BankDeposit