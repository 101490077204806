/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import checkImage from 'Latest/Images/check.png';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { links } from 'static/links';
import { commaFormatted } from 'utils/commaSeparateMasking';
import { onPatchNotifications, onPatchSubmitKYCStatus } from 'redux/reducers/sharedStateData';
import { useAppDispatch } from 'hooks/reduxHooks';


const PayReceipt = ({ amount, customhandleClick = false, handleClick, handleClose }: any) => {
    
    const { location, push } = useHistory();
    const dispatch = useAppDispatch();

    const handleBtn = () => {
        push(links.home)
    }

    useEffect(() => {
        handleAPIForStatus()
    }, [])
    
    const handleAPIForStatus = () =>{
        dispatch(onPatchSubmitKYCStatus())
          //Disable Notification
          const formData: any = new FormData();
          formData.append('EnableNotifications', false);
          formData.append('NotificationType', "kyc");
          dispatch(onPatchNotifications(formData))
    }

    return (
        <Box >
            <Grid container>
                <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: 4, mt: 4 }}>
                    <img
                        src={checkImage}
                        alt={'check'}
                        loading="lazy"
                    />
                </Grid>

                <Grid item md={12} xs={12} textAlign={"center"}>
                    <Typography variant='h5'>Your information has been submitted for upgrade </Typography>
                </Grid>
                <Grid item md={12} xs={12} textAlign={"center"}>
                    <Typography variant='body1'>Our operations team will get back to you in 5 working days</Typography>
                </Grid>



                <Grid item md={12} xs={12} my={4} justifyContent={"center"} display={"flex"}>
                    <Button variant="contained" color={"secondary"} onClick={handleBtn} >ok</Button>
                </Grid>
            </Grid>
        </Box>

    )

}

export default PayReceipt