import { Box, Grid, List, ListItem, Typography, useMediaQuery, useTheme } from '@mui/material'
import BottomBtn from 'Latest/2ndTierKyc/bottomBtn'
import React from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import UploadFileCard from './uploadFileCard';
import { useAppLoading } from 'hooks/appLoading';
import { useAppSelector } from 'hooks/reduxHooks';
import { useHistory } from 'react-router';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';

const content = {
    web: [
        "<strong>Employed / salaried:</strong> Recent salary slip / salary certificate and / or wealth statement",
        "<strong>Sole proprietor:</strong> Attested copy of registration certificate for registered concerns and sales tax registration document",
        "<strong>Student:</strong> Student card and letter of support",
        "<strong>Freelancer:</strong> Freelance contract and/or wealth statement",
        "<strong>Retired:</strong> Retirement letter / proof of retirement and/or wealth statement",
        "<strong>Bank Statement:</strong> 3 months",
    ],
    mobPOIContent: [
        "<strong>Employed / salaried:</strong> Recent salary slip / salary certificate and / or wealth statement",
        "<strong>Sole proprietor:</strong> Attested copy of registration certificate for registered concerns and sales tax registration document",
        "<strong>Student:</strong> Student card and letter of support",
        "<strong>Freelancer:</strong> Freelance contract and/or wealth statement",
        "<strong>Retired:</strong> Retirement letter / proof of retirement and/or wealth statement",
    ],
    mobMonthBankStat: [

    ]

}

const IndexV2 = ({ colors, formik, handleNext, handlePrevious, mdScreenDown }: any) => {
    const loading = useAppLoading('onPostSecondTier');
    const { userAccountDetails } = useAppSelector((state: any) => state.auth);
    const uploadedIncomeProofsFile = userAccountDetails?.kyc?.kycDocument?.incomeProofs || []
    const uploadedBankStatementsFile = userAccountDetails?.kyc?.kycDocument?.bankStatements || []


    const { push } = useHistory();

    const handleSaveExit = () => {
        formik.handleSubmit()
        push("myprofile?scroll=true")
    }

    return (
        <Box>
            {mdScreenDown && <Grid item xs={12} sx={{ mb: '16px' }} >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', pb: '16px', }}>
                    <Typography variant='h6' color={colors.grey[800]}>Upgrade account</Typography>
                    <Typography variant='h6' sx={{ color: "#443087", cursor: 'pointer' }} onClick={handleSaveExit}>Save & Exit</Typography>
                </Box>
                <Box sx={{ borderBottom: "1px solid #EAECF0", mx: { xs: '-16px', sm: "-30px" } }} />
            </Grid>}
            <Grid item md={12} mb={"24px"}>
                <Typography color={'#202020!important'} sx={{ typography: { xs: 'h5', md: 'h1' }, }}>Document Uploads</Typography>
            </Grid>

            <Grid item container justifyContent={"space-between"} sx={{ gap: '24px' }}>
                <Grid item md={5.8} xs={12}>
                    <Typography variant="h5" fontWeight={mdScreenDown ? 600 : 500} color={mdScreenDown ? '#202020' : "#422E84"} mb={"8px"}>Upload proof of income documents {!mdScreenDown && <span style={{ color: "#E14640" }}>*</span>}</Typography>
                    <UploadFileCard formik={formik} fieldName={"uploadPoiImg"} loading={loading} uploadedFile={uploadedIncomeProofsFile} uploadBtnTitle={"Upload more file"} />

                    {mdScreenDown && <Box sx={{ pt: '16px' }}>
                        <Typography variant='body1' color={"#344054"} lineHeight={"24.8px"}>Please provide documents that prove your income or wealth: </Typography>
                        <List sx={{ p: 0, pl: 1 }}>
                            {content.mobPOIContent?.map((item, index) => (
                                <ListItem key={index} sx={{ alignItems: 'flex-start', padding: 0, mt: index == 0 ? "0" : "8px" }}>
                                    <FiberManualRecordIcon sx={{ color: "#344054", width: "8px", mr: 1, mb: "3px" }} />
                                    <Typography variant="body1" color={"#344054"} lineHeight={"24.8px"} sx={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: item }} />
                                </ListItem>
                            ))}
                        </List>
                        <Typography variant="body1" color={"#344054"} lineHeight={"24.8px"}>
                            In case of any questions please reach out to our <a href='/support' style={{ textDecoration: "underline", color: '#344054' }}>support team</a>.
                        </Typography>

                    </Box>}
                </Grid>
                <Grid item md={5.8} xs={12}>
                    <Typography variant="h5" fontWeight={mdScreenDown ? 600 : 500} color={mdScreenDown ? '#202020' : "#422E84"} mb={"8px"}>Upload your 3 month bank statement {!mdScreenDown && <span style={{ color: "#E14640" }}>*</span>}</Typography>
                    <UploadFileCard formik={formik} fieldName={"uploadBankStatement"} loading={loading} uploadedFile={uploadedBankStatementsFile} uploadBtnTitle={"Upload more file"} fileCountLimit={3} />
                    {mdScreenDown && <Box sx={{ pt: '16px' }}>
                        <Typography variant='body1' color={"#344054"} lineHeight={"24.8px"}>Please provide your bank statement for the past 3 months.</Typography>
                    </Box>}
                </Grid>
            </Grid>


            {!mdScreenDown && <Grid item xs={12} md={12} pt="8px" pb={"32px"}>
                <Typography variant='body1' color={"#344054"} lineHeight={"24.8px"}>Please provide the documents listed below:</Typography>
                <List sx={{ p: 0, pl: 1 }}>
                    {content.web?.map((item, index) => (
                        <ListItem key={index} sx={{ alignItems: 'flex-start', padding: 0, mt: index == 0 ? "0" : "8px" }}>
                            <FiberManualRecordIcon sx={{ color: "#344054", width: "8px", mr: 1, mb: "3px" }} />
                            <Typography variant="body1" color={"#344054"} lineHeight={"24.8px"} sx={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: item }} />
                        </ListItem>
                    ))}
                </List>

                <Typography variant="body1" color={"#344054"} lineHeight={"24.8px"}>
                    In case of any questions please reach out to our <a href='/support' style={{ textDecoration: "underline", color: '#344054' }}>support team</a>.
                </Typography>
            </Grid>}


            <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: "space-between", pt: '16px' }} width={"100%"}>
                {/* <ButtonRowV1 handleBack={handleSaveExit} btnTitleBack={"Save & Exit"} isShowNext={false} containerStyles={{ justifyContent: "flex-start" }} />
                <ButtonRowV1 handleBack={handlePrevious} handleNext={() => formik.handleSubmit()} btnTitleNext={"Continue"} loading={loading} /> */}

                <BottomBtn handleSaveExit={handleSaveExit} handlePrevious={handlePrevious} handleNext={() => formik.handleSubmit()} loading={loading}
                    showBtnSaveExist={!mdScreenDown}
                    styleBoxBtn={{ width: '100%', justifyContent: mdScreenDown ? "space-between" : "flex-end", }}

                />
            </Grid>

        </Box>
    )
}

export default IndexV2
