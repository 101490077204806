import * as Yup from 'yup';
import { parsePhoneNumber } from 'libphonenumber-js';

export const generateDeclarationValidationSchema = () => Yup.object().shape({

  numberRegisterBelonging: Yup.string().required('This field is required'),

  uploadIntPhoneBillImg: Yup.mixed().when(['numberRegisterBelonging', 'contactNumber'], {
    is: (numberRegisterBelonging: string, contactNumber: string) => {
      // Commenting below line to enable required validation for both "numberRegisterBelonging" yes and no
      // if (numberRegisterBelonging !== 'Yes') return false;
      const parsedNumber = parsePhoneNumber(contactNumber, 'PK');
      return parsedNumber?.country !== 'PK';
    },
    // then: Yup.mixed()
    //   .test('fileRequired', 'Please upload a phone bill for the international number', (value) => {
    //     return value && (!Array.isArray(value) || value.length > 0);
    //   })
    //   .test('fileType', 'Please upload a PNG, JPEG, or PDF file', (value) => {
    //     if (!value) return true; // Skip if no file
    //     const file = Array.isArray(value) ? value[0].file : value;
    //     return ['image/png', 'image/jpeg', 'application/pdf'].includes(file.type);
    //   })
    //   .test('fileSize', 'File size must be between 1KB and 10MB', (value) => {
    //     if (!value) return true; // Skip if no file
    //     const file = Array.isArray(value) ? value[0].file : value;
    //     const fileSize = file.size;
    //     return fileSize >= 1024 && fileSize <= 10 * 1024 * 1024;
    //   }),
    // otherwise: Yup.mixed().notRequired(),
    then: Yup.array().min(1, "required").max(1, 'You can upload single file').test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed.', (value: any) => {
      // Check file extensions
      const filterFile = value.filter((i: any) => i.file && i)
      const allowedFileExtensions = ['.png', '.jpg', '.jpeg', '.pdf'];

      if (filterFile.length > 0) {
        return filterFile?.every((item: any) => {
          const fileName = item.documentName
          const fileExtension = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
          return (allowedFileExtensions.includes(fileExtension))
        });
      }
      return true;
    })
      .test('fileSize', "", function (value: any, context) {
        const filterFile = value.filter((i: any) => i.file && i)    //check those file which was not uploaded earlier
        const minSize = 1000; // 1 KB
        const maxSize = 10000000; // 10 MB
        const filterInvalidFileName = filterFile?.filter((item: any) => item?.file && (item?.file?.size <= minSize || item?.file?.size >= maxSize))

        if (filterInvalidFileName?.length > 0) {
          const invalidFileNames = filterInvalidFileName.map((item: any) => item.file.name);
          const errorMessage = `File size should be 1 KB to 10 MB. Invalid files: ${invalidFileNames.join(', ')}`;
          return this.createError({
            message: errorMessage
          });
        }
        return true
      }),
  }),

  numberBelonging: Yup.string().when('numberRegisterBelonging', {
    is: 'No',
    then: Yup.string().required('This field is required'),
  }),

  FamilyMemberName: Yup.string()
    .when(['numberRegisterBelonging', 'numberBelonging'], {
      is: (numberRegisterBelonging: string, numberBelonging: string) => numberRegisterBelonging == "No" && numberBelonging?.toLowerCase() == 'FAMILY MEMBER'.toLowerCase(),
      then: Yup.string().required('Family Member Name is required'),
    }),

  CNICNumber: Yup.string()
    .when(['numberRegisterBelonging', 'numberBelonging'], {
      is: (numberRegisterBelonging: string, numberBelonging: string) => numberRegisterBelonging == "No" && numberBelonging?.toLowerCase() == 'FAMILY MEMBER'.toLowerCase(),
      then: Yup.string()
        .required('CNIC is required')
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          'CNIC number is not valid'
        )
        .length(13, 'CNIC number is not valid'),
    }),

  Relationship: Yup.string()
    .when(['numberRegisterBelonging', 'numberBelonging'], {
      is: (numberRegisterBelonging: string, numberBelonging: string) => numberRegisterBelonging == "No" && numberBelonging?.toLowerCase() == 'FAMILY MEMBER'.toLowerCase(),
      then: Yup.string().required('Relationship is required'),
    }),

  authToUseThisNum: Yup.bool().when('numberRegisterBelonging', {
    is: 'No',
    then: Yup.bool().oneOf([true], 'Required'),
  }),

  uploadAuthImg: Yup.mixed().when(['numberRegisterBelonging', 'numberBelonging'], {
    is: (numberRegisterBelonging: string, numberBelonging: string) => numberRegisterBelonging == "No" && numberBelonging?.toLowerCase() == "employer",
    then: Yup.array().min(1, "required").max(1, 'You can upload a maximum of 1 images').test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed.', (value: any) => {
      // Check file extensions
      const filterFile = value.filter((i: any) => i.file && i)
      const allowedFileExtensions = ['.png', '.jpg', '.jpeg', '.pdf'];

      if (filterFile.length > 0) {
        return filterFile?.every((item: any) => {
          const fileName = item.documentName
          const fileExtension = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
          return (allowedFileExtensions.includes(fileExtension))
        });
      }
      return true;
    })
      .test('fileSize', "", function (value: any, context) {
        const filterFile = value.filter((i: any) => i.file && i)    //check those file which was not uploaded earlier
        const minSize = 1000; // 1 KB
        const maxSize = 10000000; // 10 MB
        const filterInvalidFileName = filterFile?.filter((item: any) => item?.file && (item?.file?.size <= minSize || item?.file?.size >= maxSize))

        if (filterInvalidFileName?.length > 0) {
          const invalidFileNames = filterInvalidFileName.map((item: any) => item.file.name);
          const errorMessage = `File size should be 1 KB to 10 MB. Invalid files: ${invalidFileNames.join(', ')}`;
          return this.createError({
            message: errorMessage
          });
        }
        return true
      }),
    // then: Yup.mixed()
    //   .test('required', 'Required', function (value) {
    //     if (!value || (Array.isArray(value) && value.length === 0)) {
    //       return false;
    //     }
    //     return true;
    //   })
    //   .test('fileSize', 'File size should be between 1 KB and 10 MB', function (value) {
    //     if (!value || (Array.isArray(value) && value.length === 0)) {
    //       return true; // Skip size validation if no file
    //     }
    //     const file = Array.isArray(value) ? value[0].file : value;
    //     return file.size >= 1024 && file.size <= 10 * 1024 * 1024;
    //   })
    //   .test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed', function (value) {
    //     if (!value || (Array.isArray(value) && value.length === 0)) {
    //       return true; // Skip type validation if no file
    //     }
    //     const file = Array.isArray(value) ? value[0].file : value;
    //     return ['image/png', 'image/jpeg', 'application/pdf'].includes(file.type);
    //   }),
    otherwise: Yup.mixed().notRequired(),
  }),

  uploadPhoneBillImg: Yup.mixed().when(['numberRegisterBelonging', 'numberBelonging', 'contactNumber'], {
    is: (numberRegisterBelonging: string, numberBelonging: string, contactNumber: string) => numberRegisterBelonging == "No" && numberBelonging?.toLowerCase() == "employer" && parsePhoneNumber(contactNumber, 'PK').country == 'PK',
    then: Yup.array().min(1, "required").max(1, 'You can upload a maximum of 1 images').test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed.', (value: any) => {
      // Check file extensions
      const filterFile = value.filter((i: any) => i.file && i)
      const allowedFileExtensions = ['.png', '.jpg', '.jpeg', '.pdf'];

      if (filterFile.length > 0) {
        return filterFile?.every((item: any) => {
          const fileName = item.documentName
          const fileExtension = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
          return (allowedFileExtensions.includes(fileExtension))
        });
      }
      return true;
    })
      .test('fileSize', "", function (value: any, context) {
        const filterFile = value.filter((i: any) => i.file && i)    //check those file which was not uploaded earlier
        const minSize = 1000; // 1 KB
        const maxSize = 10000000; // 10 MB
        const filterInvalidFileName = filterFile?.filter((item: any) => item?.file && (item?.file?.size <= minSize || item?.file?.size >= maxSize))

        if (filterInvalidFileName?.length > 0) {
          const invalidFileNames = filterInvalidFileName.map((item: any) => item.file.name);
          const errorMessage = `File size should be 1 KB to 10 MB. Invalid files: ${invalidFileNames.join(', ')}`;
          return this.createError({
            message: errorMessage
          });
        }
        return true
      }),



    // Yup.mixed().test('required', 'Required', function (value) {

    //   if (!value || (Array.isArray(value) && value.length === 0)) {

    //     return false;
    //   }
    //   return true;
    // })
    //   .test('fileSize', 'File size should be between 1 KB and 10 MB', function (value) {
    //     if (!value || (Array.isArray(value) && value.length === 0)) {
    //       return true; // Skip size validation if no file
    //     }
    //     const file = Array.isArray(value) ? value[0].file : value;
    //     return file.size >= 1024 && file.size <= 10 * 1024 * 1024;
    //   })
    //   .test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed', function (value) {
    //     if (!value || (Array.isArray(value) && value.length === 0)) {
    //       return true; // Skip type validation if no file
    //     }
    //     const file = Array.isArray(value) ? value[0].file : value;
    //     return ['image/png', 'image/jpeg', 'application/pdf'].includes(file.type);
    //   }),
    otherwise: Yup.mixed().notRequired(),
  }),

});