import React from 'react'
import { Box, Alert, AlertTitle, Button, Typography } from '@mui/material'
import useStylesAlertWarning from 'Latest/ComponentV1/CustomMakeStyle/customWarningAlert'
import IconWarning from "Latest/Images/svg/IconWarning.svg"
import { useAppDispatch } from 'hooks/reduxHooks'
import { handleUserPhEmVerifyModal, handleUserPhEmVerifyModalSpec } from 'redux/reducers/userPhoneEmailVerification'

const UserPhoneEmailVerificationAlert = ({ btnTitle = 'Verify', spec }: any) => {
    const classes = useStylesAlertWarning();
    const dispatch = useAppDispatch()
    const handleVerifyAccount = () => {
        dispatch(handleUserPhEmVerifyModal(true))
        dispatch(handleUserPhEmVerifyModalSpec(spec))
    }

    return (
        <Box>
            <Alert severity="warning" className={classes.customAlert}
                // onClose={handleClose} 
                icon={false}>
                <AlertTitle className={classes.customAlertTitle} sx={{ fontWeight: 500 }}>
                    <img src={IconWarning} />
                    Please verify your account.
                </AlertTitle>
                <Typography className={classes.customText}>Your account is not verified. Kindly verify your account by clicking here.</Typography>
                <Button onClick={handleVerifyAccount} className={classes.customButton} sx={{ borderRadius: "100px", height: "37px", textTransform: "unset", fontWeight: 500, }} >{btnTitle}</Button>
            </Alert>
        </Box>
    )
}

export default UserPhoneEmailVerificationAlert