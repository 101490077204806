import { Box, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import Button from '@mui/material/Button';
import PhoneField from 'jsx/components/Shared/PhoneField';
import { useFormik } from 'formik';
import { useAppSelector } from 'hooks/reduxHooks';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input'
import CloseIcon from '@mui/icons-material/Close';


const UpdateInfo = ({ handleCloseFunc }: any) => {

    const handleOnClick = () => {
        handleCloseFunc()
    }

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", paddingBottom: '12px', }}>
                <Typography variant='h4' sx={{ color: '#1D2939' }}>Phone update in process</Typography>

                <IconButton aria-label="close" component="label" onClick={handleCloseFunc}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column', paddingBottom: '28px', }}>
                <Typography variant='body1' sx={{ color: '#1D2939B2' }}>Your request to update your phone number has been accepted. It will take us up to 5 working days to update your phone number.</Typography>
                <Typography variant='h6' sx={{ color: '#1D2939B2' }}>While we verify your new number, please complete the other requirements, if any.</Typography>
            </Box>

            <Button fullWidth variant='contained' color={"secondary"} onClick={handleOnClick}>OK</Button>

        </Box>
    )

}

export default UpdateInfo