import * as Yup from 'yup';
import { checkIBANForRDA } from 'utils/validator';

export const OnlineBankFormSchema = Yup.object({
  // transactionID: Yup.string()
  //   .required('Transaction ID is required'),
  accountTitle: Yup.string()
    .required('Account title is required')
    .matches(/^[a-zA-Z .]+$/, 'Only alphabets are allowed.'),
  bank: Yup.object().required("Bank is required").nullable(),
  accountNumber: Yup.string()
    .required('IBAN / Account number is required')
    .matches(/^[A-Za-z0-9_]+$/, 'Number and Alphabets are allowed.')
    .min(7, 'Please enter a valid account number')
    .max(24, 'Please enter a valid account number'),
  proofOfPayment: Yup.mixed()
    .required('Proof of payment is required')
    .test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed.', function (value: any) {
      const allowedFileExtensions = ['.png', '.jpg', '.jpeg', '.pdf'];
      if (value != undefined) {
        const fileName = value.name
        const fileExtension = fileName.substr(fileName.lastIndexOf('.')).toLowerCase()
        if (!allowedFileExtensions.includes(fileExtension)) {
          return this.createError({
            message: 'Invalid file type. Only PNG, JPG, or PDF files are allowed.'
          });
        }
      }
      return true;
    })
    .test(function (value, context) {
      if (value && value.size > 10000000) {
        return this.createError({
          message: 'Max file size: 10 MB'
        });
      }
      return true;
    })
});

export const UserBankSubmitSchema = Yup.object({
  accountTitle: Yup.string()
    .required('Account title is required')
    .matches(/^[a-zA-Z .]+$/, 'Only alphabets are allowed.'),

  bank: Yup.object().required("Bank is required").nullable(),

  accountNumber: Yup.string()
    .required('IBAN / Account number is required')
    .matches(/^[A-Za-z0-9_]+$/, 'Number and Alphabets are allowed.')
    .min(7, 'Please enter a valid account number')
    .max(24, 'Please enter a valid account number')
    .test('check-rda', 'Only Meezan RDA bank accounts are supported.', function(value) {
      const { isRDA } = this.parent; 
      const isRDAValid = isRDA == 'Yes'

      if (isRDAValid && checkIBANForRDA(value) ) {
        return false;
      }

      return true;
    }),
    isRDA: Yup.string().required('Required').nullable(),

});
