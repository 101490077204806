export enum WizardStepsEnum {
    Step_ChooseInvestment = 'Choose investment',
    Step_SavingPlan = 'Saving plan',
    Step_RiskProfileConfirmation = "Step RiskProfile Confirmation",
    Step_RiskProfile = 'Risk profile',
    Step_Portfolio = 'Portfolio',
    Step_CompleteYourAccount = "Let's complete your account",
    Step_Address = "Set Address",
    Step_Account_Setup_Form = "Account Setup Form",
    Step_Review_Info = 'Review Info',
    Step_Bank_Details = 'Bank Details',
    Step_Additional_Details = 'Additional Details',
    Step_Additional_Details_Permanent_Addr="Additional Details Permanent Address",
    Step_Term_Condition = 'Term & Condition',
    Step_PayNow = "PayNow",
    Step_Receipt = "Receipt",
    Step_ThirdParty = "ThirdParty",
    Update_Question ="Update Question",
    occupational_Field = "Occupational pension",
    employerName = "Employer Name",
    success_Reciept = "Success Reciept",
    step_VPSInfo = "Vps info"

}

export enum WizardStepsVPSEnum{
    GET_CNIC = "Get Cnic"
}
