import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import settingCircleIcon from "Latest/Images/svg/settingCircleIcon.svg";
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack';
import { useHistory } from 'react-router-dom';
import { CurrencyFormatterWithDecimal } from 'utils/calculation';
import { useAppSelector } from 'hooks/reduxHooks';
import { pxToRem } from 'utils/V1/getFontValues';
import warningIcon from 'Latest/Images/svg/WarningDiamond.svg';
import Raastlogo from 'Latest/Images/svg/Raastlogo.svg';

import banksim from 'Latest/Images/svg/banksim.svg'
import person from 'Latest/Images/svg/person.svg'
import CDC from 'Latest/Images/svg/CDC.svg'
import stars from 'Latest/Images/svg/stars.svg'
import copy from 'Latest/Images/svg/copy.svg'
import { toast } from 'react-toastify'
import CustomBoxTab from 'Latest/ComponentV1/CustomTab/CustomBoxTabV2';


interface StepMethodProps {
  steps: { icon: string, description: string }[];
  methodName: string;
}

type TabValue = 'method1' | 'method2';


interface ContentTab {
  label: string;
  value: TabValue;
  comp: React.ReactNode;
}

interface ImportantInfoProps {
  data: string[];
}
interface MethodSwitcherProps {
  method1Visible: boolean;
  setMethod1Visible: React.Dispatch<React.SetStateAction<boolean>>;
}

// Toggle button component
const MethodSwitcher: React.FC<MethodSwitcherProps> = ({ method1Visible, setMethod1Visible }) => {
  const toggleMethod = () => {
    setMethod1Visible(!method1Visible);
  };

  return (
    <Button variant="contained" onClick={toggleMethod}>
      {method1Visible ? 'Switch to Method 2' : 'Switch to Method 1'}
    </Button>
  );
};
const GenerateIdViewRaast = ({ categoryAcc, originalAmt, previousStep, raastId }: any) => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { push } = useHistory();
  const { userAccountDetails } = useAppSelector(state => state.auth);
  const [textToCopy, setTextToCopy] = useState(false);
  const [method1Visible, setMethod1Visible] = useState(true);
  const [activeMethod, setActiveMethod] = useState(method1Visible ? 'method1' : 'method2');

  const handleSaveClipBoard = async (copyMe: string) => {
    await navigator.clipboard.writeText(copyMe)
    setTextToCopy(true);
    toast.success('Text has been copied.', { theme: "colored" });
    setTimeout(() => {
      setTextToCopy(false)
    }, 3000);
  }

  const buttonHtml = () => {
    return (
      <Box sx={{ display: "flex", width: "100%", alignItems: "center", flexDirection: "row", gap: { xs: "8px", md: "8px" }, justifyContent: { xs: "space-between", md: "flex-end" }, }}>
        <LoadingBtnCustomBack
          handleOnClick={previousStep ? previousStep : () => push("/")}
          btnTitle={"Back"}
        />

        <LoadingBtnCustom
          handleOnClick={() => push("/")}
          btnTitle={"Go to dashboard"}


        />
      </Box>


    );
  };

  const ImportantInfoComp = ({ data }: ImportantInfoProps) => {
    return (
      <Box sx={{ borderBottom: "1px solid #D0D5DD40", borderRadius: "8px", pt: '12px', pb: '8px', background: "#FEE9E8", mt: "8px" }}>
        <Box sx={{ px: '1rem', display: "flex", alignItems: 'center', gap: '4px', mb: '8px' }}>
          <img src={warningIcon} height={20} width={20} alt="Warning" />
          <Typography variant='h6' color="#821B13">Important information</Typography>
        </Box>
        <Box px={5}>
          {data?.map((info: string) => (
            <Typography key={info} sx={{ listStyleType: 'disc', display: 'list-item' }} variant='body2' color={"#101828D9"} dangerouslySetInnerHTML={{ __html: info }} />
          ))}
        </Box>
      </Box>
    );
  };


  const StepMethod: React.FC<StepMethodProps> = ({ methodName, steps }) => {
    return (
      <Box sx={{ marginTop: { xs: '7px' } }}>
        {!smScreen && (
          <Typography variant='h5' color='#616A75' mb={2}>
            {methodName}
          </Typography>
        )}
        {steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex', alignItems: 'center', mb: '8px', border: '1px solid #CCCCCC80', borderRadius: '8px', p: '16px',
              width: '100%'
            }}
          >
            <img src={step.icon} height={40} width={40} alt={`Step ${index + 1}`} />
            <Box ml={2} sx={{ display: 'flex', gap: '4px', flexDirection: 'column' }}>
              <Typography variant='body2' color='#666666'> Step #{index + 1}</Typography>
              <Typography variant='body2' color='#281C51' fontWeight={400} sx={{ fontSize: { xs: '16px', sm: '16px', md: '16px' } }}>  {step.description}   </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const method1Steps = [
    {
      icon: banksim,
      description: 'Log into your bank app',
    },
    {
      icon: person,
      description: 'Create a new beneficiary / payee (with IBAN)',
    },
    {
      icon: CDC,
      description: 'Select Central Depository Company in the list of banks',
    },
    {
      icon: stars,
      description: 'Enter your Raast Investment IBAN and submit your transaction',
    },
  ];

  const method2Steps = [
    {
      icon: banksim,
      description: 'Log into your bank app',
    },
    {
      icon: Raastlogo,
      description: 'Select Raast payment (with IBAN)',
    },
    {
      icon: stars,
      description: 'Enter your Raast Investment IBAN and submit your transaction',
    },
  ];

  const handleClick = () => {
    window.open('https://www.cdcpakistan.com/', '_blank');
  };
  const contentTabs: ContentTab[] = [
    {
      label: 'Method 1',
      value: 'method1',
      comp: <StepMethod steps={method1Steps} methodName="Method 1" />,

    },
    {
      label: 'Method 2',
      value: 'method2',
      comp: <StepMethod steps={method2Steps} methodName="Method 2" />,
    }
  ];
  const customTabStyles = {
    border: '1px solid #D0D5DD',
    color: '#1D2939B2',
  };
  const boxTab = {
    '& .MuiTabs-flexContainer': {
      height: '100%',
      gap: "16px",
      paddingRight: '0px',
    },
  }

  const infoData = [
    `Please only transfer funds from a bank account that’s in <span style="color:#821B13; font-weight:600">YOUR NAME.</span>`,
    "If you transfer from a bank account not in your name, your deposit will be reverted in 1 month.",
    "You’ll receive an email from us once you transact."
  ]

  return (
    <>
      <ImportantInfoComp data={infoData} />

      <Typography color="#422E84!important" sx={{ p: { xs: '24px 8px 8px 8px', md: "24px 0 8px 0" }, typography: { xs: 'h5', md: 'h1' } }}>Your Raast Investment ID IBAN generated by <span onClick={handleClick} style={{color:"#422E84 !important" , textDecoration:'underline' , cursor:'pointer' }} >CDC:</span></Typography>

      <Box >
        <Grid container spacing={2} mb={3}  >

          <Grid item md={6} xs={12}  >

            <Box sx={{
              borderRadius: "8px", display: 'flex', gap: '8px', alignItems: 'center', p: { xs: "4px 8px", md: "16px" }, minHeight: { xs: '80px', md: '108px' }, justifyContent: "space-between",
              boxShadow: "0px 1px 4px 0px #D0D5DD40",
              border: '1px solid #F3F4F6'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: "4px", md: "8px" } }}>
                <img src={Raastlogo} height={smScreen ? 22 : 55} width={smScreen ? 22 : 55} alt="Raast Logo" />
                <Box sx={{ display: 'flex', flexDirection: "column", gap: '4px' }}>
                  <Typography variant='body2' sx={{ wordBreak: 'break-all' }} color={"#616A75"} >MICP - {userAccountDetails?.identityCard?.name}</Typography>
                  <Typography variant='h6' sx={{ fontSize: { md: "20px!important" }, wordBreak: "break-all" }} color={"#1D2939!important"}>{raastId}</Typography>
                </Box>
              </Box>
              <img src={copy} style={{ cursor: 'pointer' }} onClick={() => handleSaveClipBoard(raastId)} />
            </Box>
          </Grid>


          <Grid item md={6} xs={12}>
            <Box
              sx={{
                p: { xs: "4px 8px", md: "16px" }, minHeight: { xs: '80px', md: '108px' }, background: "#422E84", borderRadius: "8px", display: 'flex', justifyContent: 'center',
                alignItems: 'center', flexDirection: 'column', width: { lg: '100%' },  gap: '8px',
              }}
            >
              <Typography variant='body2' color="#FFFFFFE5" >Deposit Amount</Typography>
              <Typography variant='h1' color={"#FFFFFFE5"}> {CurrencyFormatterWithDecimal(originalAmt, true, "never", true)}  </Typography>
            </Box>
          </Grid>
        </Grid>

        <Typography color="#422E84!important" sx={{ typography: { xs: 'h5', md: 'h1' }, p: { xs: "8px 0px 16px 0px", md: "8px 0 8px 0 " } }} >  Steps to make a Raast transaction  </Typography>

        {smScreen ? (
          <Box>
            <CustomBoxTab
              contentTabs={contentTabs}
              customTabs={boxTab}
              customTab={customTabStyles}
              defaultActiveTab={0}
              scrollBtn={false}
              renderTabContent={false}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '24px',
              marginTop: '16px',
            }}
          >
            <StepMethod steps={method1Steps} methodName='Method 1' />
            <StepMethod steps={method2Steps} methodName='Method 2' />
          </Box>
        )}
      </Box>

      <Box sx={{
        display: "flex",
        justifyContent: 'flex-end',
        alignItems: { xs: "flex-start", sm: "center" },
        gap: "8px",
        flexDirection: { xs: "column", sm: "row" },
        mt: "16px"
      }}>
        {buttonHtml()}
      </Box>

    </>

  )
};

export default GenerateIdViewRaast;
