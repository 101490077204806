import { pxToRem, responsiveFontSizes } from './getFontValues';

const typography = {
  fontFamily: ["Poppins", "sans-serif"].join(","),
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontSize: 14,
  h1: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: pxToRem(24),
    // lineHeight: "116.7%",
    // letterSpacing: -1.5
    color: "#667085"
  },
  h2: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: pxToRem(48),
    lineHeight: "120%",
    letterSpacing: -0.5
  },
  h3: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: pxToRem(28),
    // lineHeight: "116.7%",
  },
  h4: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: pxToRem(24),
    lineHeight: "123.5%",
    letterSpacing: 0.25
  },
  h5: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: pxToRem(16),
    lineHeight: "133.4%",
    // ...responsiveFontSizes({ xs: 16, sm: 16, md: 20, lg: 24, xl: 24 }),
  },
  h6: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: pxToRem(16),
    lineHeight: "130%",
    letterSpacing: 0.15,
    ...responsiveFontSizes({ xs: 14, sm: 14, md: 14, lg: 16, xl: 16 }),
  },
  body1: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: "150%",
    letterSpacing: 0.15,
    ...responsiveFontSizes({ xs: 14, sm: 14, md: 16, lg: 16, xl: 16 }),
  },
  body2: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: "143%",
    letterSpacing: 0.17,
    ...responsiveFontSizes({ xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }),
  },
  subtitle1: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: "175%",
    letterSpacing: 0.15,
  },
  subtitle2: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: pxToRem(14),
    lineHeight: "137%",
    letterSpacing: 0.1,
  },
  subtitle3: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: "normal",
    // fontFamily: ["Poppins", "sans-serif"].join(","),
    // fontStyle: "normal",
    // fontWeight: 600,
    // fontSize: pxToRem(14),
    // lineHeight: "137%",
    // letterSpacing: 0.1,
  },
  overline: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: "266%",
    letterSpacing: 1,
    textTransform: "uppercase"
  },
  caption: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: "166%",
    letterSpacing: 1,
    textTransform: "uppercase"
  },
  tableHeader: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: pxToRem(12),
    lineHeight: "18px",
  },
  alertTitle: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: pxToRem(16),
    lineHeight: "130%",
    letterSpacing: 0.15,
  },
  alertTitle2: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontStyle: "normal",
    fontWeight: 500,
    ...responsiveFontSizes({ xs: 14, sm: 14, md: 14, lg: 16, xl: 16 }),
    lineHeight: "130%",
    letterSpacing: 0.15,
  },
  button: {
    // textTransform: 'none', // Custom text transformation for buttons
  },
} as const;

export default typography;