import { Box, Divider, Grid, Typography, Button } from '@mui/material';
import BottomBtn from 'Latest/2ndTierKyc/bottomBtn';
import { useFormik } from 'formik';
import DynamicQuestionAnswers from './dynamicQuestion';
import React, { useEffect } from 'react';
import { QuestionAnswerCategoryEnum } from '../../../../constants';
import { onGetUserQuestionAnswers } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useHistory } from 'react-router';
import { useAppLoading } from 'hooks/appLoading';
import FormikBooleanButtonsField from 'jsx/components/Shared/V1/FormikBooleanBtnFieldBoxV1';
import FormikInputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';

const DeclarationFinancial = ({ colors, formik, handleModalSubmitAPIBtn, handleNext, handlePrevious, mdScreenDown, setIsRequestNumOpenModal }: any) => {
  const dispatch = useAppDispatch();
  const { kycDecQuestion } = useAppSelector((state: any) => state.questionAnswer);
  const { push } = useHistory();
  const loading = useAppLoading('onPostSecondTier');
  const { userAccountDetails } = useAppSelector((state: any) => state.auth);


  const handleSaveExit = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      handleModalSubmitAPIBtn(true);
    } else {
      push('myprofile?scroll=true');
    }
  };
  const Options = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];
  return (
    <>
      {mdScreenDown && <Grid item xs={12} sx={{ mb: '16px' }} >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', pb: '16px', }}>
          <Typography variant='h6' color={colors.grey[800]}>Upgrade account</Typography>
          <Typography variant='h6' sx={{ color: "#443087", cursor: 'pointer' }} onClick={handleSaveExit}>Save & Exit</Typography>
        </Box>
        <Box sx={{ borderBottom: "1px solid #EAECF0", mx: { xs: '-16px', sm: "-30px" } }} />
      </Grid>}
      <Grid item md={12}>

        <Typography color={'#202020!important'} sx={{ typography: { xs: 'h5', md: 'h1' }, pb: { xs: "30px", md: '12px' } }}>Declarations</Typography>

        <FormikBooleanButtonsField
          required={false}
          singleLine={true}
          col={12}
          fieldName={'isRefusedByInstitutionToOpenAccount'}
          fields={Options}
          formik={formik}
          label={`Have you ever been refused by a financial institution to open an account? Applicable both in Pakistan and abroad.`}
        />

      </Grid>

      {formik?.values?.isRefusedByInstitutionToOpenAccount == 'Yes' && <Grid mt={3}>
        <FormikInputField
          required={false}
          formik={formik}
          name={'RefusalReason'}
          label='Please mention details of the refusal:'
          maxLength={100}
          placeholder='Enter description'
          type='textArea'
          rows={5}
          col={12}
        />
      </Grid>}

      <Box sx={{ pt: { xs: '8px', md: '8px' } }}>
        <BottomBtn
          handleSaveExit={handleSaveExit}
          handlePrevious={handlePrevious}
          handleNext={() => formik.handleSubmit()}
          nextBtnTitle={'Submit'}
          showBtnSaveExist={!mdScreenDown}
          styleBoxBtn={{ width: '100%', justifyContent: mdScreenDown ? "space-between" : "flex-end", }}
        />
      </Box>

    </>
  );
};

export default DeclarationFinancial;
