import React from 'react'
import LoadingBtnCustom from './LoadingBtnCustom'
import LoadingBtnCustomBack from './LoadingBtnCustomBack'
import { Box } from '@mui/material'
import { useAppSelector } from 'hooks/reduxHooks'


const ButtonRowV1 = ({ backBtnStyle, btnTitleBack, btnTitleNext, checkFreezAcc, containerStyles, disableNext, handleBack, handleNext, isShowBack = true, isShowNext = true, loadingBackBtn, loadingNextBtn, nextBtnStyle }: any) => {


  return (
    <>
      <Box sx={{ display: "flex", width: "100%", alignItems: "center", flexDirection: "row", gap: { xs: "8px", md: "8px" }, justifyContent: { xs: "space-between", md: "flex-end" }, ...containerStyles }}>
        {isShowBack && <LoadingBtnCustomBack handleOnClick={handleBack} btnTitle={btnTitleBack} loading={loadingBackBtn} style={backBtnStyle}
        //  style={{ order: { xs: 2, md: 1 } }}
        />}
        {isShowNext && <LoadingBtnCustom handleOnClick={handleNext} btnTitle={btnTitleNext} loading={loadingNextBtn} disabled={disableNext} style={nextBtnStyle} checkFreezAcc={checkFreezAcc}
        // style={{ order: { xs: 1, md: 2 } }}
        />}
      </Box>
    </>
  )
}

export default ButtonRowV1