import { Box, Divider, Grid, Typography, Button } from '@mui/material';
import BottomBtn from 'Latest/2ndTierKyc/bottomBtn';
import { useFormik } from 'formik';
import DynamicQuestionAnswers from './dynamicQuestion';
import React, { useEffect } from 'react';
import { QuestionAnswerCategoryEnum } from '../../../../constants';
import { onGetUserQuestionAnswers } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useHistory } from 'react-router';
import { useAppLoading } from 'hooks/appLoading';
import PhoneQuestions from './PhoneQuestion';

const Index = ({ colors, formik, handleModalSubmitAPIBtn, handleNext, handlePrevious, mdScreenDown, setIsRequestNumOpenModal }: any) => {
  const dispatch = useAppDispatch();
  const { kycDecQuestion } = useAppSelector((state: any) => state.questionAnswer);
  const { push } = useHistory();
  const loading1 = useAppLoading('onOwnBySelf2ndTier');
  const loading2 = useAppLoading('onNotOwnBySelf2ndTier');
  const loading = loading1 || loading2;

  const { userAccountDetails } = useAppSelector((state: any) => state.auth);

  let nestedQuestion_1 = {};
  const addNestedQuestion = (questionAnswer: any) => {
    questionAnswer?.answer?.answerOptions?.map((item: any, index: number) => {
      const questionId = questionAnswer?.id;
      if (item?.value === formik?.values[questionId]) {
        if (item?.answer) {
          nestedQuestion_1 = { item: item?.answer, activeStep: questionId };
        } else {
          nestedQuestion_1 = {};
          if (formik.values[`${questionId}-nested`] || formik.values[`${questionId}-nested`] === '') {
            formik.setFieldValue(`${questionId}-nested`, undefined);
          }
        }
      }
    });
  };

  const handleSaveExit = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      handleModalSubmitAPIBtn(true);
    } else {
      push('myprofile?scroll=true');
    }
  };

  return (
    <>
      {mdScreenDown && <Grid item xs={12} sx={{ mb: '16px' }} >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', pb: '16px', }}>
          <Typography variant='h6' color={colors.grey[800]}>Upgrade account</Typography>
          <Typography variant='h6' sx={{ color: "#443087", cursor: 'pointer' }} onClick={handleSaveExit}>Save & Exit</Typography>
        </Box>
        <Box sx={{ borderBottom: "1px solid #EAECF0", mx: { xs: '-16px', sm: "-30px" } }} />
      </Grid>}
      <Grid item md={12}>
        <Typography color={'#202020!important'} sx={{ typography: { xs: 'h5', md: 'h1' }, }}>Declarations</Typography>
        <PhoneQuestions formik={formik} setIsRequestNumOpenModal={setIsRequestNumOpenModal} />
      </Grid>


      <BottomBtn
        handleSaveExit={handleSaveExit}
        handlePrevious={handlePrevious}
        handleNext={() => formik.handleSubmit()}
        showBtnSaveExist={!mdScreenDown}
        styleBoxBtn={{ width: '100%', justifyContent: mdScreenDown ? "space-between" : "flex-end", }}
        loading={loading}
      />


    </>
  );
};

export default Index;
