import { Box, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import React, { useEffect, useState } from 'react'
import { onGetAccountSetup, onGetAllUserQuestionAnswers, onGetUserQuestionAnswers, onPutUserAnswers } from 'redux/actions';
import EditIcon from '@mui/icons-material/Edit';
import { linkSpan } from 'Latest/Style/Main';
import { links } from 'static/links';
import { Link } from 'react-router-dom';
import UpdateAddress from 'Latest/MyProfile/UpdateAddress';
import UpdateEmail from 'Latest/MyProfile/UpdateEmail';

import { QuestionAnswerCategoryEnum, userUpdateRequestStatus, UserUpdateRequestType, VerificationMethod } from '../../../constants';
import { useFormik } from 'formik';
import { accountSetupFormValidation, motherNameValidation } from 'validate/Screen/AccountSetupFormValidation';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { useAppLoading } from 'hooks/appLoading';
import { cnicViewStyle } from "Latest/MyProfileV1/Style/style";
import { onGetUserUpdateRequest } from 'redux/reducers/sharedStateData';
import { toast } from 'react-toastify';
import { handleChangePhEmModal, handleUserPhEmVerifyModalSpec } from 'redux/reducers/userPhoneEmailVerification';

const PersonalDetailContainer = () => {
    const { user, userAccountDetails, } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const { kycDecQuestion, pendingKYCQuestion } = useAppSelector(state => state.questionAnswer);
    const [openUpdateModal, setOpenUpdateModal] = useState(false)
    const [openGenericUpdateModal, setOpenGenericUpdateModal] = useState(false)
    const [compType, setcompType] = useState('')
    const [showMotherNameField, setShowMotherNameField] = useState(false)
    const { userUpdateRequest } = useAppSelector(state => state.sharedData);

    const { modalSpec, openModalInitPhEm } = useAppSelector(state => state.userPhoneEmailVerifyModal)

    const loading = useAppLoading('questionAnswer');

    const handleGenericOpenModal = () => dispatch(handleChangePhEmModal(true))
    const handleGenericCloseModal = () => dispatch(handleChangePhEmModal(false))

    useEffect(() => {
        dispatch(onGetAccountSetup());
        dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.Kyc))
        dispatch(onGetAllUserQuestionAnswers(QuestionAnswerCategoryEnum.Kyc));
        dispatch(onGetUserUpdateRequest())
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            motherName: userAccountDetails?.motherName,
        },
        onSubmit: async (values: any) => {
            onSubmit();
        },
        validationSchema: motherNameValidation
    });

    const onSubmit = () => {
        setShowMotherNameField(false);
        const body = {
            name: userAccountDetails?.identityCard?.name,
            fatherOrHusbandName: userAccountDetails?.identityCard?.fatherName,
            motherName: formik.values?.motherName,
            dateOfBirth: userAccountDetails?.identityCard?.dateOfBirth,
            identityCardNumber: userAccountDetails?.identityCard?.identityCardNumber,
            identityCardIssuanceDate: userAccountDetails?.identityCard?.dateOfIssue,
            identityCardExpiryDate: userAccountDetails?.identityCard?.dateOfExpiry,
            gender: userAccountDetails?.identityCard?.gender,
            countryOfStay: userAccountDetails?.identityCard?.countryOfStay,
            identityCardLifeTimeExpiry: userAccountDetails?.identityCard?.lifeTimeExpiry,
            ukn: userAccountDetails?.identityCard?.ukn,
            rememberUKN: userAccountDetails?.rememberUKN,
            iban: userAccountDetails?.iban,
            accountType: userAccountDetails?.accountType,
        };

        const onSuccess = () => {
            // toast.success(`Profile updated successfully`, { autoClose: 8000, toastId: 'success1', });
            dispatch(onGetAccountSetup());
            //   MixPannelTracker({ eventName: mixPannelTrackerEventName.cnic_info_submitted, email })
        }

        dispatch(onPutUserAnswers(body, onSuccess));
    };

    const showEditAddressIcon = (userAccountDetails?.mailingAddress == null || userAccountDetails?.permanentAddress == null) && userAccountDetails?.isAccountSetupComplete

    const editIconJSX = () => {
        return (
            showEditAddressIcon &&
            <Tooltip title="Update Address" arrow={true} open={!openUpdateModal}>
                <IconButton color="primary" aria-label="edit" component="label" onClick={() => setOpenUpdateModal(true)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
        )
    }

    const GenericEditIconJSX = ({ onClick }: any) => (
        <IconButton color="primary" aria-label="edit" component="label" onClick={onClick}>
            <EditIcon />
        </IconButton>
    );

    const handleGetKycQuestion = (id: string) => {
        const getQuestion = kycDecQuestion?.questionAnswer?.find((item: any) => item?.id == id)
        return getQuestion?.question || "-"
    }

    const handleGetKycNestedQuestion = (id: string, value: string) => {
        const getQuestion = kycDecQuestion?.questionAnswer?.find((item: any) => item?.id == id)
        const filterNestedQuestion = getQuestion?.answer?.answerOptions?.filter((i: any) => i?.value == value)
        return filterNestedQuestion?.length > 0 && filterNestedQuestion[0]?.answer?.question
    }

    const createKYCQuestionArr = pendingKYCQuestion?.map((item: any) => ({
        quest: handleGetKycQuestion(item?.id),
        answer: item?.answer?.answerOptions[0]?.value,
        nested: (item?.answer?.answerOptions[0]?.answer?.answerOptions?.length > 0) ? {
            quest: handleGetKycNestedQuestion(item?.id, item?.answer?.answerOptions[0]?.value),
            answer: item?.answer?.answerOptions[0]?.answer?.answerOptions[0]?.value,
        } : null,
    }));

    const upgradeStatusEnum: any = userAccountDetails?.kyc?.status == "ApprovalSucceeded";

    const checkPhoneUpdateStatus = userUpdateRequest?.some((x) =>
        x.requestType == UserUpdateRequestType.MobileNumberUpdate
        &&
        (x.requestStatus == userUpdateRequestStatus.InProgressAdmin || x.requestStatus == userUpdateRequestStatus.InProgressThirdParty))

    const handleUpdateMobileNum = () => {
        if (checkPhoneUpdateStatus) {
            toast.error("Phone update in progress. Changes not allowed.", { theme: "colored" });
        } else {
            // setcompType(VerificationMethod.phoneNumber);
            // setOpenGenericUpdateModal(true);
            dispatch(handleUserPhEmVerifyModalSpec(VerificationMethod.phoneNumber));
            handleGenericOpenModal(); 
        }
    }

    return (
        <>

            <UpdateAddress handleOpen={() => setOpenUpdateModal(true)} open={openUpdateModal} handleClose={() => setOpenUpdateModal(false)} />
            <UpdateEmail handleOpen={() => handleGenericOpenModal()} open={openModalInitPhEm} handleClose={() => handleGenericCloseModal()} compType={modalSpec} />

            <Grid item xs={12} md={12}  >
                <Grid item md={12} xs={12} >
                    <Typography variant='h4' sx={{ ...cnicViewStyle.title }}>Your Details</Typography>
                </Grid>


                <Grid item md={12} xs={12} >
                    <Typography variant='body2'> If these details are incorrect or you wish to update, please contact <Link to={links.help} style={linkSpan}>Customer Support</Link> to correct them.</Typography>
                </Grid>

                <Grid item md={12} xs={12} sx={{ mt: "30px" }}>
                    <Grid container spacing={"20px"}>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'> Name </Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {userAccountDetails?.identityCard?.name || "-"} </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'>   {userAccountDetails?.identityCard?.gender == "Female" ? "Father's / Husband's name" : "Father's name"} </Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {userAccountDetails?.identityCard?.fatherName || "-"} </Typography>
                        </Grid>

                        {/* <Grid item md={6} xs={12}>
                            <Typography variant='body2' sx={{ mb: "4px", justifyContent: 'flex-start', display: 'flex' }}>
                                Mother maiden name
                                {!userAccountDetails?.motherName && <div onClick={() => setShowMotherNameField(!showMotherNameField)}>
                                    <EditIcon sx={{ cursor: 'pointer', fontSize: "18px", color: "rgba(0, 0, 0, 0.87)", ml: 2 }} />
                                </div>}
                            </Typography>
                            {showMotherNameField ? <InputField
                                required
                                // maxLength={120}
                                formik={formik}
                                name='motherName'
                                placeholder="Enter mother maiden name"
                                type="text"
                            /> :
                                <Typography variant='h6'>{userAccountDetails?.motherName || formik?.values.motherName || "-"}</Typography>}
                        </Grid> */}

                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'>Date of Birth</Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}>{userAccountDetails?.identityCard?.dateOfBirth} </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} >
                            <Typography variant='body2' sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                Email Address
                                <GenericEditIconJSX onClick={() => { dispatch(handleUserPhEmVerifyModalSpec(VerificationMethod.email)); handleGenericOpenModal(); }} /></Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {userAccountDetails?.email || "-"} </Typography>
                        </Grid>

                    </Grid>
                </Grid>

                <Divider sx={{ my: "24px", borderColor: "#EAECF0" }} />

                <Grid item md={12} xs={12}>
                    <Grid container spacing={"20px"}>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2' sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                Mobile number
                                <GenericEditIconJSX onClick={() => { handleUpdateMobileNum() }} /></Typography>
                            {/* <GenericEditIconJSX onClick={() => { dispatch(handleUserPhEmVerifyModalSpec(VerificationMethod.phoneNumber)); handleGenericOpenModal(); }} /></Typography> */}

                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word", display: 'flex', alignItems: 'center', gap: '8px' }}> {userAccountDetails?.contactNumber || "-"}
                                {checkPhoneUpdateStatus &&
                                    <Box sx={{ background: '#FFD6A4', px: '8px', borderRadius: '8px' }}>
                                        <Typography variant='body2' sx={{ color: '#5E4200', textAlign: 'center' }}>In progress</Typography>
                                    </Box>
                                }</Typography>

                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'>CNIC Number</Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}>{userAccountDetails?.identityCard?.identityCardNumber || "-"}</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'> Date of CNIC Issuance </Typography>
                            <Typography variant='h6' sx={{ mt: "4px" }}> {userAccountDetails?.identityCard?.dateOfIssue || "-"} </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'> Date of CNIC Expiry </Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {userAccountDetails?.identityCard?.lifeTimeExpiry ?
                                "Life time" :
                                userAccountDetails?.identityCard?.dateOfExpiry} </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'> Gender </Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {userAccountDetails?.identityCard?.gender || "-"} </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2'> Country of stay </Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}> {userAccountDetails?.identityCard?.countryOfStay || "-"} </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2' sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                Address
                                {editIconJSX()}</Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}>
                                {userAccountDetails?.permanentAddress?.lines.map((i: any) => {
                                    return (<>{i}&nbsp;</>)
                                })}
                                {userAccountDetails?.permanentAddress?.city},&nbsp;
                                {userAccountDetails?.permanentAddress?.province} </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Typography variant='body2' sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                Mailing address
                                {editIconJSX()} </Typography>
                            <Typography variant='h6' sx={{ mt: "4px", wordBreak: "break-word" }}>
                                {userAccountDetails?.mailingAddress?.lines.map((i: any) => {
                                    return (<>{i}&nbsp;</>)
                                })}
                                {userAccountDetails?.mailingAddress?.city},&nbsp;
                                {userAccountDetails?.mailingAddress?.province} </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider sx={{ my: "24px", borderColor: "#EAECF0" }} />

                <Grid item md={12} xs={12}>
                    <Grid container spacing={"20px"}>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body2'> Bank Name  </Typography>
                            <Typography variant='h6' sx={{ mt: "4px" }}> {userAccountDetails?.bankName || "-"} </Typography>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Typography variant='body2'>Account number/IBAN </Typography>
                            <Typography variant='h6' sx={{ mt: "4px" }}> {userAccountDetails?.iban || "-"} </Typography>
                        </Grid>

                        {(!userAccountDetails?.motherName && formik?.values.motherName) && <Grid
                            item
                            md={12}
                            xs={12}
                            display={'flex'}
                            gap={2}
                            justifyContent={'end'}

                        >
                            <LoadingButton
                                loading={loading}
                                color={'secondary'}
                                variant='contained'
                                disabled={
                                    JSON.stringify(formik.values) ===
                                        JSON.stringify(formik.initialValues)
                                        ? true
                                        : false
                                }
                                onClick={() => formik.submitForm()}
                            >
                                Save
                            </LoadingButton>
                        </Grid>}
                    </Grid>


                </Grid>

                {upgradeStatusEnum &&
                    <>
                        <Divider sx={{ my: "24px", borderColor: "#EAECF0" }} />

                        <Grid item md={12} xs={12}>
                            <Grid container spacing={"20px"}>

                                {createKYCQuestionArr?.map((item: any) => {
                                    return (
                                        <>
                                            <Grid key={item} item md={6} xs={12} >
                                                <Typography variant='body2'>{item?.quest} </Typography>
                                                <Typography variant='h6' sx={{ mt: "4px" }}> {item?.answer || "-"} </Typography>
                                            </Grid>
                                            {item?.nested && <Grid key={item} item md={6} xs={12} >
                                                <Typography variant='body2'> {item?.nested?.quest}</Typography>
                                                <Typography variant='h6' sx={{ mt: "4px" }}> {item?.nested?.answer || "-"} </Typography>
                                            </Grid>}
                                        </>
                                    )
                                })}

                            </Grid>
                        </Grid>
                    </>
                }

            </Grid >

        </>
    )
}

export default PersonalDetailContainer