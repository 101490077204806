import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Box, Button, Divider, Grid } from '@mui/material'
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import LoadingBtnCustomBack from 'Latest/ComponentV1/LoadingBtnCustomBack';
import React from 'react'


const BottomBtn = ({ handleNext, handlePrevious, handleSaveExit, loading = false, nextBtnTitle = "Continue", showBackBtn = true, showBtnSaveExist = true, styleBoxBtn, styleMainBox }: any) => {
    const classes = useStyles();
    return (
        <Grid item xs={12} md={12} >

            <Box sx={{ display: "flex", justifyContent: "space-between", mt: "24px", ...styleMainBox }}>

                {showBtnSaveExist && <Box sx={{ width: '100%' }}>
                    {handleSaveExit && <LoadingBtnCustomBack handleOnClick={handleSaveExit} btnTitle={"Save & Exit"} loading={loading} />}
                </Box>
                }

                <Box sx={{ display: "flex", gap: "8px", ...styleBoxBtn }}>
                    {showBackBtn && <LoadingBtnCustomBack handleOnClick={handlePrevious} btnTitle={"Back"} loading={loading} />}
                    <LoadingBtnCustom handleOnClick={handleNext} btnTitle={nextBtnTitle} loading={loading} disabled={loading} checkFreezAcc={true} />
                </Box>
                
            </Box>

        </Grid>
    )
}

export default BottomBtn