/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable sort-keys */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { Box, CircularProgress, Container, debounce, Grid, Link, Stack, Typography } from '@mui/material'
import { NewCFIGraphStyles } from 'Latest/Goal/style'
import { Slider } from '@mui/material'
import { sliderStyle } from './style'
import { useAppSelector } from 'hooks/reduxHooks'
import { Goals, bankSavingsRate, intialInvestmentMaxLimit, mahaanaSaveRate, mixPannelTrackerEventName } from '../../../constants'
import { CurrencyFormatterWithDecimal, kFormatterV1 } from 'utils/calculation'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'jsx/components/Shared/Loader'
import upArrow from 'Latest/Images/upArrow.png'
import downArrow from 'Latest/Images/downArrow.png'
import Big from 'big.js';
import { monthlySavingReturnsCalculation } from 'utils/monthlySavingReturnsCalculation'
import CustomCFBarChart from 'Latest/ChartV1/customCFBarChart'
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1'
import FormikInputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import { VPSGoal_Errors } from 'validate/VPSGoal'
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style'
import { toast } from 'react-toastify'
import { useAppLoading } from 'hooks/appLoading'

import { useIsOnline } from 'react-use-is-online';
import { isNullObj } from '../../../utils/nullChecker'
import { HandleFormulaForCalcTaxSaving } from 'utils/taxSavingCalculator'
import warningIcon from 'Latest/Images/svg/WarningDiamond.svg'


const RetirementGraph = ({
    RedirectLink,
    annualTaxableIncome,
    formik,
    handleAPIsOnStart,
    handleInterestCompute,
    initialAmount,
    isEmployer,
    loading,
    monthlySaving,
    nextStep,
    planType,
    previousStep,
    questionAnswers,
    retirementAmount,
    setLoading,
    submitGoal,
    travelPlan
}: Props) => {

    const { isOnline } = useIsOnline();

    const loadingpayment = useAppLoading('payment');
    const loadingpaymentquestionAnswer = useAppLoading('questionAnswer');
    const loadingpaymentsharedStateData = useAppLoading('sharedStateData');

    const loadinApi = loadingpayment || loadingpaymentquestionAnswer || loadingpaymentsharedStateData
    const { investGoalConfig, investPortfolioAlloc } = useAppSelector(state => state.sharedData)

    // const { cashFundSummary } = useAppSelector(state => state.sharedData);

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loadingLayout, setLoadingLayout] = useState(true)
    const [mainLoadingState, setMainLoadingState] = useState(true)
    const { user: { email } } = useAppSelector((state: any) => state.auth);
    const [barData, setBarData] = useState<any>()
    const [retirementAge, setRetirementAge] = useState(formik.values.desired_retirement_age);
    const [maxFutureValue, setMaxFutureValue] = useState<number>(100000);
    const travelTimeCounterMax = 1200;
    const travelTimeCounterMin = 1;
    const goalCounterMax = 100;


    const [count, setCount] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setMainLoadingState(false)
        }, 1000);
    }, [])

    useEffect(() => {
        setLoadingLayout(true)
        debouncedDrawGraph(retirementAge)
    }, [monthlySaving, initialAmount, retirementAge, annualTaxableIncome, investGoalConfig])

    const calculateNumberOfPayments = (retirementAge: number) => {
        let numberOfPayments;
        numberOfPayments = retirementAge * 12;
        if (numberOfPayments < 0) {
            numberOfPayments = 0;
        }
        formik.setFieldValue('desired_retirement_age', retirementAge);
        return numberOfPayments;
    };

    const debouncedDrawGraph = useCallback(
        debounce(
            (retirementAgeValue) =>
                HandleFormulae(retirementAgeValue, Number(monthlySaving), Number(initialAmount), Number(annualTaxableIncome)),
            500
        ),
        [monthlySaving, initialAmount, retirementAge, investGoalConfig, annualTaxableIncome]
    );

    const futureAmtPlustaxSaving = Number(formik.values.future_values) + Number(formik.values.taxSaving)

    const HandleFormulae = (retirementAgeValue: number, monthlySavingValue: number, initialAmountValue: number, annualTaxableIncome: number) => {

        const numberOfPayments = calculateNumberOfPayments(retirementAgeValue);
        formik.setFieldValue('number_of_payments', numberOfPayments);

        const total = new Big(setGraphDataFunc(numberOfPayments, monthlySavingValue, initialAmountValue, retirementAgeValue, annualTaxableIncome));
        const totalMax = total
            .plus(total.mul(new Big(Math.random() * 0.1).add(Math.random() * 0.15)))
            .div(1000)
            .round()
            .mul(1000)
            .toNumber();

        console.log(total, "totalMax")
        setMaxFutureValue(Math.ceil(Number(totalMax)));
        formik.setFieldValue('future_values', Number(total.toFixed(2, 0)));

    };

    const setGraphDataFunc = (numberOfPayments: number, monthlySavingValue: number, initRange: number, retirementAgeValue: number, annualTaxableIncome: number) => {

        const riskLevels: any = investGoalConfig?.investReturnMargins

        // Extract key-value pairs from the object
        const entries = Object.entries(riskLevels);

        // Extract numeric values and corresponding keys
        const numericEntries = entries?.filter(([key, value]) => typeof value === 'number');

        // Sort the numeric values and corresponding keys
        const sortedNumericEntries = numericEntries.sort((a: any, b: any) => a[1] - b[1]);

        // Find minimum value and key
        const [minKey, minValue] = numericEntries?.reduce((min: any, entry: any) => (entry[1] < min[1] ? entry : min), ["", Infinity]);

        // Find the mid value and key
        const midIndex = Math.floor(sortedNumericEntries.length / 2);
        const [midKey, midValue]: any = sortedNumericEntries[midIndex];

        // Find maximum value and key
        const [maxKey, maxValue] = numericEntries?.reduce((max: any, entry: any) => (entry[1] > max[1] ? entry : max), ["", -Infinity]);

        const profitPercentConservative = new Big(minValue / 100)
        const profitPercentModerate = new Big(midValue / 100)
        const profitPercentAggressive = new Big(maxValue / 100)

        const future_value = new Big(formik.values.future_values || 0);
        const credit = HandleFormulaForCalcTaxSaving(retirementAgeValue, monthlySavingValue, annualTaxableIncome, initRange)

        if (Number(credit) == 0) {
            formik.setFieldValue('taxSaving', 0);
            formik.setFieldValue('futureAmtPlustaxSaving', Number(future_value));
        } else {
            const futureAmtPlustaxSaving = credit.plus(future_value)
            formik.setFieldValue('taxSaving', Number(credit));
            formik.setFieldValue('futureAmtPlustaxSaving', Number(futureAmtPlustaxSaving));
        }

        const data: any = [];
        const newData: any = [];

        const exportedData: any = [];

        let months = numberOfPayments;
        if (months < 0) { months = 0; }

        let startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const initValue = new Big(initRange)

        let my_amount = initValue;

        // let bank_amount = new Big(monthlySavingReturnsCalculation(initValue || 0, undefined, bankSavingsRate));
        // let mahaana_cash = new Big(monthlySavingReturnsCalculation(initValue || 0, undefined, mahaanaSaveRate));

        let l_amount = new Big(monthlySavingReturnsCalculation(new Big(initValue) || 0, undefined, profitPercentConservative));
        let m_amount = new Big(monthlySavingReturnsCalculation(new Big(initValue) || 0, undefined, profitPercentModerate));
        let h_amount = new Big(monthlySavingReturnsCalculation(new Big(initValue) || 0, undefined, profitPercentAggressive));
        // data.push(
        //     {
        //         name: `${startDate.getFullYear()}`,
        //         Bank: (new Big(l_amount).toFixed(2, 0)),
        //         value: (new Big(l_amount).toFixed(2, 0)),
        //         myContribution: (new Big(my_amount).toFixed(2, 0)),
        //         type: 'bank'
        //     },
        //     {
        //         name: `${startDate.getFullYear()}`,
        //         MahannaCash: (new Big(m_amount).toFixed(2, 0)),
        //         value: (new Big(m_amount).toFixed(2, 0)),
        //         myContribution: (new Big(my_amount).toFixed(2, 0)),
        //         type: 'cash'
        //     },
        //     {
        //         name: `${startDate.getFullYear()}`,
        //         MahannaCash: (new Big(h_amount).toFixed(2, 0)),
        //         value: (new Big(h_amount).toFixed(2, 0)),
        //         myContribution: (new Big(my_amount).toFixed(2, 0)),
        //         type: 'grow'
        //     },
        // );

        // for (let i = 1; i < months; i++) {

        //     startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
        //     my_amount = my_amount.add(monthlySavingValue);

        //     // bank_amount = monthlySavingReturnsCalculation(bank_amount, monthlySavingValue, bankSavingsRate)
        //     // mahaana_cash = monthlySavingReturnsCalculation(mahaana_cash, monthlySavingValue, mahaanaSaveRate)

        //     l_amount = monthlySavingReturnsCalculation(l_amount, monthlySavingValue, profitPercentConservative)
        //     m_amount = monthlySavingReturnsCalculation(m_amount, monthlySavingValue, profitPercentModerate)
        //     h_amount = monthlySavingReturnsCalculation(h_amount, monthlySavingValue, profitPercentAggressive)

        //     data.push(
        //         {
        //             name: `Conservative`,
        //             creditreturn: (new Big(credit).toFixed(2, 0)),
        //             return: (new Big(l_amount.minus(my_amount)).toFixed(2, 0)),
        //             value: (new Big(l_amount).add(credit).toFixed(2, 0)).toLocaleString(),
        //             myContribution: new Big(my_amount).toFixed(2, 0),
        //             type: 'bank',
        //             year: startDate.getFullYear(),
        //         },
        //         {
        //             name: `Balanced`,
        //             creditreturn: (new Big(credit).toFixed(2, 0)),
        //             return: (new Big(m_amount.minus(my_amount)).toFixed(2, 0)),
        //             value: (new Big(m_amount).add(credit).toFixed(2, 0)).toLocaleString(),
        //             myContribution: new Big(my_amount).toFixed(2, 0),
        //             type: 'cash',
        //             year: startDate.getFullYear(),
        //         },
        //         {
        //             name: `Growth`,
        //             creditreturn: (new Big(credit).toFixed(2, 0)),
        //             return: (new Big(h_amount.minus(my_amount)).toFixed(2, 0)),
        //             value: (new Big(h_amount).add(credit).toFixed(2, 0)).toLocaleString(),
        //             myContribution: new Big(my_amount).toFixed(2, 0),
        //             type: 'grow',
        //             year: startDate.getFullYear(),
        //         },
        //     );
        // }


        for (let i = 1; i < months; i++) {
            startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);

            my_amount = my_amount.add(monthlySavingValue);
            l_amount = monthlySavingReturnsCalculation(l_amount, monthlySavingValue, profitPercentConservative)
            m_amount = monthlySavingReturnsCalculation(m_amount, monthlySavingValue, profitPercentModerate)
            h_amount = monthlySavingReturnsCalculation(h_amount, monthlySavingValue, profitPercentAggressive)

            newData.push(
                {
                    year: `${startDate.getFullYear()}`,

                    my_amount: Number(my_amount.toFixed(2, 0)),


                    Conservative: Number(l_amount.add(credit).toFixed(2, 0)),
                    Conservative_Return: Number(l_amount.sub(my_amount).toFixed(2, 0)),

                    Moderate: Number(m_amount.add(credit).toFixed(2, 0)),
                    Moderate_Return: Number(m_amount.sub(my_amount).toFixed(2, 0)),

                    Aggressive: Number(h_amount.add(credit).toFixed(2, 0)),
                    Aggressive_Return: Number(h_amount.sub(my_amount).toFixed(2, 0)),
                },
            );

            // console.log('Data: ', data);
        }

        const types = ["Conservative", "Moderate", "Aggressive"];
        const keys = [minKey, midKey, maxKey];
        const uniqueKeys = keys?.filter((value, index, self) => {
            return self?.indexOf(value) === index;
        });
        const customizeManulpulatedData: any = [];
        for (let i = 0; i < uniqueKeys?.length; i++) {
            const investPortfolioAllocData = investPortfolioAlloc?.find((x: any, index: number) => {
                return x?.profileName === keys[i];
            });
            const obj = {
                name: keys[i],
                creditreturn: (new Big(credit).toFixed(2, 0)),
                return: newData[newData.length - 1][types[i] + '_Return'],
                value: newData[newData.length - 1][types[i]],
                myContribution: newData[newData.length - 1]?.my_amount,
                type: types[i],
                year: startDate.getFullYear(),
                investPortfolioAllocData: investPortfolioAllocData
            };
            customizeManulpulatedData.push(obj);
        }

        //     data.push(
        //         {
        //             name: `Conservative`,
        //             creditreturn: (new Big(credit).toFixed(2, 0)),
        //             return: (new Big(l_amount.minus(my_amount)).toFixed(2, 0)),
        //             value: (new Big(l_amount).add(credit).toFixed(2, 0)).toLocaleString(),
        //             myContribution: new Big(my_amount).toFixed(2, 0),
        //             type: 'bank',
        //             year: startDate.getFullYear(),
        //         },

        // exportedData.push(data[data.length - 3])
        // exportedData.push(data[data.length - 2])
        // exportedData.push(data[data.length - 1])
        // setBarData(exportedData)
        setBarData(customizeManulpulatedData)
        setLoadingLayout(false)
        return h_amount;
    };

    const yearCounterValidationSchema = (param: number) => {
        if (formik?.initialValues?.goalId > 0) {
            if (formik?.initialValues?.goalId === Goals.TravelTime) {
                return param < travelTimeCounterMin
                    ? travelTimeCounterMin
                    : param > travelTimeCounterMax
                        ? travelTimeCounterMax
                        : param;
            } else {
                return param <= 0 ? 1 : param > goalCounterMax ? goalCounterMax : param;
            }
        } else {
            return param < formik?.values?.userAge + 1
                ? formik?.values?.userAge + 1
                : param > goalCounterMax
                    ? goalCounterMax
                    : param;
        }
    };

    const handleChangeRetirementAge = (e: any) => {
        setRetirementAge(
            yearCounterValidationSchema(Number(e.target.value))
        )
    }

    useEffect(() => {  // for internet check
        if (count !== 0 && questionAnswers?.length /*&& !isNullObj(cashFundSummary)*/) {
            submitGoal();
            nextStep()
            // MixPannelTracker({ eventName: mixPannelTrackerEventName.bank_vs_save_plus_experimented, email })
        }

    }, [questionAnswers /*, cashFundSummary */])

    const handleNext = async () => {
        formik.setFieldTouched('annual_taxable_income', true);
        if (errorCustomObj.initial_amount[formik.errors.initial_amount] || errorCustomObj.monthly_saving[formik.errors.monthly_saving] || errorCustomObj.annual_taxable_income[formik.errors.annual_taxable_income]) {
            return
        }

        //old
        // submitGoal();
        // nextStep()
        // MixPannelTracker({ eventName: mixPannelTrackerEventName.bank_vs_save_plus_experimented, email }) 


        if (isOnline) { // for internet check

            if (questionAnswers?.length /*&& !isNullObj(cashFundSummary)*/) {
                submitGoal();
                nextStep()
                // MixPannelTracker({ eventName: mixPannelTrackerEventName.bank_vs_save_plus_experimented, email })
            } else {
                setCount(count + 1)
                handleAPIsOnStart()
            }

        } else {
            toast.error('Internet is required to proceed', { theme: "colored" });
        }

    }

    const errorCustomObj: any = {
        "initial_amount": {
            [VPSGoal_Errors.AMOUNT_REQUIRED]: true,
            [VPSGoal_Errors.MIN_INTIAL_AMT_CF]: true
        },
        "monthly_saving": {
            [VPSGoal_Errors.AMOUNT_REQUIRED]: true,
        },
        "annual_taxable_income": {
            [VPSGoal_Errors.AMOUNT_REQUIRED]: true,
        }
    }

    const buttonHtml = () => {
        return (
            <ButtonRowV1 handleNext={handleNext} handleBack={previousStep} loadingNextBtn={loadingLayout || loadinApi} btnTitleNext="Continue" />
        )
    }

    const topContentHtml = () => {
        return (
            <>
                <Box sx={{ mb: { xs: "8px", md: "16px" }, gap: '8px', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='h1' color={"#1D2939"} textAlign={"center"} >Plan your retirement</Typography>
                    {smScreen &&
                        <Typography variant='body2' color={"rgba(29, 41, 57, 0.70)"} textAlign={"center"} >
                            Contributions can be made at any time. However keeping them monthly can help you achieve your retirement faster.
                        </Typography>}
                </Box>

            </>
        )
    }


    const keyDetail = barData?.map((p: any) => {
        // Get the profile name
        const profile = p?.investPortfolioAllocData
        const profileName = profile?.profileName;
        // Get the asset details

        // const assets = profile?.assetsType?.map((asset: any) => {
        //     console.log(asset ,"asset")
        //     // Format the assets and their percentages
        //     // const formattedAssetsProd = asset?.products?.map((product: any) => `${product?.value1}% ${product?.key}`)?.join(", ");

        //     // return `${profileName}: ${formattedAssetsProd}`;

        // });

        const assets = profile?.assetsType
            ?.map((asset: any) => `${asset?.assetPercentage}% ${asset?.assetName}`)
            .join(", ");

        // Combine the profile name with the formatted assets
        const formattedProfileAssets = `${profileName}: ${assets}`;

        return formattedProfileAssets
        // return assets[0]; // Since there's only one assetType per profile
    });

    return (
        <>
            {/* =============revamp V2 */}
            {mainLoadingState && <Loader />}
            <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ py: { xs: "24px", md: "40px" } }}>
                <Grid container>
                    <Grid item md={12} xs={12}>
                        {smScreen && <Box sx={{ borderRadius: "8px", background: "#FFF", boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)", p: "16px", mb: "24px" }}>{topContentHtml()}</Box>}

                        <Box sx={{ ...mainContainerV2, gap: "0px", }}>

                            {!smScreen && <Box >{topContentHtml()}</Box>}

                            <Grid container sx={{ background: "#FFF", py: { xs: "0px", md: "16px" } }}>

                                <Grid item md={5.5} xs={12} sx={{ p: { xs: "16px 0px", md: "0px 26px 0px 0px" }, display: "flex", flexDirection: "column", justifyContent: "space-between", order: { xs: 2, md: 1 } }} >

                                    <Box sx={{ gap: "16px", display: "flex", flexDirection: "column" }}>
                                        <Box>
                                            <FormikInputField
                                                formik={formik}
                                                label={"Your initial investment"}
                                                maxLength={12}
                                                name={"initial_amount"}
                                                placeholder='Enter here...'
                                                thousandSeparator={true}
                                                type='number'
                                                inputEnd={"PKR"}
                                                showError={false}
                                            />
                                            {errorCustomObj.initial_amount[formik.errors.initial_amount] && <span className='text-danger' style={{ display: "flex" }}>{formik.errors.initial_amount}</span>}
                                        </Box>
                                        <Box>
                                            <FormikInputField
                                                formik={formik}
                                                label={<><span>Your monthly contribution</span><span className='ml-1 text-red'></span></>}
                                                maxLength={12}
                                                name={"monthly_saving"}
                                                placeholder='Enter here...'
                                                thousandSeparator={true}
                                                type='number'
                                                inputEnd={"PKR"}
                                                showError={false}
                                            />

                                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5.25px', pt: '8px', alignItem: 'center' }}>
                                                <img src={warningIcon} height={20} width={20} />
                                                <Typography sx={{ color: '#821B13' }} fontSize="14px!important">Monthly contributions are optional.</Typography>
                                            </Box>

                                            {errorCustomObj.monthly_saving[formik.errors.monthly_saving] && <span className='text-danger' style={{ display: "flex" }}>{formik.errors.monthly_saving}</span>}
                                        </Box>
                                        <Box>
                                            <FormikInputField
                                                formik={formik}
                                                label={"Annual taxable income"}
                                                maxLength={12}
                                                name={"annual_taxable_income"}
                                                placeholder='Enter here...'
                                                thousandSeparator={true}
                                                type='number'
                                                inputEnd={"PKR"}
                                            />
                                            {/* {errorCustomObj.monthly_saving[formik.errors.annual_taxable_income] && <span className='text-danger' style={{ display: "flex" }}>{formik.errors.annual_taxable_income}</span>} */}
                                        </Box>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <Typography variant={"body2"} sx={{ pb: '6px' }} >Duration of Retirement</Typography>
                                            <Box sx={{ display: "flex", alignItems: "end", flexWrap: { md: 'noWrap', sm: 'noWrap', xs: 'wrap' }, columnGap: "31px", rowGap: "8px" }}>
                                                <Box sx={{ p: 0, width: "100%", paddingTop: '35px' }}>
                                                    <Slider
                                                        value={retirementAge}
                                                        max={25}
                                                        min={1}
                                                        onChange={handleChangeRetirementAge}
                                                        sx={sliderStyle}
                                                        defaultValue={0}
                                                        valueLabelDisplay="auto"
                                                        size='medium'
                                                        valueLabelFormat={() => <div className='rangeLabel'>{retirementAge + " " + `${retirementAge > 1 ? 'Years' : 'Year'}`}</div>}
                                                    />
                                                </Box>

                                                <Grid item md={6} sm={4} xs={12} sx={[NewCFIGraphStyles.customSliderInputField,
                                                    //  { display: "flex", justifyContent: "center" }
                                                ]}>
                                                    <Typography sx={{ ...NewCFIGraphStyles.customSliderInput }}>{retirementAge + " " + `${retirementAge > 1 ? 'Years' : 'Year'}`}</Typography>
                                                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} >
                                                        <img src={upArrow} alt={'increase'} onClick={() => retirementAge < 25 ? setRetirementAge(retirementAge + 1) : {}} />
                                                        <img src={downArrow} alt={'decrease'} onClick={() => retirementAge > 1 ? setRetirementAge(retirementAge - 1) : {}} />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>

                                        <Box sx={{ display: 'flex', gap: '8px', flexDirection: { xs: "column", md: "row" } }} >
                                            <Grid item md={6} xs={12} sx={{ p: "16px", background: "#E8E2FD", borderRadius: '8px' }} >
                                                <Typography color="#432F87" variant="h3" textAlign={"center"} pb="8px">{kFormatterV1(Number(futureAmtPlustaxSaving))}</Typography>
                                                <Typography color="#432F87" variant="body2" textAlign={"center"}>Your total expected value at retirement</Typography>
                                            </Grid>
                                            <Grid item md={6} xs={12} sx={{ p: "16px", background: "#E8E2FD", borderRadius: '8px' }} >
                                                <Typography color="#432F87" variant="h3" textAlign={"center"} pb="8px">{kFormatterV1(Number(formik.values.taxSaving))}</Typography>
                                                <Typography color="#432F87" variant="body2" textAlign={"center"}>Your Total Tax Savings</Typography>
                                            </Grid>
                                        </Box>
                                    </Box>

                                </Grid>

                                <Grid item md={6.5} xs={12} sx={{ order: { xs: 1, md: 1 } }}  >
                                    {/* <Typography sx={{ p: "14px 0px", fontSize: { xs: "16px", md: "14px" }, fontWeight: 500, letterSpacing: "0.56px", textAlign: { md: "center" }, color: { xs: "#422E84", md: "#1D2939" } }}>
                                        PROJECTIONS 
                                    </Typography> */}
                                    {/* <Box sx={{pt:'19px'}} /> */}
                                    {/* {smScreen && <Box sx={{ mx: { xs: "-16px", md: "" }, borderBottom: "1px solid rgba(208, 213, 221, 0.25)", }} />} */}
                                    <Grid item md={12} sm={12} xs={12} sx={{ padding: { md: "0 0 0 16px", xs: "0 0 24px 0" } }}>

                                        {loadingLayout ?
                                            <LoadingBarGraph />
                                            :
                                            barData && <CustomCFBarChart
                                                LabelListDataKey={"value"}
                                                isVPS={true}
                                                LabelListShow={true}
                                                barData={barData}
                                                maxFutureValue={maxFutureValue}
                                                barDataKey1="myContribution"
                                                barDataKey2="return"
                                                barDataKey3="creditreturn"
                                                dataLegendKeyCustom={{
                                                    myContribution: "Contribution",
                                                    return: "Profit",
                                                    creditreturn: "Tax Credit"
                                                }}
                                                barChartStyle={{ top: 25 }}
                                            />

                                        }

                                        <Box sx={{ p: "8px 12px", background: "#F2F4F7", borderRadius: "6px" }}>
                                            <Typography variant='body2' color='#1D2939' pb='10px'>Note: Values shown are approximations based on historical performance and are not indicative of future results. </Typography>

                                            {keyDetail?.map((x: any, i: number) => {
                                                return (
                                                    <Typography key={i} variant='body2' color='#1D2939' component={'li'} sx={{ listStyle: "disc" }}>{x}  </Typography>
                                                )
                                            })}

                                        </Box>

                                    </Grid>

                                </Grid>

                            </Grid>

                            {!smScreen && <Box width={"100%"} sx={{ mt: { xs: "24px", md: "16px" } }}>
                                {buttonHtml()}
                            </Box>}


                        </Box>
                        {smScreen && <Box sx={{ ...smallScreenBtnWrap, p: "8px 16px", mt: "24px", }}>
                            {buttonHtml()}
                        </Box>}
                    </Grid>
                </Grid>
            </Container>

            {/* ====================== */}
        </>
    )

}

export default RetirementGraph
interface Props {
    monthlySaving: number | string;
    initialAmount: number | string;
    handleInterestCompute: (values: Record<string, number | string>) => void;
    isEmployer: boolean;
    future_values?: any;
    formik: any;
    retirementAmount?: number | undefined;
    planType?: string | undefined;
    travelPlan?: string | undefined;
    previousStep?: any,
    loading?: any,
    nextStep?: any,
    setLoading?: any,
    submitGoal?: any,
    RedirectLink?: any,
    handleAPIsOnStart?: any,
    questionAnswers?: any,
    annualTaxableIncome?: any
}

export const LoadingBarGraph = () => {
    return (
        <Box height={400} sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
        }}>

            <CircularProgress color="secondary" />
        </Box>
    )
}