/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable sort-keys */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from 'react'
import { Box, Container, debounce, InputAdornment, Grid, Typography, useTheme, useMediaQuery, Link } from '@mui/material'
import { goalCardHeadTypoStyle, goalSubHeadTypoStyle, monthlyAmountStyle, NewCFIGraphStyles, secondaryTextStyles } from 'Latest/Goal/style'
import { Slider } from '@mui/material'
import { calculatorWrapper, customSliderInputField, disclaimerTypo, sliderBoxStyle, sliderStyle, tooltipWrapper } from './style'
import ButtonsRow from 'jsx/components/WizardFormModal/ButtonsRowLatest'
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Big from 'big.js';
import { useAppSelector } from 'hooks/reduxHooks'
import { DefaultAgeEstimate } from 'constants/globalVariables'
import { Goals, PLAN_TYPES, intialInvestmentMaxLimit } from '../../../../src/constants'
import { CurrencyFormatter, CurrencyFormatterWithDecimal, FormulaeForRetirementSavings } from 'utils/calculation'
import Loader from 'jsx/components/Shared/Loader'
import { HeadingStyle, subHeading2Style } from 'Latest/Style/Main'
import upArrow from 'Latest/Images/upArrow.png'
import downArrow from 'Latest/Images/downArrow.png'
import InputField from 'jsx/components/Shared/FormikInputFieldLatest';
import { removeCommaFormatting } from 'utils/commaSeparateMasking'
import { monthlySavingReturnsCalculation } from 'utils/monthlySavingReturnsCalculation'
import CustomCFBarChart from 'Latest/ChartV1/customCFBarChart'
import { LoadingBarGraph } from './newCFIGraph'
import FormikInputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1'
import { VPSGoal_Errors } from 'validate/VPSGoal'
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style'

const GenInvestGraph = ({
    RedirectLink,
    formik,
    handleInterestCompute,
    initialAmount,
    isEmployer,
    loading,
    monthlySaving,
    nextStep,
    planType,
    previousStep,
    retirementAmount,
    setLoading,
    submitGoal,
    travelPlan
}: Props) => {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [loadingLayout, setLoadingLayout] = useState(false)
    const [mainLoadingState, setMainLoadingState] = useState(true)

    const [barData, setBarData] = useState<any>()
    const [noOfYears, setNoOfYears] = useState('')

    const [range, setRange] = useState<number>(0);
    const [retirementAge, setRetirementAge] = useState(formik.values.desired_retirement_age);
    const { userAccountDetails } = useAppSelector((state: any) => state.auth);
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);

    const amountLimit = dashboardDetail?.goals[0]?.maxLimit || 0
    const cashFundRemainingLimitAmount = dashboardDetail?.summary?.cashFundRemainingLimitAmount > 0 ? dashboardDetail?.summary?.cashFundRemainingLimitAmount : 0;

    const { investGoalConfig } = useAppSelector(state => state.sharedData)

    const travelTimeCounterMax = 1200;
    const travelTimeCounterMin = 1;
    const goalCounterMax = 100;

    console.log('barData: ', barData);
    console.log('investGoalConfig: ', investGoalConfig);

    useEffect(() => {
        setTimeout(() => {
            setMainLoadingState(false)
        }, 1000);
    }, [])

    useEffect(() => {
        setLoadingLayout(true)
        debouncedDrawGraph(retirementAge)
    }, [monthlySaving, initialAmount, retirementAge, retirementAmount])

    const calculateNumberOfPayments = (retirementAge: number) => {
        let numberOfPayments;
        numberOfPayments = retirementAge * 12;
        formik.setFieldValue('desired_retirement_age', retirementAge);
        return numberOfPayments;
    };

    const debouncedDrawGraph = useCallback(
        debounce(
            (retirementAgeValue) =>
                HandleFormulae(retirementAgeValue, Number(monthlySaving), Number(initialAmount)),
            500
        ),
        [monthlySaving, initialAmount, retirementAmount]
    );

    const HandleFormulae = (retirementAgeValue: number, monthlySavingValue: number, initialAmountValue: number) => {

        const numberOfPayments = calculateNumberOfPayments(retirementAgeValue);
        formik.setFieldValue('number_of_payments', numberOfPayments);

        const total = new Big(setGraphDataFunc(numberOfPayments, monthlySavingValue));
        const totalMax = total
            .plus(total.mul(new Big(Math.random() * 0.1).add(Math.random() * 0.15)))
            .div(1000)
            .round()
            .mul(1000)
            .toNumber();

        let monthlyValue;
        if (planType !== PLAN_TYPES.ACCUMULATE_AMOUNT) {
            monthlyValue = Number(monthlySaving);
        } else {
            monthlyValue = FormulaeForRetirementSavings({
                numberOfPayments: numberOfPayments,
                retirementAmount: formik?.values?.retirement_amount,
                initialAmount: Number(initialAmount)
            });
            // const monthlyContribution = Number(monthlyValue) >= minMonthlycontribution ? monthlyValue : minMonthlycontribution
            const monthlyContribution = Number(monthlyValue) >= 0 ? monthlyValue : 0
            // formik.setFieldValue('monthly_saving', monthlyContribution);
            formik.setFieldValue('monthly_saving', monthlyContribution);
        }

        formik.setFieldValue('future_values', Number(total.toFixed(2, 0)));
    };

    const setGraphDataFunc = (numberOfPayments: number, monthlySavingValue: number) => {

        const riskLevels: any = investGoalConfig.investReturnMargins

        // Extract key-value pairs from the object
        const entries = Object.entries(riskLevels);

        // Extract numeric values and corresponding keys
        const numericEntries = entries.filter(([key, value]) => typeof value === 'number');

        // Sort the numeric values and corresponding keys
        const sortedNumericEntries = numericEntries.sort((a: any, b: any) => a[1] - b[1]);

        // Find minimum value and key
        const [minKey, minValue] = numericEntries.reduce((min: any, entry: any) => (entry[1] < min[1] ? entry : min), ["", Infinity]);

        // Find the mid value and key
        const midIndex = Math.floor(sortedNumericEntries.length / 2);
        const [midKey, midValue]: any = sortedNumericEntries[midIndex];

        // Find maximum value and key
        const [maxKey, maxValue] = numericEntries.reduce((max: any, entry: any) => (entry[1] > max[1] ? entry : max), ["", -Infinity]);

        const data = [];
        let exportedData = [];

        const profitPercentConservative = new Big(minValue / 100)
        const profitPercentModerate = new Big(midValue / 100)
        const profitPercentAggressive = new Big(maxValue / 100)

        let months = numberOfPayments;
        if (months < 0) { months = 0; }
        let startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

        let my_amount = new Big(initialAmount);

        let l_amount = new Big(monthlySavingReturnsCalculation(new Big(initialAmount) || 0, undefined, profitPercentConservative));
        let m_amount = new Big(monthlySavingReturnsCalculation(new Big(initialAmount) || 0, undefined, profitPercentModerate));
        let h_amount = new Big(monthlySavingReturnsCalculation(new Big(initialAmount) || 0, undefined, profitPercentAggressive));

        for (let i = 1; i <= months; i++) {
            startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);

            my_amount = my_amount.add(monthlySavingValue);

            l_amount = monthlySavingReturnsCalculation(l_amount, monthlySavingValue, profitPercentConservative)
            m_amount = monthlySavingReturnsCalculation(m_amount, monthlySavingValue, profitPercentModerate)
            h_amount = monthlySavingReturnsCalculation(h_amount, monthlySavingValue, profitPercentAggressive)

            data.push(
                {
                    year: `${startDate.getFullYear()}`,

                    my_amount: Number(my_amount.toFixed(2, 0)),


                    Conservative: Number(l_amount.toFixed(2, 0)),
                    Conservative_Return: Number(l_amount.sub(my_amount).toFixed(2, 0)),

                    Moderate: Number(m_amount.toFixed(2, 0)),
                    Moderate_Return: Number(m_amount.sub(my_amount).toFixed(2, 0)),

                    Aggressive: Number(h_amount.toFixed(2, 0)),
                    Aggressive_Return: Number(h_amount.sub(my_amount).toFixed(2, 0)),
                },
            );

            // console.log('Data: ', data);
        }

        const types = ["Conservative", "Moderate", "Aggressive"];
        const keys = [minKey, midKey, maxKey];
        const uniqueKeys = keys?.filter((value, index, self) => {
            return self?.indexOf(value) === index;
          });
        const customizeManulpulatedData = [];
          console.log(uniqueKeys)
        for (let i = 0; i < uniqueKeys?.length; i++) {
            const obj = {
                name: keys[i],
                myContribution: data[data.length - 1][types[i]],
                return: data[data.length - 1][types[i] + '_Return'],
                my_amount: data[data.length - 1]?.my_amount,
                type: types[i],
                year: startDate.getFullYear()
            };
            customizeManulpulatedData.push(obj);
        }

        // const manulpulateData = [
        //     {
        //         name: minKey,
        //         myContribution: data[data.length - 1]?.Conservative,
        //         return: data[data.length - 1]?.Conservative_Return,
        //         my_amount: data[data.length - 1]?.my_amount,
        //         type: 'Conservative',
        //         year: startDate.getFullYear()
        //     },
        //     {
        //         name: midKey,
        //         myContribution: data[data.length - 1]?.Moderate,
        //         return: data[data.length - 1]?.Moderate_Return,
        //         my_amount: data[data.length - 1]?.my_amount,
        //         type: 'Moderate',
        //         year: startDate.getFullYear()
        //     },
        //     {
        //         name: maxKey,
        //         myContribution: data[data?.length - 1]?.Aggressive,
        //         return: data[data?.length - 1]?.Aggressive_Return,
        //         my_amount: data[data?.length - 1]?.my_amount,
        //         type: 'Aggressive',
        //         year: startDate.getFullYear()
        //     }
        // ]


        // exportedData.push(data[data.length - 1])
        // exportedData = manulpulateData

        exportedData = customizeManulpulatedData;
        setBarData(exportedData)
        setLoadingLayout(false)
        return my_amount;
    };

    const yearCounterValidationSchema = (param: number) => {
        if (formik?.initialValues?.goalId > 0) {
            if (formik?.initialValues?.goalId === Goals.TravelTime) {
                return param < travelTimeCounterMin
                    ? travelTimeCounterMin
                    : param > travelTimeCounterMax
                        ? travelTimeCounterMax
                        : param;
            } else {
                return param <= 0 ? 1 : param > goalCounterMax ? goalCounterMax : param;
            }
        } else {
            return param < formik?.values?.userAge + 1
                ? formik?.values?.userAge + 1
                : param > goalCounterMax
                    ? goalCounterMax
                    : param;
        }
    };

    const handleChangeRetirementAge = (e: any) => {
        setNoOfYears(e.target.value)
        setRetirementAge(
            yearCounterValidationSchema(Number(e.target.value))
        )
    }

    const timeHorizonUnit = formik?.values?.goalId === Goals.TravelTime ? "Month" : "Year"
    const timeHorizonMaxRange = formik?.values?.goalId === Goals.TravelTime ? 1200 : 25

    const errorCustomObj: any = {
        "initial_amount": {
            [VPSGoal_Errors.AMOUNT_REQUIRED]: true,
            [VPSGoal_Errors.MIN_INTIAL_AMT_GI]: true
        },
        "monthly_saving": {
            [VPSGoal_Errors.AMOUNT_REQUIRED]: true,
        },
        "retirement_amount": {
            [VPSGoal_Errors.AMOUNT_REQUIRED]: true,
            [VPSGoal_Errors.AMOUNT_REQUIRED_NOT_GREATER]: true
        }
    }

    const handleNext = () => {
        if (errorCustomObj.initial_amount[formik.errors.initial_amount]) {
            return
        } else if (planType === PLAN_TYPES.ACCUMULATE_AMOUNT) {
            if (errorCustomObj.retirement_amount[formik.errors.retirement_amount]) {
                return
            }
        }
        else if (planType !== PLAN_TYPES.ACCUMULATE_AMOUNT) {
            if (errorCustomObj.monthly_saving[formik.errors.monthly_saving]) {
                return
            }
        }

        submitGoal();
        nextStep();
    }

    const handleBack = () => {
        previousStep();
    }

    // if (mainLoadingState)
    //     return (
    //         <div className='text-center p-5'><Loader /></div>
    //     );


    const limitMessageHtml = () => {
        if (Number(formik?.values?.initial_amount) > intialInvestmentMaxLimit) {
            if (RedirectLink == "dashboard") {
                return <Box sx={{ p: "8px 12px", background: "#F2F4F7", borderRadius: "6px", mt: "16px" }}>
                    <Typography variant='body2' color='#667085'>Note: You can only invest up to {CurrencyFormatterWithDecimal(amountLimit, true, "never", false)}. Your remaining limit is {CurrencyFormatterWithDecimal(cashFundRemainingLimitAmount, true, "never", false)}. To invest more, please upgrade your account.
                        &nbsp;
                        <Link href={"/myprofile?scroll=true"} color="secondary" variant="body2" rel="noopener noreferrer" sx={{ fontWeight: 600 }}>
                            Upgrade now
                        </Link>
                    </Typography>
                </Box>
            } else {
                return <Box sx={{ p: "8px 12px", background: "#F2F4F7", borderRadius: "6px", mt: "16px" }}>
                    <Typography variant='body2' color='#667085'>Note: You can only invest up to {CurrencyFormatterWithDecimal(intialInvestmentMaxLimit, true, "never")}. To invest more, please upgrade your account once it’s active</Typography>
                </Box>
            }
        }
    }

    const buttonHtml = () => {
        return (
            <ButtonRowV1 handleNext={handleNext} handleBack={handleBack} loadingNextBtn={loadingLayout} btnTitleNext="Continue" />
        )
    }

    const topContentHtml = () => {
        return (
            <>
                <Typography variant='h1' color={"#1D2939"} textAlign={"center"} mb="8px">Plan your goal</Typography>
                <Typography variant='body2' color={"rgba(29, 41, 57, 0.70)"} textAlign={"center"} >Contributions can be made at any time. However keeping them monthly can help you achieve your goals.</Typography>
            </>
        )
    }

    return (
        <>
            {mainLoadingState && <Loader />}
            <Container maxWidth={'lg'} sx={{ py: { xs: "24px", md: "40px" }, }} disableGutters={smScreen}>

                {smScreen && <Box sx={{
                    borderRadius: "8px",
                    background: "#FFF",
                    boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
                    p: "16px", mb: "24px"
                }}>
                    {topContentHtml()}
                </Box>}

                <Box sx={{
                    ...mainContainerV2,
                    gap: "16px"
                }}>

                    {!smScreen && <Box>
                        {topContentHtml()}
                    </Box>}

                    <Grid container sx={{ background: "#FFF", py: { xs: "0px", md: "16px" } }}>

                        <Grid item md={5.5} xs={12} sx={{ p: { xs: "16px 0px", md: "0px 26px 0px 0px" }, display: "flex", flexDirection: "column", justifyContent: "space-between", order: { xs: 2, md: 1 } }} >

                            <Box sx={{ gap: "16px", display: "flex", flexDirection: "column" }}>

                                <Box>
                                    <FormikInputField
                                        // required
                                        formik={formik}
                                        label={"Your initial investment"}
                                        maxLength={12}
                                        name={"initial_amount"}
                                        placeholder='Enter here...'
                                        thousandSeparator={true}
                                        type='number'
                                        inputEnd={"PKR"}
                                        showError={false}
                                    />
                                    {errorCustomObj.initial_amount[formik.errors.initial_amount] && <span className='text-danger' style={{ display: "flex" }}>{formik.errors.initial_amount}</span>}
                                </Box>

                                <Box>
                                    <FormikInputField
                                        // required
                                        formik={formik}
                                        label={planType === PLAN_TYPES.ACCUMULATE_AMOUNT ? "Your target amount" : <><span>Your monthly contribution</span><span className='ml-1 text-red'>(optional)</span></>}
                                        maxLength={12}
                                        name={planType === PLAN_TYPES.ACCUMULATE_AMOUNT ? "retirement_amount" : "monthly_saving"}
                                        placeholder='Enter here...'
                                        thousandSeparator={true}
                                        type='number'
                                        inputEnd={"PKR"}
                                        showError={false}
                                    />

                                    {planType === PLAN_TYPES.ACCUMULATE_AMOUNT ?
                                        errorCustomObj.retirement_amount[formik.errors.retirement_amount] && <span className='text-danger' style={{ display: "flex" }}>{formik.errors.retirement_amount}</span>
                                        :
                                        errorCustomObj.monthly_saving[formik.errors.monthly_saving] && <span className='text-danger' style={{ display: "flex" }}>{formik.errors.monthly_saving}</span>
                                    }

                                </Box>

                                <Grid item md={12} xs={12} >
                                    <Typography variant={"body2"} sx={{ pb: '30px' }} >Duration of investing</Typography>
                                    <Box sx={{ ...sliderBoxStyle, columnGap: "31px", rowGap: "8px" }}>
                                        <Box sx={{ p: 0, width: "100%" }}>
                                            <Slider
                                                value={retirementAge}
                                                max={timeHorizonMaxRange}
                                                min={1}
                                                onChange={handleChangeRetirementAge}
                                                sx={sliderStyle}
                                                defaultValue={0}
                                                valueLabelDisplay="auto"
                                                size='medium'
                                                valueLabelFormat={() => <div className='rangeLabel'>{retirementAge + " " + `${retirementAge > 1 ? `${timeHorizonUnit}s` : `${timeHorizonUnit}`}`}</div>}
                                            />
                                        </Box>
                                        <Grid item md={4} sm={4} xs={12} style={customSliderInputField} sx={{ ...NewCFIGraphStyles.customSliderInputField, mt: { xs: 0, md: 0 } }}>
                                            <Typography sx={{ ...NewCFIGraphStyles.customSliderInput }}>
                                                {retirementAge + " " + `${retirementAge > 1 ? `${timeHorizonUnit}s` : `${timeHorizonUnit}`}`}
                                            </Typography>
                                            <Grid item sx={{ display: 'flex', flexDirection: 'column' }} >
                                                <img src={upArrow} alt={'increase'} onClick={() => retirementAge < timeHorizonMaxRange ? setRetirementAge(retirementAge + 1) : {}} />
                                                <img src={downArrow} alt={'decrease'} onClick={() => retirementAge > 1 ? setRetirementAge(retirementAge - 1) : {}} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                {planType === PLAN_TYPES.ACCUMULATE_AMOUNT && <Grid item md={12} xs={12} sx={{ p: "16px", background: "#422E84", borderRadius: '8px', mt: "8px" }} >
                                    <Typography color="white" variant="h3" textAlign={"center"} pb="8px">{CurrencyFormatterWithDecimal(formik.values.monthly_saving, true, 'never')}</Typography>
                                    <Typography color="white" variant="h6" textAlign={"center"}> Contribute monthly to achieve your target</Typography>
                                </Grid>
                                }

                            </Box>

                            {/* {userAccountDetails?.kyc?.status != 'ApprovalSucceeded' && limitMessageHtml()} */}
                        </Grid>

                        <Grid item md={6.5} xs={12} sx={{ order: { xs: 1, md: 1 } }}  >
                            <Typography sx={{ p: "14px 0px", fontSize: { xs: "16px", md: "14px" }, fontWeight: 500, textAlign: { md: "center" }, color: { xs: "#422E84", md: "#1D2939" } }}>
                                PROJECTIONS {barData?.length ? barData[0].year && `BY ${barData[0].year}` : null}
                            </Typography>
                            {smScreen && <Box sx={{ mx: { xs: "-16px", md: "" }, borderBottom: "1px solid rgba(208, 213, 221, 0.25)", }} />}
                            <Grid item md={12} sm={12} xs={12} sx={{ padding: { md: "34px 0 0 16px", xs: "24px 0 24px 0" } }}>

                                {loadingLayout ?
                                    <LoadingBarGraph />
                                    :
                                    barData && <CustomCFBarChart LabelListDataKey={"myContribution"} LabelListShow={true} barData={barData} fund="GI" barDataKey1="my_amount" barDataKey2="return" />}

                                <Box sx={{ p: "8px 12px", background: "#F2F4F7", borderRadius: "6px" }}>
                                    <Typography variant='body2' color='#1D2939' textAlign={"center"}>Note: Values shown are approximations based on historical performance and are not indicative of future results. </Typography>
                                </Box>

                            </Grid>

                        </Grid>

                    </Grid>

                    {!smScreen && <Box width={"100%"} sx={{ mt: { xs: "24px", md: "16px" } }}>
                        {buttonHtml()}
                    </Box>}
                </Box>

                {smScreen && <Box sx={{ ...smallScreenBtnWrap, mt: "24px" }}>
                    {buttonHtml()}
                </Box>}

            </Container>










            {/* <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ py: { xs: '16px', md: "40px" } }}>

                <Typography variant={"h5"} sx={{ pb: '16px', px: { xs: '16px', md: 0 } }} >
                    {planType === PLAN_TYPES.ACCUMULATE_AMOUNT ? "I know my target amount" : "Discover how your wealth can grow"}</Typography>

                <Grid container sx={{ borderRadius: "12px", border: { xs: "none", md: "1px solid #EAECF0" }, background: "#FFF", p: { xs: "0px", md: "24px" } }}>

                    <Grid item md={7} xs={12} sx={{ p: { xs: "16px", md: "0px 26px 0px 0px" }, display: "flex", flexDirection: "column", justifyContent: "space-between", order: { xs: 2, md: 1 } }} >

                        <Box sx={{ gap: "16px", display: "flex", flexDirection: "column" }}>

                            <Box>
                                <FormikInputField
                                    required
                                    formik={formik}
                                    label={"Initial Investment"}
                                    maxLength={12}
                                    name={"initial_amount"}
                                    placeholder='Enter here...'
                                    thousandSeparator={true}
                                    type='number'
                                    inputEnd={"PKR"}
                                    showError={false}
                                />
                                {errorCustomObj.initial_amount[formik.errors.initial_amount] && <span className='ml-2 text-danger' style={{ display: "flex" }}>{formik.errors.initial_amount}</span>}
                            </Box>

                            <Box>
                                <FormikInputField
                                    required
                                    formik={formik}
                                    label={planType === PLAN_TYPES.ACCUMULATE_AMOUNT ? "Total investment goal" : "Monthly Contribution"}
                                    maxLength={12}
                                    name={planType === PLAN_TYPES.ACCUMULATE_AMOUNT ? "retirement_amount" : "monthly_saving"}
                                    placeholder='Enter here...'
                                    thousandSeparator={true}
                                    type='number'
                                    inputEnd={"PKR"}
                                    showError={false}
                                />

                                {planType === PLAN_TYPES.ACCUMULATE_AMOUNT ?
                                    errorCustomObj.retirement_amount[formik.errors.retirement_amount] && <span className='ml-2 text-danger' style={{ display: "flex" }}>{formik.errors.retirement_amount}</span>
                                    :
                                    errorCustomObj.monthly_saving[formik.errors.monthly_saving] && <span className='ml-2 text-danger' style={{ display: "flex" }}>{formik.errors.monthly_saving}</span>
                                }

                            </Box>

                            <Grid item md={12} xs={12} >
                                <Typography variant={"body2"} sx={{ pb: '30px' }} >Time Horizon</Typography>
                                <Box sx={{ ...sliderBoxStyle, }}>
                                    <Box sx={{ px: { xs: 4, md: 0 }, width: "100%" }}>
                                        <Slider
                                            value={retirementAge}
                                            max={timeHorizonMaxRange}
                                            min={1}
                                            onChange={handleChangeRetirementAge}
                                            sx={sliderStyle}
                                            defaultValue={0}
                                            valueLabelDisplay="auto"
                                            size='medium'
                                            valueLabelFormat={() => <div className='rangeLabel'>{retirementAge + " " + `${retirementAge > 1 ? `${timeHorizonUnit}s` : `${timeHorizonUnit}`}`}</div>}
                                        />
                                    </Box>
                                    <Grid item md={4} sm={4} xs={12} style={customSliderInputField} sx={NewCFIGraphStyles.customSliderInputField}>
                                        <Typography sx={{ ...NewCFIGraphStyles.customSliderInput }}>{retirementAge + " " + `${retirementAge > 1 ? `${timeHorizonUnit}s` : `${timeHorizonUnit}`}`}</Typography>
                                        <Grid item sx={{ display: 'flex', flexDirection: 'column' }} >
                                            <img src={upArrow} alt={'increase'} onClick={() => retirementAge < timeHorizonMaxRange ? setRetirementAge(retirementAge + 1) : {}} />
                                            <img src={downArrow} alt={'decrease'} onClick={() => retirementAge > 1 ? setRetirementAge(retirementAge - 1) : {}} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            {planType === PLAN_TYPES.ACCUMULATE_AMOUNT && <Grid item md={12} xs={12} sx={{ p: "16px", background: "#F9FAFB", borderRadius: '8px' }} >
                                <Typography variant='h6' color="#667085"> Monthly contribution</Typography>
                                <Typography variant='h5' color="#475467" mt="4px">{CurrencyFormatterWithDecimal(formik.values.monthly_saving, true, 'never', true)}</Typography>
                            </Grid>
                            }

                        </Box>

                        {userAccountDetails?.kyc?.status != 'ApprovalSucceeded' && limitMessageHtml()}
                    </Grid>


                    <Grid item md={5} xs={12} sx={{ border: "1px solid #EAECF0", borderRadius: { xs: 0, md: "8px" }, order: { xs: 1, md: 1 } }}  >
                        <Typography sx={{ p: "14px 20px", borderBottom: "1px solid #EAECF0" }} variant={"h6"} >Performance</Typography>
                        <Grid item md={12} sm={12} xs={12} sx={{ p: { xs: "16px", md: "34px 24px 26px 24px" }, background: '#FCFCFD' }}  >

                            {loadingLayout ?
                                <LoadingBarGraph />
                                :
                                barData && <CustomCFBarChart LabelListDataKey={"myContribution"} LabelListShow={true} barData={barData} fund="GI" barDataKey1="my_amount" barDataKey2="return" />}

                            <Box sx={{ p: "8px 12px", background: "#F2F4F7", borderRadius: "6px", mt: "16px" }}>
                                <Typography variant='body2' color='#667085'>Note: Values shown are approximations based on historical performance and are not indicative of future results. </Typography>
                            </Box>

                        </Grid>

                    </Grid>

                    <Grid item md={12} xs={12} sx={{ display: "flex", justifyContent: "flex-end", pt: { xs: 0, md: "24px" }, px: { xs: "16px", md: "0px" } }} order={3} >
                        <ButtonRowV1 handleNext={handleNext} handleBack={handleBack} loadingNextBtn={loadingLayout} />
                    </Grid>

                </Grid>


            </Container> */}

        </>
    )

}

export default GenInvestGraph

interface Props {
    monthlySaving: number | string;
    initialAmount: number | string;
    handleInterestCompute: (values: Record<string, number | string>) => void;
    isEmployer: boolean;
    future_values?: any;
    formik: any;
    retirementAmount?: number | undefined;
    planType?: string | undefined;
    travelPlan?: string | undefined;
    previousStep?: any,
    loading?: any,
    nextStep?: any,
    setLoading?: any,
    submitGoal?: any,
    RedirectLink?: any
}

interface IFormulaeValues {
    numberOfPayments: number;
    initialAmount: number;
    monthlySavings?: number;
    expextedRetirementAge?: number;
    retirementAmount?: number;
    retirement_age?: number;
    numberOfProfit?: number;
}