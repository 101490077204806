import { Box, Grid, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PhoneField from 'jsx/components/Shared/PhoneField';
import { useFormik } from 'formik';
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import CloseIcon from '@mui/icons-material/Close';
import { onUpdatePhoneOTP } from 'redux/actions';
import { toast } from 'react-toastify';
import { useAppLoading } from 'hooks/appLoading';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';

const UpdatePhoneNumber = ({ currentStep, handleCloseFunc, setCurrentStep }: any) => {
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    const loading = useAppLoading('auth');

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            phoneNumber: ''
        },

        onSubmit: async (values) => {
            const body = {
                newPhoneNumber: values.phoneNumber,
                userId: userAccountDetails?.userId,
            }
            const onSuccess = (message: any, response: any) => {
                setCurrentStep(currentStep + 1);
            }
            const onFail = (message: any, response: any) => {
                console.log(message, response.status, "OPOOP")
                toast.error('Try again later', { theme: "colored" });
            }
            dispatch(onUpdatePhoneOTP(body, onSuccess, onFail));
        },
        validationSchema: Yup.object({
            phoneNumber: Yup.string().required('Mobile number is required').test('require', '', function (value) {
                const isValid = isValidPhoneNumber(`+${String(value)}`);
                if (!isValid) {
                    return this.createError({
                        message: "Mobile number is Invalid"
                    });
                }
                return true;
            }),
        })
    });

    const handleOnClick = () => {
        formik.handleSubmit();
    };

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", paddingBottom: '28px', }}>
                <Typography variant='h4' sx={{ color: '#1D2939' }}>Update your number</Typography>
                <IconButton aria-label="close" component="label" onClick={handleCloseFunc}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
                <Typography variant='body1' sx={{ color: '#475467' }}>You'll receive OTPs for verification on your previous and new phone numbers. We will then submit your phone number change request to CDC.</Typography>
                <Typography variant='h6' sx={{ color: '#202020' }}>The approval process can take up to 5 working days</Typography>
            </Box>

            <Box sx={{ padding: '24px 0px' }}>
                <PhoneField
                    required
                    country={'pk'}
                    countryCodeEditable={false}
                    disableCountryCode={false}
                    disableDropdown={false}
                    enableSearch={true}
                    fieldName='phoneNumber'
                    formik={formik}
                    label='Mobile number'
                    value={formik.values.phoneNumber}
                />
            </Box>

            <LoadingBtnCustom type="submit" btnTitle="Continue" size={"large"} fullWidth={true} handleOnClick={handleOnClick} loading={loading} />
        </Box>
    );
};

export default UpdatePhoneNumber;
