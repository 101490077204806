import * as React from 'react';
import '../../scss/login/_Login.scss';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { FormEvent, memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { resendSmsCodeParams, verifyEmailParams } from 'redux/actions';
import { authStyles, authStylesOTP } from 'Latest/Style/Style';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Logo from 'Latest/Images/mahanna2.png';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import OtpInput from 'react-otp-input';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import OTPInput from './OtpInput';
import { ENVIRONMENT_NAME } from 'constants/settings';
import { AuthMsgs, Env_Name } from 'constants/index';
import { Button, IconButton, useMediaQuery } from '@mui/material';
import { styleAuth } from './style';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import CloseIcon from '@mui/icons-material/Close';

const ValidateOTP = ({
  customContainerStyle,
  customSubHead,
  externalPhoneNo,
  handleCloseCustomFunc,
  heading = 'Mobile number confirmation',
  loading,
  onlyOTP = false,
  otpUserId,
  phoneNo,
  resendContent2ndTier,
  resendSmsCode,
  type,
  useContainerStyle = true,
  userId,
  verifySmsCode
}: ValidateOTPProps) => {

  const [otp, setOtp] = useState('');
  const [disableOTP, setDisableOTP] = useState(false);
  const [mobValidateWTP, setMobValidateWTP] = useState<string>('');
  const theme = useTheme();

  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    otp.length === 6 && verifySmsCode({
      userId,
      token: otp.toString()
    });
  };
  const history = useHistory()
  const handleResendOTP = (messageMethod: string) => {
    if (messageMethod.toLowerCase() === "sms".toLowerCase()) {
      AuthMsgs.resendOtpWhatsapp = "OTP is sent to you via SMS";
    } else {
      AuthMsgs.resendOtpWhatsapp = "OTP is sent to you via WhatsApp";
    }

    if (!disableOTP) {
      setOtp('')
      resendSmsCode({ userId, messageMethod });
      setDisableOTP(true);
      setTimeout(() => {
        setDisableOTP(false);
      }, 30000);
    }
  };

  const defaultTimeLimit = 30;

  const [counter, setCounter] = React.useState(defaultTimeLimit); // 1 hour in seconds
  const [isFlag, setIsFlag] = React.useState(false);

  const Timer = () => {
    counter === 0 && setCounter(defaultTimeLimit); // Reset to 1 hour when it reaches 0
    setIsFlag(true);
  };

  React.useEffect(() => {
    let timerId: any;
    if (counter > 0) {
      timerId = setTimeout(() => setCounter(counter - 1), 1000);
    }

    if (counter === 0) {
      clearInterval(timerId); // Clear the interval when the counter reaches 0
      setIsFlag(false);
    }

    return () => clearInterval(timerId); // Cleanup on component unmount or counter change
  }, [counter, isFlag]);

  const hours = Math.floor(counter / 3600);
  const minutes = Math.floor((counter % 3600) / 60);
  const seconds = counter % 60;

  const displayTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  const phoneNumber = externalPhoneNo ? formatPhoneNumber(externalPhoneNo) : formatPhoneNumber(history.location.state);

  const verifyMobileNumber = (mobNum: any) => {
    if (mobNum?.startsWith('+92') || mobNum?.startsWith('92')) {
      return true
    } else {
      return false
    }

  }

  const handleChange = (name: string) => {
    setMobValidateWTP(name)
  }

  const subHeadingJSX = () => {
    if (customSubHead) {
      return (customSubHead)
    } else {
      return (
        <Typography variant='body2' color={"rgba(29, 41, 57, 0.70)"} >
          Enter OTP sent to {verifyMobileNumber(phoneNumber) ? `${phoneNumber} via ${!mobValidateWTP ? "Sms" : mobValidateWTP}` : `${phoneNumber} via Whatsapp`}
        </Typography>
      )
    }
  }

  const resendContentJSX = () => {
    if (resendContent2ndTier) {
      return (
        <>
          <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}>Didn’t receive the OTP? </Typography>
          <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} sx={{}} fontWeight={400} onClick={() => { Timer(); handleResendOTP("WhatsappMesage"); }}> &nbsp;Resend&nbsp;</Typography>
        </>
      )
    } else {
      return (
        <>
          <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}>Didn’t receive the OTP? </Typography>
          <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}> &nbsp;Resend via&nbsp;</Typography>
          {
            verifyMobileNumber(phoneNumber) ? (
              <>
                <a className='ResendOtp' onClick={() => { Timer(); handleResendOTP("sms"); handleChange("Sms") }} style={{ color: "#49BDD8", fontWeight: 500 }} >
                  {" SMS "}
                </a>
                <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}>&nbsp;or&nbsp;</Typography>
                <a className='ResendOtp' onClick={() => { Timer(); handleResendOTP("WhatsappMesage"); handleChange("WhatsApp") }} style={{ color: "#49BDD8", fontWeight: 500 }} >
                  {" WhatsApp "}
                </a>
              </>
            ) : (
              <a className='ResendOtp' onClick={() => { Timer(); handleResendOTP("WhatsappMesage"); }} style={{ color: "#49BDD8", fontWeight: 500 }} >
                {" WhatsApp "}
              </a>
            )
          }
        </>
      )
    }
  }

  const backHeaderJsx = () => {
    if (type == "2ndTierKyc") {
      return (<>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
          <Box sx={{ display: 'flex', alignItems: "center", gap: 1, pb: "4px", cursor: "pointer" }} onClick={handleCloseCustomFunc}>
            <ArrowBackIcon sx={{ fontSize: "18px", color: "#422E84" }} />
            <Typography variant='body2' color={"#422E84"}>Back</Typography>
          </Box>
          <IconButton aria-label="close" component="label" onClick={handleCloseCustomFunc}>
            <CloseIcon />
          </IconButton>
        </Box>
      </>)
    } else {
      return (
        <Box sx={{ display: 'flex', alignItems: "center", gap: 1, pb: "4px", cursor: "pointer" }} component={Link} to={"/auth/login"}>
          <ArrowBackIcon sx={{ fontSize: "18px", color: "#422E84" }} />
          <Typography variant='body2' color={"#422E84"}>Back</Typography>
        </Box>
      )
    }
  }

  return (
    <>

      {onlyOTP ?
        <>
          <Container sx={{ mt: { xs: "24px", md: "40px" }, ...authStylesOTP, ...authStyles.container, maxWidth: "540px !important", ...customContainerStyle }} disableGutters={smScreen} >
            <Box sx={useContainerStyle ? styleAuth.cardContainer : {}} >
              <Box className={"otpForm"} sx={{ mt: "12px" }}>
                <Box className='Form_Secondary' id='otp-field'>
                  <OTPInput
                    autoFocus
                    isNumberInput
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={setOtp}
                    disabled={loading}
                  />
                </Box>
              </Box>
            </Box>

          </Container>
        </>
        :
        <Container sx={{ mt: { xs: "24px", md: "40px" }, ...authStylesOTP, ...authStyles.container, maxWidth: "540px !important", ...customContainerStyle }} disableGutters={smScreen} >
          <Box sx={useContainerStyle ? styleAuth.cardContainer : {}} >

            {backHeaderJsx()}

            <Box>
              <Typography variant='h1' color='#1D2939'>{heading}</Typography>

              {subHeadingJSX()}

            </Box>

            <Box className={"otpForm"} sx={{ mt: "12px" }}>
              <Box className='Form_Secondary' id='otp-field'>
                <Typography>OTP</Typography>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ marginTop: '1rem', '> div': { flexWrap: 'wrap' } }}>

                  <OTPInput
                    autoFocus
                    isNumberInput
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={setOtp}
                    disabled={loading}
                  />
                </Stack>

                <Stack direction={{ xs: 'row', sm: 'row', md: "row" }} spacing={3} style={{ marginTop: '32px' }} >
                  <Typography sx={{ justifyContent: 'start', width: 'auto', color: '#899CA8' }} display={"flex"} flexWrap={"wrap"}>
                    {counter && counter > 0 ?
                      <>
                        <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}>You can resend the code in</Typography>
                        <Typography sx={authStyles.counter} variant='body2' color={"#49BDD8 !important"} fontWeight={400}>
                          {displayTime}
                        </Typography>
                      </>
                      :
                      resendContentJSX()

                    }
                  </Typography>

                </Stack>

                <Box sx={{ mt: "8px" }}>
                  <LoadingBtnCustom btnTitle="Verify OTP" size={"large"} fullWidth={true} handleOnClick={handleSubmit} loading={loading} />
                </Box>

              </Box>
            </Box>

          </Box>
        </Container>

      }
    </>
  );
};

export default memo(ValidateOTP);

interface ValidateOTPProps {
  resendSmsCode: (data: resendSmsCodeParams) => void;
  loading: boolean;
  otpUserId: any;
  userId: string;
  phoneNo?: string;
  verifySmsCode: (data: verifyEmailParams) => void;
  externalPhoneNo?: any;
  useContainerStyle?: boolean;
  customContainerStyle?: any;
  onlyOTP?: boolean;
  heading?: string;
  customSubHead?: any;
  resendContent2ndTier?: boolean;
  handleCloseCustomFunc?: any;
  type?: string
}
