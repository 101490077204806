import React from 'react'
import CustomTabs from './tabs'
import { Box, Grid, Skeleton } from '@mui/material'
import Cardinfo from "./CardInfo"
import TopBarHeading from 'Latest/ComponentV1/topBarHeading'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { zakatStatus } from '../../constants'
import { useAppLoading } from 'hooks/appLoading'
import ZakatNextOfKInNotification from 'Latest/Notification/ShowNotification/zakat_NextOfKIn_Notification'
import TierKYCNotification from 'Latest/Notification/ShowNotification/tier_KYC_Notification'
import Reupload_CNIC_Notification from 'Latest/Notification/ShowNotification/reupload_CNIC_Notification'
import Summary from './Tab/SummaryV2';
import { onGetDashboardPotAllocation } from 'redux/actions'
import { onGetTransactionDetail, onGetTransactionDetailAction } from 'redux/reducers/sharedStateData'
import Dividend_Notification from 'Latest/Notification/ShowNotification/Dividend_Notification'
import AccFreezNotification from 'Latest/Notification/ShowNotification/Acc_Freez_Notification'
import UserPhoneEmailVerificationNotification from 'Latest/Notification/ShowNotification/user_Phone_Email_Notification'

const Index = ({ Data }: any) => {

    const loading = useAppLoading('auth');
    const dispatch: any = useAppDispatch();

    React.useEffect(() => {
        getTransactionHistory()
        Number(Data?.goals[0]?.potDetails?.portfolioAmount) > 0 && dispatch(onGetDashboardPotAllocation(Number(Data?.goals[0]?.potDetails?.portfolioAmount)))
    }, [])

    const getTransactionHistory = () => {
        const onSuccess = (message: any, response: any) => {
            const responseList = message?.length > 0 ? message : []
            const checkPending = responseList?.length > 0 && responseList?.some((i: any) => i?.status === 'ApprovalPending' || i?.status === 'ApprovalInProgress')
            dispatch(onGetTransactionDetailAction(responseList))
        }
        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
            //   setTransactionList([])
        }
        const param = {
            id: ""
        }
        dispatch(onGetTransactionDetail(param, onSuccess, onFail))
    }

    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
    const brokerType: any = dashboardDetail?.goals?.some((x: any) => x?.brokerType?.toLowerCase() === "cdc");

    return (
        <>
            {/* <TopBarHeading heading={"Your Personal Dashboard"} /> */}

            {loading ?
                <SkeletonLoading />
                :
                <>
                    <UserPhoneEmailVerificationNotification/>
                    <AccFreezNotification />
                    <ZakatNextOfKInNotification />
                    <TierKYCNotification />
                    <Reupload_CNIC_Notification />
                    {brokerType && <Dividend_Notification />}
                </>
            }

            {/* //Dashboard Content */}
            <Box>
                <Summary Data={Data} />
                {/* <CustomTabs Data={Data} /> */}
                <Cardinfo Data={Data} />
            </Box>
        </>
    )
}

export default Index

const SkeletonLoading = () => {
    return (
        <Box sx={{ px: { xs: "16px", sm: "32px" }, pb: { xs: "16px", sm: "24px" } }}>
            <Skeleton variant="rounded" width={"100%"} height={20} />
        </Box>
    )
}