import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "hooks/reduxHooks";
import { tokens } from "theme";
import "../style/elipses.css"
import { CardinfoStyle } from "../style/cardinfoStyle";

const Index = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { articlesData } = useAppSelector((state: any) => state.sharedData);
    const mahaanaEvent: any = articlesData?.events;

    if (!mahaanaEvent || !mahaanaEvent.length) {
        return null
    }
    return (
        <Grid sx={{ ...CardinfoStyle.itemGridRight, borderRadius: "8px" }}>
            <Typography variant='h4' color={colors.purple[100]} lineHeight={"36px"}>Upcoming Event</Typography>

            {
                mahaanaEvent?.map((vals: any, index: any) => {
                    return (
                        <Card key={index} sx={{ maxWidth: "100%", marginTop: "20px", boxShadow: "none" }}>
                            <CardMedia
                                onClick={() => window.open(vals?.link, '_blank', 'noopener,noreferrer')}
                                component="img"
                                image={vals?.image}
                                alt="green iguana"
                                sx={{ borderRadius: "8px", cursor: 'pointer' }}
                                height={"264px"} width={"100%"}

                            />
                            <CardContent sx={{ padding: "0px", width: "100%", marginTop: "10px" }}>
                                <Typography gutterBottom variant="h5" component="div" sx={{ lineHeight: "25px", fontWeight: "500" }} className="elipseStylePara">
                                    <a href={vals?.link} target="_blank" rel="noreferrer" style={{ lineHeight: "25px", fontWeight: "500", color: colors.purple[100] }}>{vals?.name}</a>
                                </Typography>
                                <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginTop: "10px" }} gap={2}>
                                    <Typography variant="body1"  >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: "-2px", marginRight: "5px" }}>
                                            <path d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM11.5 8.5H8C7.86739 8.5 7.74022 8.44732 7.64645 8.35355C7.55268 8.25979 7.5 8.13261 7.5 8V4.5C7.5 4.36739 7.55268 4.24021 7.64645 4.14645C7.74022 4.05268 7.86739 4 8 4C8.13261 4 8.25979 4.05268 8.35356 4.14645C8.44732 4.24021 8.5 4.36739 8.5 4.5V7.5H11.5C11.6326 7.5 11.7598 7.55268 11.8536 7.64645C11.9473 7.74021 12 7.86739 12 8C12 8.13261 11.9473 8.25979 11.8536 8.35355C11.7598 8.44732 11.6326 8.5 11.5 8.5Z" fill="#344054" />
                                        </svg>
                                        { vals?.itemTime }
                                        {/* {`${vals?.itemTime?.slice(0,5)} ${ vals?.itemTime?.slice(5) }`} */}
                                    </Typography>
                                    <Typography variant="body1" style={{ textTransform : "uppercase" }}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: "-1px", marginRight: "5px"  }}>
                                            <path d="M13 2H11.5V1.5C11.5 1.36739 11.4473 1.24021 11.3536 1.14645C11.2598 1.05268 11.1326 1 11 1C10.8674 1 10.7402 1.05268 10.6464 1.14645C10.5527 1.24021 10.5 1.36739 10.5 1.5V2H5.5V1.5C5.5 1.36739 5.44732 1.24021 5.35355 1.14645C5.25979 1.05268 5.13261 1 5 1C4.86739 1 4.74021 1.05268 4.64645 1.14645C4.55268 1.24021 4.5 1.36739 4.5 1.5V2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2ZM13 5H3V3H4.5V3.5C4.5 3.63261 4.55268 3.75979 4.64645 3.85355C4.74021 3.94732 4.86739 4 5 4C5.13261 4 5.25979 3.94732 5.35355 3.85355C5.44732 3.75979 5.5 3.63261 5.5 3.5V3H10.5V3.5C10.5 3.63261 10.5527 3.75979 10.6464 3.85355C10.7402 3.94732 10.8674 4 11 4C11.1326 4 11.2598 3.94732 11.3536 3.85355C11.4473 3.75979 11.5 3.63261 11.5 3.5V3H13V5Z" fill="#344054" />
                                        </svg>
                                        {vals?.itemDate}
                                    </Typography>
                                    <Typography variant="body1" >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: "-2px", marginRight: "5px" }}>
                                            <path d="M8 1C6.54182 1.00165 5.14383 1.58165 4.11274 2.61274C3.08165 3.64383 2.50165 5.04182 2.5 6.5C2.5 11.2063 7.5 14.7606 7.71313 14.9094C7.7972 14.9683 7.89735 14.9999 8 14.9999C8.10265 14.9999 8.2028 14.9683 8.28687 14.9094C8.5 14.7606 13.5 11.2063 13.5 6.5C13.4983 5.04182 12.9184 3.64383 11.8873 2.61274C10.8562 1.58165 9.45818 1.00165 8 1ZM8 4.5C8.39556 4.5 8.78224 4.6173 9.11114 4.83706C9.44004 5.05682 9.69638 5.36918 9.84776 5.73463C9.99913 6.10009 10.0387 6.50222 9.96157 6.89018C9.8844 7.27814 9.69392 7.63451 9.41421 7.91421C9.13451 8.19392 8.77814 8.3844 8.39018 8.46157C8.00222 8.53874 7.60009 8.49913 7.23463 8.34776C6.86918 8.19638 6.55682 7.94004 6.33706 7.61114C6.1173 7.28224 6 6.89556 6 6.5C6 5.96957 6.21071 5.46086 6.58579 5.08579C6.96086 4.71071 7.46957 4.5 8 4.5Z" fill="#344054" />
                                        </svg>
                                        {vals?.itemLocation}
                                    </Typography>
                                </Box>
                            </CardContent>


                            <Button fullWidth size={"large"} variant='contained' color="secondary" sx={{ fontWeight: "500", fontSize: "14px", borderRadius: "20px", marginTop: "20px" }} onClick={() => window.open(vals?.link, '_blank', 'noopener,noreferrer')}>
                                RSVP now
                            </Button>

                        </Card>
                    )
                })
            }

        </Grid>
    )
}

export default Index;